import { omit } from 'ramda';
import { util } from 'src/app/core/util/util.service';
import MomentUtil from 'src/app/util/moment.util';
import { objectUtil } from 'src/app/util/util';
import { PeopleState } from '../people/people.model';
import { CurrentInsuranceState, LifeAssuredListState, PolicyDocumentsListState } from './current-insurance.model';

// biome-ignore lint/complexity/noStaticOnlyClass: Mapper
export class CurrentInsuranceMapper {
	/** Mapping for view */
	static mapToView(data: CurrentInsuranceState) {
		if (!data) {
			return { ...data };
		}
		const filterPolicyOwners = (po: string) => {
			const a = JSON.parse(po)?.map((x) => +x);
			return a?.filter((x) => x);
		};
		const val = {
			...data,
			linkedCRTId: data?.linkedCRTId,
			policyOwner:
				data.policyOwner && typeof data.policyOwner === 'string' ? filterPolicyOwners(data.policyOwner) : null,
			lifeAssuredList: (data.lifeAssuredList
				? data.lifeAssuredList
				: data.lifeAssured
					? typeof data.lifeAssured === 'string'
						? ((JSON.parse(data.lifeAssured) as LifeAssuredListState[])?.map(
								objectUtil.mapPascalCaseToCamelCase,
							) as LifeAssuredListState[])
						: data.lifeAssured
					: []
			)?.map((x) => {
				return {
					lifeAssured: +x.lifeAssured,
					product: x.product,
					benefit: +x.benefit,
					notes: x.notes,
					premium: +x.premium,
					startDate: util.isValidMoment(x.startDate) ? x.startDate : MomentUtil.formatDateToMoment(x.startDate),
					newExisting: x?.newExisting,
					priority: x?.priority,
				};
			}),
			policyDocumentsList: data.policyDocumentsList
				? data.policyDocumentsList
				: data.policyDocuments
					? typeof data.policyDocuments === 'string'
						? ((JSON.parse(data.policyDocuments) as PolicyDocumentsListState[])?.map(
								objectUtil.mapPascalCaseToCamelCase,
							) as PolicyDocumentsListState[])
						: data.policyDocuments
					: [],
			totalPremium: data?.totalPremium,
		};
		return val;
	}

	public static getLifeAssuredName(id: number, lifeAssuredList = [], transferedSCI: PeopleState[] = []) {
		const lifeAssuredName = lifeAssuredList?.find((i) => (+i.value === id ? i.display : null))?.display;

		if (lifeAssuredName) {
			return lifeAssuredName;
		}

		// If ID is not in Life Assured List, check in the list of Transfered SCI
		const transferedName = transferedSCI?.find((i) => +i.customerId === id)?.name || '';

		if (transferedName) {
			return transferedName;
		}

		return '';
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(
		data: CurrentInsuranceState,
		info: CurrentInsuranceState,
		isAiQuoteEnabled?: boolean,
	): CurrentInsuranceState {
		const omitList = ['sumOfLifeAssured'];
		if (!isAiQuoteEnabled) {
			omitList.push('policyNumber');
			omitList.push('manualReview');
		}
		const allInfo = omit(omitList, info);
		const policyDocs = info?.policyDocuments?.map(({ aiQuoteStatus, ...x }) => x);
		return {
			...allInfo,
			...data,
			policyOwner:
				data.policyOwner && data.policyOwner.length > 0 ? JSON.stringify(data.policyOwner?.map(String)) : null,
			additionalNotes: data.additionalNotes,
			premiumFrequency: data.premiumFrequency,
			policyFee: data?.policyFee,
			totalPremium: +(data?.totalPremium || 0),
			lifeAssuredList: data?.lifeAssuredList?.map((x) => {
				return {
					lifeAssured: +x.lifeAssured,
					product: x.product,
					benefit: +x.benefit,
					notes: x.notes,
					premium: +x.premium || 0,
					startDate: MomentUtil.formatDateToServerDate(x.startDate),
					newExisting: x?.newExisting,
					priority: x?.priority,
				};
			}),
			lifeAssured: data?.lifeAssuredList?.map((x) => {
				return {
					lifeAssured: +x.lifeAssured,
					product: x.product,
					benefit: +x.benefit,
					notes: x.notes,
					premium: +x.premium,
					startDate: MomentUtil.formatDateToServerDate(x.startDate),
					newExisting: x?.newExisting,
					priority: x?.priority,
				};
			}),
			cRTId: info?.cRTId,
			sectionCode: info?.sectionCode,
			adviceProcessId: info?.adviceProcessId,
			policyDocumentsList: policyDocs,
			policyDocuments: policyDocs && policyDocs?.length > 0 ? JSON.stringify(policyDocs) : null,
			linkedCRTId: info?.linkedCRTId,
			parentCRTId: info?.parentCRTId,
			// Only for Final Structure
			parentCustomerId: info?.parentCustomerId,
			status: 1,
		};
	}
}
