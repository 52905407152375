import { Component, HostBinding, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent implements ConfirmModalModel, OnInit {
	confirm$: Observable<unknown>;
	decline$: Observable<unknown>;
	close$: Observable<unknown>;
	header: string;
	message: string;
	secondaryMessage: string;
	subMessage?: string;
	isOkBtn?: boolean;
	isAcceptBtn?: boolean;
	confirmTxt = 'Yes';
	cancelTxt = 'No';
	detachCloseIcon = false;
	cancelLoading: boolean;
	confirmLoading: boolean;
	confirmBtnText = 'Replace';
	declineBtnText = 'Cancel';
	customButtons?: { text: string; action$: Observable<unknown>; closeOnActionDone?: boolean }[];
	customBtnIsExecuting = false;
	designV2 = false;
	subject: Subject<boolean | string>;

	@HostBinding('class.tap-modal') modalV2: boolean;

	constructor(public bsModalRef: BsModalRef) {
		this.setupProps();
	}
	ngOnInit(): void {
		this.modalV2 = this.designV2;
	}
	setupProps() {
		this.header = 'Confirm';
		this.message = 'Confirm?';
	}

	continue() {
		if (this.cancelLoading || this.confirmLoading) {
			return;
		}
		this.confirmLoading = true;
		this.confirm$
			.pipe(
				finalize(() => {
					this.bsModalRef.hide();
					this.action(true);
					setTimeout(() => (this.confirmLoading = false), 500);
				}),
				take(1),
			)
			.subscribe();
	}
	decline() {
		this.cancelLoading = true;
		if (this.decline$) {
			this.decline$.subscribe();
			this.action(false);
		}
		this.bsModalRef.hide();
		setTimeout(() => (this.cancelLoading = false), 500);
	}

	close() {
		this.cancelLoading = true;
		if (this.close$) {
			this.close$.subscribe();
			this.action('close');
		}
		this.bsModalRef.hide();
		setTimeout(() => (this.cancelLoading = false), 500);
	}

	customBtnClick(item: { text: string; action$: Observable<unknown>; closeOnActionDone: boolean }): void {
		if (this.customBtnIsExecuting) {
			return;
		}
		this.customBtnIsExecuting = true;
		item.action$
			.pipe(
				finalize(() => {
					this.customBtnIsExecuting = false;
					// by default if cloneOnActionDone will be true if not specified
					if (item.closeOnActionDone || item.closeOnActionDone === undefined) {
						this.bsModalRef.hide();
					}
				}),
				take(1),
			)
			.subscribe();
	}

	action(value: boolean | string) {
		if (this.subject) {
			this.subject.next(value);
			this.subject.complete();
		}
	}
}
export interface ConfirmModalModel {
	confirm$: Observable<unknown>;
	decline$?: Observable<unknown>;
	close$?: Observable<unknown>;
	header: string;
	message: string;
	secondaryMessage: string;
	subMessage?: string;
	isOkBtn?: boolean;
	isAcceptBtn?: boolean;
	confirmTxt?: string;
	cancelTxt?: string;
	detachCloseIcon?: boolean;
	customButtons?: {
		text: string;
		action$: Observable<unknown>;
		closeOnActionDone?: boolean;
	}[];
}
