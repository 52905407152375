import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import produce from 'immer';
import { Metakey } from '../activity-datatable.config';

/** client ui state */
export interface ActivityUiState {
	/** if search page is currently fetching data */
	isSearching: boolean;
	/** if search page is currently exporting data */
	isExporting: boolean;
	/** if column form is open or closed */
	columnFormPopupOpen: boolean;
	/** if column form is currently saving */
	isColumnSaving: boolean;
	/** which property is currently being sorted. use propname */
	propSort: string;
	/** sort direction */
	sort: 'asc' | 'desc' | '';
	/** propSort and sort direction */
	currentSort: AcitivityUiSortState;

	/** edit status of cell. key is id and propname */
	cellEditStatus: Record<string, boolean>;
	/** loading status of cell. key is id and propname */
	cellLoadingStatus: Record<string, boolean>;
	/** temp value of cell */
	// biome-ignore lint/suspicious/noExplicitAny: To Do: Extract type
	cellTempValue: Record<string, any>;
	/** loading status of row */
	rowLoadingStatus: { [key: number]: boolean };
}

export interface AcitivityUiSortState {
	propSort?: string;
	sort?: 'asc' | 'desc' | '';
}

function createInitialUiState(): ActivityUiState {
	return {
		isSearching: false,
		isExporting: false,
		columnFormPopupOpen: false,
		propSort: '',
		sort: '',
		isColumnSaving: false,

		cellEditStatus: {},
		cellLoadingStatus: {},
		cellTempValue: {},
		rowLoadingStatus: {},
		currentSort: {},
	};
}

@Injectable()
@StoreConfig({ name: 'activityUi', idKey: 'ActivityId' })
export class ActivityUiStore extends Store<ActivityUiState> {
	constructor() {
		super(createInitialUiState());
	}

	setEdit(customerId: number, metakey: Metakey, edit: boolean) {
		const key = `${customerId}-${metakey}`;

		this.update((state) => ({
			cellEditStatus: { ...state.cellEditStatus, [key]: edit },
		}));
	}

	setLoad(customerId: number, metakey: Metakey, loading: boolean) {
		const key = `${customerId}-${metakey}`;
		this.update((state) => ({
			cellLoadingStatus: { ...state.cellLoadingStatus, [key]: loading },
		}));
	}

	setIsSearching(isSearching: boolean) {
		this.update(
			produce((draft) => {
				draft.isSearching = isSearching;
			}),
		);
	}
	setIsExporting(isExporting: boolean) {
		this.update(
			produce((draft) => {
				draft.isExporting = isExporting;
			}),
		);
	}

	toggleColumnPopup(open: boolean) {
		this.update(
			produce((draft) => {
				draft.columnFormPopupOpen = open;
			}),
		);
	}

	setIsColumnSaving(isSaving: boolean) {
		this.update(
			produce((draft) => {
				draft.isColumnSaving = isSaving;
			}),
		);
	}

	setIsEditing(customerId: number, isEditing: boolean) {
		const key = customerId;
		this.update((state) => ({
			rowLoadingStatus: { ...state.rowLoadingStatus, [key]: isEditing },
		}));
	}

	setIsDeleting(customerId: number, isDeleting: boolean) {
		const key = customerId;
		this.update((state) => ({
			rowLoadingStatus: { ...state.rowLoadingStatus, [key]: isDeleting },
		}));
	}

	setIsCompleting(customerId: number, isCompleting: boolean) {
		const key = customerId;
		this.update((state) => ({
			rowLoadingStatus: { ...state.rowLoadingStatus, [key]: isCompleting },
		}));
	}

	setIsCancelling(customerId: number, isCancelling: boolean) {
		const key = customerId;
		this.update((state) => ({
			rowLoadingStatus: { ...state.rowLoadingStatus, [key]: isCancelling },
		}));
	}

	setSort(propSort: string, sort: 'asc' | 'desc') {
		this.update(
			produce((draft) => {
				draft.propSort = propSort;
				draft.sort = sort;
				draft.currentSort = {
					propSort,
					sort,
				};
			}),
		);
	}

	setTempValue(customerId: number, metakey: string, value: unknown) {
		const key = `${customerId}-${metakey}`;
		this.update((state) => ({
			cellTempValue: { ...state.cellTempValue, [key]: value },
		}));
	}
}
