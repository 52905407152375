const appVersion = require('../../package.json').version;

export const environment = {
	appVersion,
	production: false,
	live: false,
	staging: false,
	log: true,
	flags: {
		useNewHeader: true,
	},
	defaultLoginLogo: 'https://tapdevelopmentstorage.blob.core.windows.net/00-tapnz/logo.png',
	defaultLoginBackground: 'https://tapdevelopmentstorage.blob.core.windows.net/00-tapnz/login-bg.png',
	cpUrl: 'tapnzcpdevapi.azurewebsites.net',
	cpSiteUrl: 'http://localhost:4202',
	cpEmailSender: 'testsender@tapcrm.co.nz',
	// Default DatadogRum Config
	datadogRum: {
		applicationId: '3c86d486-55cc-4b2b-b5ce-13aa28126c44',
		clientToken: 'pub836acc8cc9a5ef7971d46e641fe5c553',
		site: 'us5.datadoghq.com',
		service: 'tapcrmfrontend',
		// env: '<ENV_NAME>',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 100,
		trackResources: true,
		trackLongTasks: true,
		trackUserInteractions: true,
		defaultPrivacyLevel: 'mask-user-input',
	},
	intercom: {
		appId: 'lm8kxcwt',
	},
	aiQuoteApi: 'https://staging.api.adlib.me/api/', // staging
	aiQuoteAuth: 'Bearer Kl3D3M/NRCdlvaGaPhdC3Td9+AbvUoqIKG8Xjuphr6U=', // staging
	// aiQuoteApi: 'https://7a0dc2dd-1ffb-4cdf-8b6a-d488292484fd.mock.pstmn.io/api/', // mock api only
	// aiQuoteAuth: 'Bearer Kl3D3M/NRCdlvaGaPhdC3Td9+AbvUoqIKG8Xjuphr6U=', // mock api only
	defaultImgUrl: 'https://tapdevelopmentstorage.blob.core.windows.net/00-tapnz',
	markerIo: {
		project: '66f9ee16f7736580a64da6f2',
		source: 'snippet',
	},
};

export const defaultCrmProdConfigs = {
	appVersion,
	production: true,
	live: false,
	staging: false,
	log: false,
	flags: {
		useNewHeader: false,
	},
	apiProtocol: 'https:',
	apiVersion: 2,
	secretKey: 'TAP_SECRET',
	defaultLoginLogo: 'https://tapdevelopmentstorage.blob.core.windows.net/00-tapnz/logo.png',
	defaultLoginBackground: 'https://tapdevelopmentstorage.blob.core.windows.net/00-tapnz/login-bg.png',
	idleTime: 10800, // seconds
	swUpdateTime: 10800, // seconds

	// cpUrl: 'hub-dev-api.myapp.co.nz', //  CUSTOMER PORTAL DEV
	// cpUrl: 'hub-test-api.myapp.co.nz', //  CUSTOMER PORTAL TEST
	// cpUrl: 'hub-staging-api.myapp.co.nz', //  CUSTOMER PORTAL STAGING
	// cpUrl: 'tapnzliveapi.azurewebsites.net', //  CUSTOMER PORTAL LIVE
	// cpEmailSender: 'testsender@tapcrm.co.nz', // DEV
	// cpEmailSender: 'no-reply@myapp.co.nz',
	// cpSiteUrl: 'https://hub-dev.myapp.co.nz', // CP URL DEV
	cpSiteUrl: 'https://hub-test.myapp.co.nz', // CP URL TEST
	// cpSiteUrl: 'https://hub-staging.myapp.co.nz', // CP URL STAGING
	// cpSiteUrl: 'https://hub.myapp.co.nz', // CP URL LIVE

	// cpUrl: 'hub-test-api.myapp.co.nz', //  CUSTOMER PORTAL TEST
	cpEmailSender: 'no-reply@myapp.co.nz',
	// Default DatadogRum Config
	datadogRum: {
		applicationId: '3c86d486-55cc-4b2b-b5ce-13aa28126c44',
		clientToken: 'pub836acc8cc9a5ef7971d46e641fe5c553',
		site: 'us5.datadoghq.com',
		service: 'tapcrmfrontend',
		// env: '<ENV_NAME>',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 100,
		trackResources: true,
		trackLongTasks: true,
		trackUserInteractions: true,
		defaultPrivacyLevel: 'mask-user-input',
	},
	intercom: {
		appId: 'lm8kxcwt',
	},
	aiQuoteApi: environment?.aiQuoteApi,
	aiQuoteAuth: environment?.aiQuoteAuth,
	defaultImgUrl: environment?.defaultImgUrl,
	markerIo: {
		project: '66f9ee16f7736580a64da6f2',
		source: 'snippet',
	},
};
