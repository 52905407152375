import { Injectable } from '@angular/core';
import { applyTransaction, arrayAdd } from '@datorama/akita';
import { BehaviorSubject, EMPTY, Observable, Subject, concat, from, iif, of, throwError } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { ApiService } from '../../../../core/base/api.service';
import { AddPhotoRequest, CustomerService } from '../../../../core/customer/customer.service';
import { NoteService } from '../../../../core/note/note.service';
import { ActivityService } from '../../../../core/services/activity/activity.service';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { UserQuery } from '../../../../domain/user/user.query';
import { ActivityViewModel } from '../../../../shared/models/_general/activity.viewmodel';
import {
	NoteState as ActivityNoteState,
	ActivityState,
	ActivityTimeline,
	ActivityTimelineState,
	NoteRequest,
} from '../../../../shared/models/activity-timeline/activity-timeline.model';
import {
	PrimaryClient,
	PrimaryClientState,
} from '../../../../shared/models/client-profile/primary-client/primary-client.model';
import {
	SecondaryClient,
	SecondaryClientState,
} from '../../../../shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryProfessionalState } from '../../../../shared/models/client-profile/secondary-professional/secondary-professional.model';
import { SecondaryTrustState } from '../../../../shared/models/client-profile/secondary-trust/secondary-trust.model';
import { SecondaryBusinessState } from '../../../../shared/models/client-profile/seondary-business/secondary-business.model';
import { DocumentGroup, DocumentGroupState } from '../../../../shared/models/documents/document-group.model';
import { EditHistory, EditHistoryState } from '../../../../shared/models/history/history.model';
import { util, objectUtil } from '../../../../util/util';
import { ClientProfileUtilsService } from './client-profie-utils.service';
import { ClientProfileQuery } from './client-profile.query';
import { ClientProfileStore } from './client-profile.store';

import sort from 'fast-sort';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import {
	CurrentActivityCriteria,
	CurrentActivityCriteriaState,
} from '../../../../shared/models/current-activity-criteria/current-activity-criteria.model';
import { GetNotes } from '../../../../shared/models/notes/note-params.model';
import { NoteState, NoteTypes } from '../../../../shared/models/notes/note.model';
import { capitalizeFirstLetter, smallFirstLetter } from '../../../../shared/services/service-utils/document.util';
import MomentUtil from '../../../../util/moment.util';

import { EmailDocTypeModel } from '@modules/emails/email-settings/state/email-template.store';
import { LinkedContact, LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import { PeopleDetails } from '@shared/models/client-review-template/people/people-details.model';
import { PeopleState } from '@shared/models/client-review-template/people/people.model';
import { linkedContactSortUtil } from '@shared/services/service-utils/linked-contacts-sorter.util';
import produce from 'immer';
import * as moment from 'moment';
import { BusinessConfigService } from 'src/app/domain/business-config/business-config.service';
import { CustomerTypes } from 'src/app/shared/models/_general/client.model';
import { FinalStructure } from 'src/app/shared/models/client-review-template/final-structure/final-structure.model';
import { Asset, LiabilityCustomerServiceState } from 'src/app/shared/models/services/assets/assets';
import { Investment, InvestmentState } from 'src/app/shared/models/services/investments/investments.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { AdviceProcessMapper } from '../../../../shared/models/advice-process/advice-process.mapper';
import {
	AdviceProcessCode,
	AdviceProcessSectionCodes,
	AdviceProcessState,
	KLOATSyncResponse,
	ServiceAdviceProcess,
	ServiceAdviceProcessState,
} from '../../../../shared/models/advice-process/advice-process.model';
import { FgInsurance, FgInsuranceState } from '../../../../shared/models/services/fg-insurance/fg-provider-group.model';
import { Kiwisaver, KiwisaverState } from '../../../../shared/models/services/kiwisaver/kiwisaver.model';
import { LrInsurance } from '../../../../shared/models/services/lr-insurance/lr-provider-group.model';
import { Mortgage } from '../../../../shared/models/services/mortgage/mortgage-group.model';
import { PropertyAsset } from '../../../../shared/models/services/property-asset/property-asset-group.model';
import { fgServiceUtil } from '../../../../shared/services/service-utils/fg-insurance.util';
import { kiwiSaverServiceUtil } from '../../../../shared/services/service-utils/kiwisaver.util';
import { lrSortServiceUtil } from '../../../../shared/services/service-utils/lr-insurance.util';
import { mortgageSortServiceUtil } from '../../../../shared/services/service-utils/mortgage-utils';
import {
	assetServiceUtil,
	propAndAssetServiceUtil,
} from '../../../../shared/services/service-utils/property-assets.util';
import { isObject } from '../../../../shared/services/service-utils/service.util';

@Injectable()
export class ClientProfileService extends ClientProfileUtilsService {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	[x: string]: any;
	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected blStaffsQuery: BLStaffsQuery,
		protected store: ClientProfileStore,
		protected query: ClientProfileQuery,
		protected api: ApiService,
		protected customerService: CustomerService,
		protected userQuery: UserQuery,
		protected noteService: NoteService,
		protected activityService: ActivityService,
		protected businessConfigQuery: BusinessConfigQuery,
		protected businessConfigService: BusinessConfigService,
	) {
		super(store, query, dropdownValueQuery, blStaffsQuery, businessConfigQuery, userQuery);
	}

	invokeApNoteFetchEvent = new BehaviorSubject('');
	apId = this.invokeApNoteFetchEvent.asObservable();

	// Email Client Pop Up
	openECModalSubject$ = new Subject<EmailDocTypeModel>();
	openECModalEmitter$ = this.openECModalSubject$.asObservable();
	closeECModalSubject$ = new Subject<EmailDocTypeModel>();
	closeECModalEmitter$ = this.closeECModalSubject$.asObservable();

	clear(): void {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	/**
	 * Get Primary Client Individualgit ba
	 * @param clientId Client Id
	 */
	getPrimaryClient(
		clientId: number,
		service?: string,
		isPrimaryOnly?: boolean,
		// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	): Observable<PrimaryClient | any> {
		this.store.setPrimaryClient(null);
		return of(clientId).pipe(
			mergeMap(() => this.customerService.GetPrimaryClient(clientId)),
			tap((x) =>
				applyTransaction(() => {
					const state = objectUtil.mapPascalCaseToCamelCase(x) as PrimaryClientState;
					this.store.setPrimaryClient(state);
				}),
			),
			catchError(() => of({})),
			tap(() => {
				if (isPrimaryOnly) {
					this.getSecondaryClients(clientId).pipe(take(1)).subscribe();
					this.getLinkedContacts(clientId).pipe(take(1)).subscribe();
				} else {
					this.getSecondaryClients(clientId).pipe(take(1)).subscribe();
					this.getSecondaryBusinesses(clientId).pipe(take(1)).subscribe();
					this.getSecondaryTrusts(clientId).pipe(take(1)).subscribe();
					this.getSecondaryProfessionals(clientId).pipe(take(1)).subscribe();
					this.getLinkedContacts(clientId).pipe(take(1)).subscribe();

					if (service !== ServicesCodes.LR?.toLowerCase()) {
						this.getLRInsurance(clientId).pipe(take(1)).subscribe();
					}
					if (service !== ServicesCodes.Mortgage?.toLowerCase()) {
						this.getMortage(clientId).pipe(take(1)).subscribe();
						this.getSecurity(clientId).pipe(take(1)).subscribe();
						this.getAsset(clientId).pipe(take(1)).subscribe();
						this.getLiability(clientId).pipe(take(1)).subscribe();
					}
					if (service !== ServicesCodes.FG) {
						this.getFGInsurance(clientId).pipe(take(1)).subscribe();
					}
					if (service !== ServicesCodes.KiwiSaver) {
						this.getKiwisavers(clientId).pipe(take(1)).subscribe();
					}
					if (service !== ServicesCodes.Investment) {
						this.getInvestments(clientId).pipe(take(1)).subscribe();
					}
					if (service !== ServicesCodes.AdviceProcess) {
						this.getAdviceProcessesByPrimaryId(clientId).pipe(take(1)).subscribe();
					}

					this.getClientDocuments(clientId).pipe(take(1)).subscribe();
					this.getClientHistories(clientId).pipe(take(1)).subscribe();
				}
			}),
		);
	}

	/**
	 * Add Primary Client Individual
	 * @param primaryClient Primary
	 */
	addPrimaryClient(primaryClient: PrimaryClientState): Observable<unknown> {
		return of(primaryClient).pipe(
			mergeMap(() => this.customerService.SavePrimaryClient(primaryClient)),
			mergeMap((id) =>
				primaryClient.note
					? this.customerService
							.AddNote({
								CustomerID: +id,
								CustomerServiceID: 0,
								Notes: primaryClient.note,
								ActivityType: CustomerTypes.PrimaryCustomerIndividual,
							})
							.pipe(map(() => id))
					: of(id),
			),
			tap((id) =>
				applyTransaction(() => {
					this.store.setPrimaryClient({
						...primaryClient,
						customerID: id,
						clientSince: MomentUtil.formatToServerDate(moment()),
					});
				}),
			),
		);
	}

	downloadLink(documentID) {
		return this.api.get<string>(`documents/download/${documentID}`);
	}

	updateNextReview(value: string, code: string) {
		const prepPayload = (pciData: PrimaryClientState) => {
			switch (code) {
				case ServicesCodes.LR:
					return { ...pciData, lRNextReview: value };
				case ServicesCodes.Mortgage:
					return { ...pciData, mortgageNextReview: value };
				case ServicesCodes.FG:
					return { ...pciData, fGNextReview: value };
				case ServicesCodes.Investment:
					return { ...pciData, investmentKSNextReview: value };
				default:
					return pciData;
			}
		};
		return of(value).pipe(
			withLatestFrom(this.primaryClient$),
			map(([, data]) => prepPayload(data)),
			mergeMap((data) =>
				this.customerService.UpdatePrimaryClient(data).pipe(
					tap(() =>
						applyTransaction(() => {
							this.store.setPrimaryClient(data);
						}),
					),
					tap(() => this.getClientHistories(data?.customerID).pipe(take(1)).subscribe()),
					map(() => data),
				),
			),
			catchError(() => of('')),
		);
	}

	updatePrimaryClient(primaryClient: PrimaryClientState): Observable<unknown> {
		return of(primaryClient).pipe(
			mergeMap(() => this.customerService.UpdatePrimaryClient(primaryClient)),
			tap(() => {
				this.refetchPrimary(+primaryClient?.customerID).pipe(take(1)).subscribe();
				this.getClientHistories(primaryClient.customerID).pipe(take(1)).subscribe();
			}),
			catchError((err) => {
				return err['DuplicateEmail'] ? of({ errorMessage: err['DuplicateEmail'] }) : of('');
			}),
		);
	}

	convert(x) {
		return this.customerService.convertProfile(x).pipe(
			tap(() =>
				applyTransaction(() => {
					const primaryClient: PrimaryClientState = {
						...this.query.getValue().primaryClient,
						contactStatus: x.contactStatus,
					};
					this.store.setPrimaryClient(primaryClient);
				}),
			),
			tap(() => this.getClientHistories(x.customerID).pipe(take(1)).subscribe()),
			catchError(() => of('')),
		);
	}

	refetchPrimary(clientId: number) {
		return this.customerService.GetPrimaryClient(clientId).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = objectUtil.mapPascalCaseToCamelCase(x) as PrimaryClientState;
					this.store.setPrimaryClient(state);
				}),
			),
		);
	}

	updateKeyContact(isPrimary: boolean, req: PrimaryClientState | SecondaryClientState) {
		return of(req).pipe(
			withLatestFrom(this.primaryClient$, this.businessConfigQuery.businessConfig$),
			tap(([data, pci, config]) => {
				applyTransaction(() => {
					if (!config?.AdviserRework) {
						return;
					}
					if (isPrimary || !!data?.isKeyContact || +pci?.preferredEmailContact === +data.customerID) {
						const preferredEmailContact = data?.isKeyContact ? data?.customerID?.toString() : null;
						this.store.setPrimaryClient({ ...pci, preferredEmailContact });
					}
				});
			}),
		);
	}

	/**
	 * Get All Secondary Clients
	 * @param primaryClientID Primary Client ID : number
	 */
	getSecondaryClients(primaryClientID: number): Observable<SecondaryClient[]> {
		this.store.setIsLoading(true, CustomerTypes.SecondaryCustomerIndividual?.toLowerCase());
		return of(primaryClientID).pipe(
			mergeMap((x) => this.customerService.GetSecondaryClientsByPrimaryClient(x)),
			tap((x) =>
				applyTransaction(() => {
					const state = x ? (x.map(objectUtil.mapPascalCaseToCamelCase) as SecondaryClientState[]) : [];
					this.store.setSecondaryClients(state);
					this.store.setIsLoading(false, CustomerTypes.SecondaryCustomerIndividual?.toLowerCase());
				}),
			),
			catchError(() => of([])),
		);
	}

	/**
	 * Add new secondary client
	 * @param req Secondary Client
	 * @param id Primary Customer Id
	 */
	addSecondaryClient(req: SecondaryClientState) {
		const { primaryCustomer } = req;
		return of(req).pipe(
			mergeMap((x) => this.customerService.AddSecondaryClient(x)),
			mergeMap((x) =>
				req.note
					? this.addNote({
							customerID: +x,
							customerServiceID: 0,
							notes: req.note,
							activityType: CustomerTypes.SecondaryCustomerIndividual,
						}).pipe(map(() => x))
					: of(x),
			),
			tap((x) => {
				this.updateKeyContact(false, { ...req, customerID: x })
					.pipe(take(1))
					.subscribe();
				this.getClientHistories(+primaryCustomer).pipe(take(1)).subscribe();
			}),
			tap((x) => {
				applyTransaction(() => {
					const data = [...this.query.getValue().secondaryClients, { ...req, customerID: +x, isActive: 1 }];
					this.store.setSecondaryClients(data);
				});
			}),
			catchError((err) => {
				return err['DuplicateEmail'] ? of({ errorMessage: err['DuplicateEmail'] }) : of('');
			}),
		);
	}

	/**
	 * Update Secondary Client
	 * @param req Secondary Client
	 */
	updateSecondaryClient(req: SecondaryClientState) {
		const { primaryCustomer } = req;
		return of(req).pipe(
			mergeMap((x) => this.customerService.UpdateSecondaryClient(x)),
			tap(() => {
				this.updateKeyContact(false, req).pipe(take(1)).subscribe();
				this.getClientHistories(+primaryCustomer).pipe(take(1)).subscribe();
				this.getActivityTimeline(+primaryCustomer).pipe(take(1)).subscribe();
			}),
			tap(() => {
				applyTransaction(() => {
					const data = this.query.getValue().secondaryClients?.map((y) => (y.customerID === req.customerID ? req : y));
					this.store.setSecondaryClients(data);
				});
			}),
			catchError((err) => {
				return err['DuplicateEmail'] ? of({ errorMessage: err['DuplicateEmail'] }) : of('');
			}),
		);
	}

	/**
	 * Delete Secondary Client
	 * @param req Secondary Client
	 */
	deleteSecondaryClient(req: SecondaryClientState) {
		const { primaryCustomer } = req;
		return of(req).pipe(
			mergeMap(() => this.customerService.DeactivateSecondaryClient(req)),
			tap(() => this.getClientHistories(+primaryCustomer).pipe(take(1)).subscribe()),
			tap(() => {
				applyTransaction(() => {
					const data = this.query.getValue().secondaryClients?.filter((y) => y.customerID !== req.customerID);
					this.store.setSecondaryClients(data);
				});
			}),
			tap(() =>
				applyTransaction(() => {
					const activityTimeline = this.query.getValue().activityTimeline;
					const notesState = activityTimeline.notes?.filter((n) => n.customerID !== req.customerID);
					this.store.setActivityTimeline({
						activities: activityTimeline.activities,
						notes: notesState,
					});
				}),
			),
			catchError(() => of('')),
		);
	}

	/**
	 * Update Secondary Client
	 * @param req Secondary Client
	 */
	copySecondaryClient(id: number) {
		return this.customerService.CopySecondaryClient(id);
	}

	/**
	 * Get all Secondary Businesses
	 * @param primaryClientID secondary business
	 */
	getSecondaryBusinesses(primaryClientID: number) {
		this.store.setIsLoading(true, CustomerTypes.SecondaryCustomerCompany?.toLowerCase());

		return of(primaryClientID).pipe(
			mergeMap((x) => this.customerService.GetSecondaryBusinessesByPrimaryClient(x)),
			tap((x) =>
				applyTransaction(() => {
					const state = x ? (x.map(objectUtil.mapPascalCaseToCamelCase) as SecondaryBusinessState[]) : [];
					this.store.setSecondaryBusinesses(state);
					this.store.setIsLoading(false, CustomerTypes.SecondaryCustomerCompany?.toLowerCase());
				}),
			),
			catchError(() => of([])),
		);
	}

	getSecondaryTrusts(primaryClientId: number) {
		this.store.setIsLoading(true, CustomerTypes.SecondaryCustomerTrust?.toLowerCase());

		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetSecondaryTrustByPrimaryClient(x)),
			tap((x) =>
				applyTransaction(() => {
					const state = x ? (x.map(objectUtil.mapPascalCaseToCamelCase) as SecondaryTrustState[]) : [];
					this.store.setSecondaryTrusts(state);
					this.store.setIsLoading(false, CustomerTypes.SecondaryCustomerTrust?.toLowerCase());
				}),
			),
			catchError(() => of([])),
		);
	}

	addSecondaryTrusts(req: SecondaryTrustState) {
		const { primaryCustomer } = req;
		return this.customerService.AddSecondaryTrustByPrimaryClient(req).pipe(
			mergeMap((id) =>
				req.note
					? this.addNote({
							customerID: +id,
							customerServiceID: 0,
							notes: req.note,
							activityType: CustomerTypes.SecondaryCustomerTrust,
						}).pipe(map(() => id))
					: of(id),
			),
			tap(() => this.getClientHistories(+primaryCustomer).pipe(take(1)).subscribe()),
			tap((id) =>
				applyTransaction(() => {
					const state = [...this.query.getValue().secondaryTrusts, { ...req, customerID: +id }];
					this.store.setSecondaryTrusts(state);
				}),
			),
			catchError(() => of('')),
		);
	}

	updateSecondaryTrust(req: SecondaryTrustState) {
		const { primaryCustomer } = req;
		return this.customerService.UpdateSecondaryTrustByPrimaryClient(req).pipe(
			tap(() => this.getClientHistories(+primaryCustomer).pipe(take(1)).subscribe()),
			tap(() =>
				applyTransaction(() => {
					const state = this.query.getValue().secondaryTrusts?.map((y) => (y.customerID === req.customerID ? req : y));
					this.store.setSecondaryTrusts(state);
				}),
			),
			catchError(() => of('')),
		);
	}

	deleteSecondaryTrust(req: SecondaryTrustState) {
		const { primaryCustomer } = req;
		return this.customerService.DeactivateSecondaryTrustByPrimaryClient(req).pipe(
			tap(() => this.getClientHistories(+primaryCustomer).pipe(take(1)).subscribe()),
			tap(() => {
				applyTransaction(() => {
					const state = this.query.getValue().secondaryTrusts?.filter((y) => y.customerID !== req.customerID);
					this.store.setSecondaryTrusts(state);
				});
			}),
			tap(() =>
				applyTransaction(() => {
					const activityTimeline = this.query.getValue().activityTimeline;
					const notesState = activityTimeline.notes?.filter((n) => n.customerID !== req.customerID);
					this.store.setActivityTimeline({
						activities: activityTimeline.activities,
						notes: notesState,
					});
				}),
			),
			catchError(() => of('')),
		);
	}

	/**
	 * Get all Secondary Professional Contacts
	 * @param primaryClientID secondary professional contacts
	 */
	getSecondaryProfessionals(primaryClientId: number) {
		this.store.setIsLoading(true, CustomerTypes.SecondaryCustomerProfessional?.toLowerCase());
		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetSecondaryProfessionalsByPrimaryClient(x)),
			tap((x) =>
				applyTransaction(() => {
					const state = x ? (x?.map(objectUtil.mapPascalCaseToCamelCase) as SecondaryProfessionalState[]) : [];
					this.store.setSecondaryProfessionals(state);
					this.store.setIsLoading(false, CustomerTypes.SecondaryCustomerProfessional?.toLowerCase());
				}),
			),
			catchError(() => of([])),
		);
	}

	addSecondaryProfessional(req: SecondaryProfessionalState) {
		const { primaryCustomer } = req;
		return this.customerService.AddSecondaryProfessionalByPrimaryClient(req).pipe(
			mergeMap((id) =>
				req.note
					? this.addNote({
							customerID: +id,
							customerServiceID: 0,
							notes: req.note,
							activityType: CustomerTypes.SecondaryCustomerProfessional,
						}).pipe(map(() => id))
					: of(id),
			),
			tap(() => this.getClientHistories(+primaryCustomer).pipe(take(1)).subscribe()),
			tap((id) =>
				applyTransaction(() => {
					const state = [...this.query.getValue().secondaryProfessionals, { ...req, customerID: +id }];
					this.store.setSecondaryProfessionals(state);
				}),
			),
			catchError(() => of('')),
		);
	}

	updateSecondaryProfessional(req: SecondaryProfessionalState) {
		const { primaryCustomer } = req;
		return this.customerService.UpdateSecondaryProfessionalByPrimaryClient(req).pipe(
			tap(() => this.getClientHistories(+primaryCustomer).pipe(take(1)).subscribe()),
			tap(() =>
				applyTransaction(() => {
					const state = this.query
						.getValue()
						.secondaryProfessionals?.map((y) => (y.customerID === req.customerID ? req : y));
					this.store.setSecondaryProfessionals(state);
				}),
			),
			catchError(() => of('')),
		);
	}

	deleteSecondaryProfessional(req: SecondaryProfessionalState) {
		const { primaryCustomer } = req;
		return this.customerService.DeactivateSecondaryProfessionalByPrimaryClient(req).pipe(
			tap(() => this.getClientHistories(+primaryCustomer).pipe(take(1)).subscribe()),
			tap(() => {
				applyTransaction(() => {
					const data = this.query.getValue().secondaryProfessionals?.filter((y) => y.customerID !== req.customerID);
					this.store.setSecondaryProfessionals(data);
				});
			}),
			tap(() =>
				applyTransaction(() => {
					const activityTimeline = this.query.getValue().activityTimeline;
					const notesState = activityTimeline.notes?.filter((n) => n.customerID !== req.customerID);
					this.store.setActivityTimeline({
						activities: activityTimeline.activities,
						notes: notesState,
					});
				}),
			),
			catchError(() => of('')),
		);
	}

	/**
	 * Get Client Documents
	 * @param primaryClientId primaryClientId
	 */
	getClientDocuments(primaryClientId: number): Observable<DocumentGroup> {
		this.store.setIsLoading(true, 'document');
		this.store.setDocuments(null);

		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetDocumentsClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const documents = objectUtil.mapPascalCaseToCamelCase(x) as DocumentGroupState;
					this.store.setDocuments(documents);
					this.store.setIsLoading(false, 'document');
				}),
			),
		);
	}

	// Transfer document
	// biome-ignore lint/suspicious/noExplicitAny: Does not accept unknown
	TransferDocument(req: { doc: any; doctype: string }) {
		return of(req).pipe(
			mergeMap((x) =>
				this.customerService.TransferDocument({
					CreateDateTime: x.doc.createDateTime,
					CreatedBy: x.doc.createdBy,
					CreatedByStaffId: x.doc.createdByStaffId,
					CreatedByStaffLevel: x.doc.createdByStaffLevel,
					CustomerID: x.doc.customerID,
					DocumentLink: x.doc.documentLink,
					DocumentName: x.doc.documentName,
					DocumentTypeCode: capitalizeFirstLetter(req.doctype),
					FileExtension: x.doc.fileExtension,
					FileName: x.doc.fileName,
					Id: x.doc.id,
					IsActive: x.doc.isActive,
					ModifiedByStaffId: x.doc.modifiedByStaffId,
					ModifiedDateTime: x.doc.modifiedDateTime,
				}),
			),
			tap(() =>
				applyTransaction(() => {
					const docState = JSON.parse(JSON.stringify(this.query.getValue().documents));
					const prop = req.doctype;

					const newDoc = Object.keys(docState)?.reduce((object, key) => {
						if (key === prop) {
							if (!object[key]) {
								object[key] = [];
							}

							if (docState[key].length > 0) {
								object[key]?.unshift(...docState[key]);
							}

							const newObj = Object.assign({}, req.doc);
							newObj.documentTypeCode = req.doctype;
							object[key]?.unshift(newObj);
						} else {
							object[key] = docState[key];
						}

						// Removes from prev
						if (key?.toLowerCase() === req.doc.documentTypeCode?.toLowerCase()) {
							object[key] = object[key]?.filter((d) => d.id !== req.doc.id);
						}

						// sort date by createDateTime
						object[key] = object[key].sort(
							(a, b) => new Date(b.createDateTime).getTime() - new Date(a.createDateTime).getTime(),
						);

						return object;
					}, {});

					this.store.setDocuments(newDoc);
				}),
			),
			catchError(() => of('')),
		);
	}

	// Deactivate Document
	DeactivateDocument(doc) {
		return of(doc).pipe(
			mergeMap((x) => this.customerService.DeactivateDocument(x.id)),
			tap(() =>
				applyTransaction(() => {
					const docState = JSON.parse(JSON.stringify(this.query.getValue().documents));

					const newDoc = Object.keys(docState)?.reduce((object, key) => {
						object[key] = docState[key];

						if (key === smallFirstLetter(doc.documentTypeCode)) {
							object[key] = object[key]?.filter((d) => d.id !== doc.id);
						}
						return object;
					}, {});

					this.store.setDocuments(newDoc);
				}),
			),
			tap(() => this.getAdviceProcessesByPrimaryId(this.primaryClient().customerID).pipe(take(1)).subscribe()),
			catchError(() => of('')),
		);
	}

	// biome-ignore lint/suspicious/noExplicitAny: Does not accept unknown
	UploadDocument(req: { doc: any; doctype: string; customerId: number }) {
		// biome-ignore lint/suspicious/noExplicitAny: Does not accept unknown
		const docs: any[] = req.doc.getAll('');
		const first$ = of({
			CustomerID: req.customerId,
			Document: '',
			FileName: docs[0].name,
			DocumentType: req.doctype,
		});

		let failedCount = 0;
		let successCount = 0;

		return first$.pipe(
			switchMap(() =>
				concat(
					first$,
					from(docs).pipe(
						mergeMap(
							(x) => this.convertToBase64(x),
							(o, i) => [o, i],
						),
						map(([o, i]) => {
							return {
								CustomerID: req.customerId,
								Document: i ? i?.split(',')[1] : '',
								FileName: o.name,
								DocumentType: req.doctype,
							};
						}),
						concatMap((req2) =>
							this.customerService.UploadDocument(req2).pipe(
								tap(() => {
									successCount++;
								}),
								catchError(() => {
									failedCount++;
									return of('failed');
								}),
							),
						),
					),
				),
			),
			map(() => {
				return {
					success: successCount,
					failed: failedCount,
				};
			}),
			finalize(() => {
				this.getClientDocuments(req.customerId)
					.pipe(map(() => 'success'))
					.subscribe();
				this.getClientHistories(req.customerId).pipe(take(1)).subscribe();
			}),
		);
	}

	/**
	 * Get activity timeline
	 * @params primaryClientId primary client id
	 */
	getActivityTimeline(
		primaryClientId: number,
		// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	): Observable<ActivityTimeline | any> {
		return of(primaryClientId).pipe(
			mergeMap(() => this.customerService.GetActivityTimeline(primaryClientId)),
			tap((x) => {
				applyTransaction(() => {
					const state = objectUtil.mapPascalCaseToCamelCase(x) as ActivityTimelineState;
					this.store.setActivityTimeline(state);
				});
			}),
			catchError(() => of({})),
		);
	}

	addActivityNote = (note: string) => {
		return of(note).pipe(
			withLatestFrom(this.customerID$, this.userQuery.userInfo$),
			mergeMap(([n, id]) =>
				this.customerService.AddNote({
					CustomerID: id,
					Notes: n,
					CustomerServiceID: 0,
					IsActivity: true,
					StaffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
				}),
			),
			withLatestFrom(this.activityTimeline$, this.userQuery.userInfo$),
			tap(([id, activityTimeline]) =>
				applyTransaction(() => {
					const newNote = {
						notesID: +id,
						notes: note,
						createDateTime: MomentUtil.formatToServerDatetime(MomentUtil.createMomentNz()),
						staffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
						dueDateTime: null,
						activityType: null,
						activityName: null,
						assignedToAdviser: null,
						isActivity: true,
					} as ActivityNoteState;

					const state = {
						activities: activityTimeline.activities,
						notes: [newNote, ...activityTimeline.notes],
					};
					this.store.setActivityTimeline(state);
				}),
			),
			catchError(() => of('')),
		);
	};

	addToActivityTimelineNotes(noteID: number, note: string, aType: string, cusID: number, csID = 0) {
		applyTransaction(() => {
			let timeline = this.query.getValue().activityTimeline;
			if (!timeline) {
				timeline = {
					activities: [],
					notes: [],
				};
			}
			const state = produce<ActivityTimelineState>(timeline, (draft) => {
				draft.notes?.unshift({
					notesID: noteID,
					notes: note,
					createDateTime: MomentUtil.formatToServerDatetime(MomentUtil.createMomentNz()),
					staffName: `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
					dueDateTime: null,
					activityType: aType,
					activityName: null,
					assignedToAdviser: null,
					isActivity: false,
					customerID: cusID,
					customerServiceID: csID,
				});
			});
			this.store.setActivityTimeline(state);
		});
	}

	deleteActivityNote = (noteId: number, isAp?: boolean, adviceProcessId?: string) => {
		return of(noteId).pipe(
			mergeMap((x) => this.noteService.DeactivateNote(x, isAp)),
			withLatestFrom(this.activityTimeline$),
			tap(([, activityTimeline]) =>
				applyTransaction(() => {
					const notesState = activityTimeline.notes?.filter((note) => note.notesID !== noteId);
					this.store.setActivityTimeline({
						activities: activityTimeline.activities,
						notes: notesState,
					});

					if (isAp) {
						this.invokeApNoteFetchEvent.next(adviceProcessId);
					}
				}),
			),
			map(([x]) => x),
			catchError(() => of('')),
		);
	};

	deleteAllNotesByType = (type: string, customerId: number) => {
		return of(type).pipe(
			mergeMap((x: string) => this.noteService.DeactiveNotesByType(x, customerId)),
			withLatestFrom(this.activityTimeline$),
			tap(([, activityTimeline]) =>
				applyTransaction(() => {
					const notesState: ActivityNoteState[] = activityTimeline.notes?.filter(
						(note: ActivityNoteState) => note.type !== type,
					);

					this.store.setActivityTimeline({
						activities: activityTimeline.activities,
						notes: notesState,
					});
				}),
			),
			map(([x]) => x),
			catchError(() => of('')),
		);
	};

	addActivity = (ac: ActivityViewModel) => {
		return of(ac).pipe(
			map((a) => ActivityViewModel.MapToAdd(a)),
			mergeMap((x) => this.activityService.Post(x)),
			withLatestFrom(this.customerID$),
			mergeMap(([, customerID]) => this.getActivityTimeline(customerID)),
			catchError((e) => throwError(e)),
		);
	};

	updateActivity = (ac: ActivityViewModel) => {
		return of(ac).pipe(
			map((a) => ActivityViewModel.MapToEdit(a)),
			mergeMap((x) => this.activityService.Put(x)),
			withLatestFrom(this.customerID$),
			mergeMap(([x, customerID]) => (ac.IsCompleted ? this.getActivityTimeline(customerID).pipe(map(() => x)) : of(x))),
			withLatestFrom(this.activityTimeline$),
			tap(([id, activityTimeline]) =>
				applyTransaction(() => {
					const updatedActivity = objectUtil.mapPascalCaseToCamelCase(
						ActivityViewModel.MapToModel(ac),
					) as ActivityState;
					if (!updatedActivity.isCompleted) {
						const activities = activityTimeline.activities?.map((a) => (a.activityId === +id ? updatedActivity : a));
						const state = {
							notes: activityTimeline.notes,
							activities: sort(activities).desc((a) => a.dueDate),
						};
						this.store.setActivityTimeline(state);
					}
				}),
			),
			catchError((e) => throwError(e)),
		);
	};

	cancelActivity = (ac: { activity; reason }) => {
		return of(ac.activity).pipe(
			map((a) => ActivityViewModel.MapToEdit(a)),
			mergeMap((x) =>
				this.activityService.CancelActivityTimeline({
					...x,
					Reason: ac.reason,
					IsCancelled: true,
				}),
			),
			withLatestFrom(this.activityTimeline$, this.customerID$),
			mergeMap(([, , customerID]) => this.getActivityTimeline(customerID)),
			catchError(() => of('')),
		);
	};

	deleteActivity = (ac: ActivityViewModel) => {
		return of(ac).pipe(
			map((a) => ActivityViewModel.MapToDelete(a)),
			mergeMap((x) => this.activityService.Delete(x)),
			withLatestFrom(this.activityTimeline$, this.customerID$),
			mergeMap(([, , customerID]) => this.getActivityTimeline(customerID)),
			catchError(() => of('')),
		);
	};

	addPhoneCall = (ac: ActivityViewModel) => {
		return of(ac).pipe(
			map((a) => ActivityViewModel.MapToQuickAdd(a)),
			mergeMap((a) => this.activityService.QuickAddPost(a)),
			withLatestFrom(this.customerID$),
			mergeMap(([x, customerID]) => this.getActivityTimeline(customerID).pipe(map(() => x))),
			withLatestFrom(this.activityTimeline$),
			tap(([x, activityTimeline]) =>
				applyTransaction(() => {
					const newActivity = objectUtil.mapPascalCaseToCamelCase(ac) as ActivityState;
					const state = {
						notes: activityTimeline?.notes,
						activities: sort([...activityTimeline?.activities, { ...newActivity, activityId: +x }]).desc(
							(a) => a.dueDate,
						),
					};
					this.store.setActivityTimeline(state);
				}),
			),
		);
	};

	/**
	 * Get Client History
	 * @param primaryClientId primaryClientId
	 */
	getClientHistories(primaryClientId: number): Observable<EditHistory[]> {
		this.store.setIsLoading(true, 'history');
		this.store.setHistories([]);

		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetHistoriesClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const histories = x ? (x?.map(objectUtil.mapPascalCaseToCamelCase) as EditHistoryState[]) : [];
					this.store.setHistories(histories);
					this.store.setIsLoading(false, 'history');
				}),
			),
			catchError(() => of([])),
		);
	}

	deleteHistory(id: number, isAp?: boolean) {
		return this.customerService.DeleteNote(id, isAp).pipe(
			tap(() => {
				applyTransaction(() => {
					const data = this.query.getValue().histories?.filter((n) => n.notesID !== id);
					this.store.setHistories(data);
				});
			}),
			catchError(() => of('')),
		);
	}

	getNotes = (req: GetNotes) => {
		if (!req.CustomerID) {
			return EMPTY;
		}
		return this.customerService.GetNotes(req).pipe(
			map(objectUtil.mapPascalCaseToCamelCase),
			catchError(() => of([])),
		);
	};

	addNote = (note: NoteRequest) => {
		return of(note).pipe(
			mergeMap((n) =>
				this.customerService.AddNote({
					CustomerID: n.customerID,
					Notes: n.notes,
					CustomerServiceID: n.customerServiceID,
					ActivityType: n.activityType,
					StaffName: n.staffName || `${this.userQuery.getValue().FirstName} ${this.userQuery.getValue().LastName}`,
				}),
			),
			tap((x) =>
				this.addToActivityTimelineNotes(+x, note.notes, note.activityType, note.customerID, note.customerServiceID),
			),
			catchError(() => of('')),
		);
	};

	deactivateNote = (note: NoteState) => {
		return of(note).pipe(
			mergeMap((x) => this.noteService.DeactivateNote(x.notesID)),
			tap(() =>
				applyTransaction(() => {
					const activityTimeline = this.query.getValue().activityTimeline;
					const notesState = activityTimeline.notes?.filter((n) => n.notesID !== note.notesID);
					this.store.setActivityTimeline({
						activities: activityTimeline.activities,
						notes: notesState,
					});
				}),
			),
			catchError(() => of('')),
		);
	};

	pinNote = (req: { note: NoteState; pin: boolean }) => {
		const isAp = req.note.activityType === NoteTypes.AdviceProcess;
		return of(req).pipe(
			mergeMap((x) => this.customerService.PinNote(x.note.notesID, x.pin, isAp)),
			tap(() =>
				applyTransaction(() => {
					const activityTimeline = this.query.getValue().activityTimeline;
					const state = produce(activityTimeline, (draft) => {
						draft.notes?.forEach((n) => {
							if (n.notesID === req.note.notesID) {
								n.isPinned = req.pin;
							}
						});
					});
					this.store.setActivityTimeline({
						activities: activityTimeline.activities,
						notes: state.notes,
					});
				}),
			),
			catchError(() => of('')),
		);
	};

	addPhoto$ = (req: AddPhotoRequest, isAddSecondary?: boolean) => {
		return this.customerService
			.AddPhoto({
				...req,
				Photo: req.Photo ? req.Photo.replace(/^data:image.+;base64,/, '') : null,
			})
			.pipe(
				tap((x) =>
					applyTransaction(() => {
						const sci = this.query.getValue().secondaryClients;
						if (!!isAddSecondary || (sci && sci?.some((y) => y.customerID === req.CustomerID))) {
							const data = this.query
								.getValue()
								.secondaryClients?.map((y) =>
									y.customerID === x.CustomerID ? { ...y, photoURL: x.PhotoURL, fileName: x.FileName } : y,
								);
							this.store.setSecondaryClients(data);
						} else {
							const data = this.query.getValue().primaryClient;
							this.store.setPrimaryClient({
								...data,
								photoURL: x.PhotoURL,
								fileName: x.FileName,
							});
						}
					}),
				),
				catchError(() => of('')),
			);
	};

	/**
	 * Get Client History
	 * @param primaryClientId primaryClientId
	 */
	getCriterias(primaryClientId: number): Observable<CurrentActivityCriteria[]> {
		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetCriteriaClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const criterias = x ? (x?.map(objectUtil.mapPascalCaseToCamelCase) as CurrentActivityCriteriaState[]) : [];
					// const slicedCriterias =
					// 	criterias && criterias.length > 10
					// 		? criterias?.slice(0, 10)
					// 		: criterias;
					this.store.setCriterias(criterias);
				}),
			),
			catchError(() => of([])),
		);
	}

	/**
	 * Get LrInsurance
	 * @param primaryClientId primaryClientId
	 */

	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	getLRInsurance(primaryClientId: number): Observable<LrInsurance | any> {
		this.store.setIsLoading(true, ServicesCodes.LR?.toLowerCase());
		this.store.setLrInsurance({
			totalInforceAPI: 0,
			lRs: [],
		});

		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetLrInsurancesByPrimaryClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const data = objectUtil.mapPascalCaseToCamelCase(x);
					// biome-ignore lint/suspicious/noExplicitAny: To do: extract type
					const mainLR: any = {};
					if (isObject(data)) {
						mainLR.lRs = data ? Object.keys(data)?.map((i) => data[i]) : [];
					}
					this.store.setLrInsurance(lrSortServiceUtil(mainLR));
					this.store.setIsLoading(false, ServicesCodes.LR?.toLowerCase());
				}),
			),
			catchError(() => of({})),
		);
	}

	/**
	 * Get Mortgage
	 * @param primaryClientId primaryClientId
	 */
	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	getMortage(primaryClientId: number): Observable<Mortgage | any> {
		this.store.setIsLoading(true, ServicesCodes.Mortgage?.toLowerCase());
		this.store.setMortgage({
			totaLending: 0,
			mortgages: [],
		});

		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetMortgagesByPrimaryClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const data = objectUtil.mapPascalCaseToCamelCase(x);
					// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
					const mainMortgage: any = {};
					if (isObject(data)) {
						mainMortgage.mortgages = data ? Object.keys(data)?.map((i) => data[i]) : [];
					}
					this.store.setMortgage(mortgageSortServiceUtil(mainMortgage));
					this.store.setIsLoading(false, ServicesCodes.Mortgage?.toLowerCase());
				}),
			),
			catchError(() => of({})),
		);
	}

	/**
	 * Get Security
	 * @param primaryClientId primaryClientId
	 */
	getSecurity(primaryClientId: number): Observable<PropertyAsset> {
		this.store.setIsLoading(true, ServicesCodes.Property?.toLowerCase());
		this.store.setPropertyAsset({
			totaValue: 0,
			customerServices: [],
		});

		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetSecuritiesByPrimaryClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const data = objectUtil.mapPascalCaseToCamelCase(x);
					const sorted = propAndAssetServiceUtil(data);
					this.store.setPropertyAsset(sorted);
					this.store.setIsLoading(false, ServicesCodes.Property?.toLowerCase());
				}),
			),
			catchError(() => of({})),
		);
	}

	/**
	 * Get Assets
	 * @param primaryClientId primaryClientId
	 */
	getAsset(primaryClientId: number): Observable<Asset> {
		this.store.setIsLoading(true, ServicesCodes.Asset?.toLowerCase());
		this.store.setAsset({
			notes: [],
			customerServices: [],
		});

		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetAssetsByPrimaryClientId(x)),
			withLatestFrom(this.query.propertyAsset$),
			tap(([x, pa]) =>
				applyTransaction(() => {
					const data = objectUtil.mapPascalCaseToCamelCase(x);
					this.store.setAsset(
						assetServiceUtil({
							customerServices: data?.customerServices || [],
							notes: data?.notes || [],
						}),
					);

					const combineData = [...(pa?.customerServices || []), ...(data?.customerServices || [])];
					const sorted = propAndAssetServiceUtil({
						customerServices: combineData,
					});

					this.store.setPropertyAsset(sorted);
					this.store.setIsLoading(false, ServicesCodes.Asset?.toLowerCase());
				}),
			),
			map(([x]) => x),
			catchError(() => of({})),
		);
	}

	/**
	 * Get Liabilities
	 * @param primaryClientId primaryClientId
	 */
	getLiability(primaryClientId: number): Observable<Asset> {
		this.store.setIsLoading(true, ServicesCodes.Asset?.toLowerCase());
		this.store.setAsset({
			notes: [],
			customerServices: [],
		});

		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetLiabilityByPrimaryClientId(x)),
			withLatestFrom(this.query.liability$),
			tap(([x, _pa]) =>
				applyTransaction(() => {
					const data = objectUtil.mapPascalCaseToCamelCase(x);
					this.store.setLiability(data);

					// const combineData = [
					// 	...pa.customerServices,
					// 	...data.customerServices,
					// ];
					// const sorted = propAndAssetServiceUtil({ customerServices: combineData });

					// this.store.setPropertyAsset(sorted);
					this.store.setIsLoading(false, ServicesCodes.Liability?.toLowerCase());
				}),
			),
			map(([x]) => x),
			catchError(() => of({})),
		);
	}

	addLiability(liability: LiabilityCustomerServiceState): Observable<boolean> {
		return this.customerService.AddLiability(liability).pipe(
			tap((customerServiceId) => {
				liability.customerServiceID = customerServiceId;
				this.store.update((state) => {
					state.liability.customerServices;
					return {
						...state,
						...{
							liability: {
								...state.liability,
								customerServices: arrayAdd(state?.liability?.customerServices, liability),
							},
						},
					};
				});
			}),
			tap(() => this.getClientHistories(liability.customerID).pipe(take(1)).subscribe()),
		);
	}

	/**
	 * Get LrInsurance
	 * @param primaryClientId primaryClientId
	 */
	getFGInsurance(primaryClientId: number): Observable<FgInsurance> {
		this.store.setIsLoading(true, ServicesCodes.FG?.toLowerCase());
		this.store.setFgInsurance({
			totalInforceApi: 0,
			fGs: [],
		});

		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetFgInsurancesByPrimaryClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const fgInsuranceState = objectUtil.mapPascalCaseToCamelCase(x) as FgInsuranceState;
					// biome-ignore lint/suspicious/noExplicitAny: To Do: Extract type
					const mainFg: any = {};
					if (isObject(fgInsuranceState)) {
						mainFg.fGs = fgInsuranceState ? Object.keys(fgInsuranceState)?.map((i) => fgInsuranceState[i]) : [];
					}
					const fgInsurance = fgServiceUtil(mainFg, this.businessConfigService.companyCode());
					this.store.setFgInsurance(fgInsurance);
					this.store.setIsLoading(false, ServicesCodes.FG?.toLowerCase());
				}),
			),
			catchError(() => {
				this.store.setIsLoading(false, ServicesCodes.FG?.toLowerCase());
				return of({});
			}),
		);
	}

	/**
	 * Get All Kiwisavers
	 * @param primaryClientID Primary Client ID : number
	 */
	getKiwisavers(primaryClientID: number): Observable<Kiwisaver[]> {
		this.store.setIsLoading(true, ServicesCodes.KiwiSaver?.toLowerCase());
		this.store.setKiwiSaver([]);

		return of(primaryClientID).pipe(
			mergeMap((x) => this.customerService.GetKiwisaversByPrimaryClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const state = x
						? x.map((ks) => {
								const convertedToStringIds = ks.FundOwner?.map((k) => k.toString());
								if (convertedToStringIds) {
									ks.FundOwner = [...convertedToStringIds];
								}
								return objectUtil.mapPascalCaseToCamelCase(ks) as KiwisaverState;
							})
						: [];
					this.store.setKiwiSaver(kiwiSaverServiceUtil(state));
					this.store.setIsLoading(false, ServicesCodes.KiwiSaver?.toLowerCase());
				}),
			),
			catchError(() => of([])),
		);
	}

	/**
	 * Get Investments
	 * @param primaryClientId primaryClientId
	 */
	getInvestments(primaryClientId: number): Observable<Investment[]> {
		this.store.setIsLoading(true, ServicesCodes.Investment?.toLowerCase());
		this.store.setInvestments([]);

		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetInvestmentsByPrimaryClientId(x)),
			tap((x) =>
				applyTransaction(() => {
					const state = x
						? x.map((ks) => {
								const convertedToStringIds = ks.Investor?.map((k) => k.toString());
								if (convertedToStringIds) ks.Investor = [...convertedToStringIds];
								return objectUtil.mapPascalCaseToCamelCase(ks) as InvestmentState;
							})
						: [];
					this.store.setInvestments(kiwiSaverServiceUtil(state));
					this.store.setIsLoading(false, ServicesCodes.Investment?.toLowerCase());
				}),
			),
			catchError(() => of([])),
		);
	}

	convertToBase64 = (file, reader = new FileReader()) =>
		new Observable((obs) => {
			reader.onload = () => obs.next(reader.result);
			reader.onloadend = () => obs.complete();

			return reader.readAsDataURL(file);
		});

	/**
	 * Get Advice Processes
	 * @param id CustomerID
	 */
	getAdviceProcessesByPrimaryId(id: number): Observable<AdviceProcessState> {
		this.store.setIsLoading(true, ServicesCodes.AdviceProcess?.toLowerCase());
		return this.customerService.GetAdviceProcessesByPrimaryId(id, { status: 1 }).pipe(
			map((x) => AdviceProcessMapper.mapToSate(x)),
			tap((x) => applyTransaction(() => this.store.setAdviceProcesses(x))),
			finalize(() => this.store.setIsLoading(false, ServicesCodes.AdviceProcess?.toLowerCase())),
		);
	}

	/**
	 * Add Advice Process
	 * @param req AdviceProcess
	 */
	addAdviceProcess(req: ServiceAdviceProcessState) {
		return this.customerService.AddAdviceProcess(objectUtil.mapCamelCaseToPascalCase(req)).pipe(
			filter((x) => !!x),
			switchMap((x) => this.fetchAdviceProcessById(x, req)),
		);
	}

	fetchAdviceProcessById(id, req: ServiceAdviceProcessState) {
		return this.api
			.get<ServiceAdviceProcess>(`adviceprocesses/${id}`, {
				status: 1,
			})
			.pipe(
				map((x) => objectUtil.mapPascalCaseToCamelCase(x) as ServiceAdviceProcessState),
				tap((x) =>
					applyTransaction(() => {
						let data = this.query.getValue().adviceProcesses;
						if (this.getAdviceProcessService(x.processCode) === ServicesCodes.ClientAlterationRequest) {
							data = {
								...data,
								clientAlterationRequests: [...data.clientAlterationRequests, x],
							};
						}
						if (this.getAdviceProcessService(x.processCode) === ServicesCodes.LR) {
							if (x?.processCode === AdviceProcessCode.LRClaim) {
								data = {
									...data,
									lRClaimsAdviceProcesses: [...data.lRClaimsAdviceProcesses, x],
								};
							} else {
								data = {
									...data,
									lRAdviceProcesses: [...data.lRAdviceProcesses, x],
								};
							}
						}
						if (this.getAdviceProcessService(x.processCode) === ServicesCodes.Mortgage) {
							data = {
								...data,
								mortgageAdviceProcesses: [...data.mortgageAdviceProcesses, x],
							};
						}
						if (this.getAdviceProcessService(x.processCode) === ServicesCodes.KiwiSaver) {
							data = {
								...data,
								kiwiSaverAdviceProcesses: [...data.kiwiSaverAdviceProcesses, x],
							};
						}
						if (this.getAdviceProcessService(x.processCode) === ServicesCodes.BlanketAdvice) {
							data = {
								...data,
								blanketAdviceProcesses: [...data.blanketAdviceProcesses, x],
							};
						}
						if (this.getAdviceProcessService(x.processCode) === ServicesCodes.FG) {
							if (x?.processCode === AdviceProcessCode.FGClaim) {
								data = {
									...data,
									fGClaimsAdviceProcesses: [...data.fGClaimsAdviceProcesses, x],
								};
							} else {
								data = {
									...data,
									fGAdviceProcesses: [...data.fGAdviceProcesses, x],
								};
							}
						}
						if (this.getAdviceProcessService(x.processCode) === ServicesCodes.ComplaintAdvice) {
							data = {
								...data,
								complaintAdviceProcesses: [...data.complaintAdviceProcesses, x],
							};
						}
						if (this.getAdviceProcessService(x.processCode) === ServicesCodes.Investment) {
							data = {
								...data,
								investmentAdviceProcesses: [...data.investmentAdviceProcesses, x],
							};
						}
						this.store.setAdviceProcesses(data);
					}),
				),
				tap(() => this.getCriterias(req.customerID).pipe(take(1)).subscribe()),
				tap(() => this.getClientHistories(req.customerID).pipe(take(1)).subscribe()),
			);
	}

	/**
	 * KLOAT New Request for Syncing
	 * @param req AdviceProcess
	 */
	kloatNewRequest(req: ServiceAdviceProcessState) {
		const payload = objectUtil.mapCamelCaseToPascalCase(req);
		return this.customerService.AddAdviceProcess(payload).pipe(catchError((x) => of(x as KLOATSyncResponse)));
	}

	/**
	 * Update Advice Process
	 * @param req AdviceProcess
	 */
	updateAdviceProcess(
		req: ServiceAdviceProcessState,
		isEndProcess?: boolean,
		isReopen?: boolean,
		isStatusOnly?: boolean,
	) {
		const request = objectUtil.mapCamelCaseToPascalCase(req) as ServiceAdviceProcess;
		return of(!!isStatusOnly).pipe(
			switchMap((x) => (x ? of(request) : this.customerService.UpdateAdviceProcess(request))),
			map(() => request.AdviceProcessID),
			switchMap((x) =>
				this.api.get<ServiceAdviceProcess>(`adviceprocesses/${x}`, {
					status: 1,
				}),
			),
			switchMap((x) =>
				!!isEndProcess || !!isReopen || !!isStatusOnly
					? this.getActivityTimeline(request.CustomerID).pipe(map(() => x))
					: of(x),
			),
			switchMap((x) => (isEndProcess ? this.getPrimaryClient(request.CustomerID, '', true).pipe(map(() => x)) : of(x))),
			tap((x) =>
				applyTransaction(() => {
					const data = objectUtil.mapPascalCaseToCamelCase(x) as ServiceAdviceProcessState;
					let adviceProcess = this.query.getValue().adviceProcesses;
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.ClientAlterationRequest) {
						adviceProcess = {
							...adviceProcess,
							clientAlterationRequests: adviceProcess.clientAlterationRequests?.map((ap) =>
								ap?.adviceProcessID === data.adviceProcessID ? data : ap,
							),
						};
					}
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.LR) {
						if (data?.processCode === AdviceProcessCode.LRClaim) {
							adviceProcess = {
								...adviceProcess,
								lRClaimsAdviceProcesses: adviceProcess.lRClaimsAdviceProcesses?.map((ap) =>
									ap?.adviceProcessID === data.adviceProcessID ? data : ap,
								),
							};
						} else {
							adviceProcess = {
								...adviceProcess,
								lRAdviceProcesses: adviceProcess.lRAdviceProcesses?.map((ap) =>
									ap?.adviceProcessID === data.adviceProcessID ? data : ap,
								),
							};
						}
					}
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.Mortgage) {
						adviceProcess = {
							...adviceProcess,
							mortgageAdviceProcesses: adviceProcess.mortgageAdviceProcesses?.map((ap) =>
								ap?.adviceProcessID === data.adviceProcessID ? data : ap,
							),
						};
					}
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.KiwiSaver) {
						adviceProcess = {
							...adviceProcess,
							kiwiSaverAdviceProcesses: adviceProcess.kiwiSaverAdviceProcesses?.map((ap) =>
								ap?.adviceProcessID === data.adviceProcessID ? data : ap,
							),
						};
					}
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.BlanketAdvice) {
						adviceProcess = {
							...adviceProcess,
							blanketAdviceProcesses: adviceProcess.blanketAdviceProcesses?.map((ap) =>
								ap?.adviceProcessID === data.adviceProcessID ? data : ap,
							),
						};
					}
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.FG) {
						if (data?.processCode === AdviceProcessCode.FGClaim) {
							adviceProcess = {
								...adviceProcess,
								fGClaimsAdviceProcesses: adviceProcess.fGClaimsAdviceProcesses?.map((ap) =>
									ap?.adviceProcessID === data.adviceProcessID ? data : ap,
								),
							};
						} else {
							adviceProcess = {
								...adviceProcess,
								fGAdviceProcesses: adviceProcess.fGAdviceProcesses?.map((ap) =>
									ap?.adviceProcessID === data.adviceProcessID ? data : ap,
								),
							};
						}
					}
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.ComplaintAdvice) {
						adviceProcess = {
							...adviceProcess,
							complaintAdviceProcesses: adviceProcess.complaintAdviceProcesses?.map((ap) =>
								ap?.adviceProcessID === data.adviceProcessID ? data : ap,
							),
						};
					}
					if (this.getAdviceProcessService(data.processCode) === ServicesCodes.Investment) {
						adviceProcess = {
							...adviceProcess,
							investmentAdviceProcesses: adviceProcess.investmentAdviceProcesses?.map((ap) =>
								ap?.adviceProcessID === data.adviceProcessID ? data : ap,
							),
						};
					}
					this.store.setAdviceProcesses(adviceProcess);
				}),
			),
			tap(() => this.getCriterias(request.CustomerID).pipe(take(1)).subscribe()),
			tap(() => this.getClientHistories(request.CustomerID).pipe(take(1)).subscribe()),
			// tap(() =>
			// 	this.getSecondaryClients(request.CustomerID).pipe(take(1)).subscribe()
			// )
		);
	}

	/**
	 * Delete Advice Process
	 * @param id AdviceProcessID
	 */
	deleteAdviceProcess(id: number, code: string) {
		return this.customerService.DeleteAdviceProcess(id).pipe(
			switchMap((x) => this.getActivityTimeline(this.query.getValue().primaryClient.customerID).pipe(map(() => x))),
			tap(() =>
				applyTransaction(() => {
					let data = this.query.getValue().adviceProcesses;
					if (this.getAdviceProcessService(code) === ServicesCodes.ClientAlterationRequest) {
						data = {
							...data,
							clientAlterationRequests: [...data.clientAlterationRequests?.filter((x) => x.adviceProcessID !== id)],
						};
					}
					if (this.getAdviceProcessService(code) === ServicesCodes.LR) {
						if (code === AdviceProcessCode.LRClaim) {
							data = {
								...data,
								lRClaimsAdviceProcesses: [...data.lRClaimsAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
							};
						} else {
							data = {
								...data,
								lRAdviceProcesses: [...data.lRAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
							};
						}
					}
					if (this.getAdviceProcessService(code) === ServicesCodes.Mortgage) {
						data = {
							...data,
							mortgageAdviceProcesses: [...data.mortgageAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
						};
					}
					if (this.getAdviceProcessService(code) === ServicesCodes.KiwiSaver) {
						data = {
							...data,
							kiwiSaverAdviceProcesses: [...data.kiwiSaverAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
						};
					}
					if (this.getAdviceProcessService(code) === ServicesCodes.BlanketAdvice) {
						data = {
							...data,
							blanketAdviceProcesses: [...data.blanketAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
						};
					}
					if (this.getAdviceProcessService(code) === ServicesCodes.FG) {
						if (code === AdviceProcessCode.FGClaim) {
							data = {
								...data,
								fGClaimsAdviceProcesses: [...data.fGClaimsAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
							};
						} else {
							data = {
								...data,
								fGAdviceProcesses: [...data.fGAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
							};
						}
					}
					if (this.getAdviceProcessService(code) === ServicesCodes.ComplaintAdvice) {
						data = {
							...data,
							complaintAdviceProcesses: [...data.complaintAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
						};
					}
					if (this.getAdviceProcessService(code) === ServicesCodes.Investment) {
						data = {
							...data,
							investmentAdviceProcesses: [...data.investmentAdviceProcesses?.filter((x) => x.adviceProcessID !== id)],
						};
					}
					this.store.setAdviceProcesses(data);
				}),
			),
			tap(() => this.getCriterias(this.query.getValue().primaryClient.customerID).pipe(take(1)).subscribe()),
			tap(() => this.getClientHistories(this.query.getValue().primaryClient.customerID).pipe(take(1)).subscribe()),
		);
	}

	/**
	 * Get Document By ID
	 * @param documentId DocumentID
	 */
	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	getDocument$ = (documentId) => this.api.get<any>(`Documents/${documentId}`);

	/**
	 * Upload Document
	 * @param file DocumentFile
	 */
	uploadDocument$ = (file) =>
		this.api
			.post('Documents', file)
			.pipe(mergeMap((x) => this.getClientDocuments(file?.CustomerId).pipe(map(() => x))));

	/**
	 * Download Document
	 * @param documentID DocumentID
	 * @returns Download Link
	 */
	downloadDocument$ = (documentID) =>
		// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
		this.api.get<any>(`Documents/${documentID}`);

	/**
	 * Deactivate Document
	 * @param documentID DocumentID
	 */
	deleteDocument$ = (documentID) => this.api.delete<number>(`Documents/Deactivate/${documentID}`);

	/**
	 * Get Questionnaires
	 * @param code Code for questionnaires
	 */
	getAdviceProcessQuestionnaires$ = (code) =>
		// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
		this.api.get<any>(`adviceprocesses/settings/${code}`);

	/**
	 * Get Questionnaires for Business
	 * @param code Code for questionnaires
	 */
	getAdviceProcessQuestionnairesBusiness$ = (code, customerId) =>
		// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
		this.api.get<any>(`contacts/${customerId}/adviceprocesses/settings/${code}`);

	/**
	 * Get Advice Process Notes
	 * @param customerID number (CustomerID of PCI)
	 */
	getAdviceProcessNotes$ = (customerID, adviceProcessID) =>
		of(customerID).pipe(
			filter((x) => !!x),
			mergeMap(() =>
				// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
				this.api.get<any>(`contacts/${customerID}/adviceprocesses/${adviceProcessID}/crt-notes`),
			),
		);

	/**
	 * Add Advice Process Notes
	 * @param notes { adviceProcessId: number; notes: string; customerID: number }
	 */
	addAdviceProcessNotes$ = (notes: {
		referenceId: number;
		notes: string;
		customerID: number;
		type: string;
	}) =>
		this.api
			// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
			.post3<any>('notes/crt', objectUtil.mapCamelCaseToPascalCase(notes))
			.pipe(switchMap((x) => this.getActivityTimeline(notes?.customerID).pipe(map(() => x))));

	/**
	 * Get Advice Process Notes
	 * @param noteID: number
	 */
	deleteAdviceProcessNotes$ = (noteID) =>
		this.api
			// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
			.delete<any>(`notes/${noteID}/crt`)
			.pipe(
				switchMap((x) => this.getActivityTimeline(this.query.getValue().primaryClient.customerID).pipe(map(() => x))),
			);

	/**
	 * Transfer SCI
	 * @param customerId: number
	 */
	transferSCI(customer: SecondaryClientState) {
		return this.customerService.TransferSCI(customer).pipe(
			tap((x) =>
				applyTransaction(() => {
					customer.customerID = x;
					const state = objectUtil.mapPascalCaseToCamelCase(customer) as PrimaryClientState;
					this.store.setPrimaryClient(state);
				}),
			),
			catchError(() => of('')),
		);
	}

	updateAdviceProcessByApID(apId: number, isStarted?: boolean) {
		return this.api.get<ServiceAdviceProcess>(`adviceprocesses/${apId}?status=1`).pipe(
			switchMap((x) => {
				if (!!x && !!x.IsOnline && !x.IsStarted) {
					// Don't populate fact find if already started and offline
					const mapItem = {
						...x,
						Documents: x.Documents?.map((d) => ({
							...d,
							Value: d.Value?.DocumentID ? d.Value?.DocumentID : null,
						})),
					};
					return this.api.put(`adviceprocesses/${apId}`, {
						...mapItem,
						IsStarted: isStarted,
					});
				} else {
					return of(x);
				}
			}),
		);
	}

	updateClaimAdviceProcess(apCode: string, data) {
		// Update Policy Numbers on Linked Claim Advice Processes
		const csId = data?.customerServiceID;
		const updatePolicyNumber = (ap: ServiceAdviceProcessState, policyName: string) => {
			const customerServices = util.tryParseJson(ap?.customerServiceID);
			const i = customerServices?.findIndex((x) => +x === +csId);
			if (i < 0) {
				// If Advice Process has no Linked Policy Number related
				return ap;
			}
			const policyNumbers =
				(ap?.policyNumber?.split(',') || [])?.map((p, index) => (index === i ? policyName : p?.trim())) || [];
			return {
				...ap,
				policyNumber: policyNumbers?.join(', '),
			};
		};

		of(true)
			.pipe(
				withLatestFrom(this.adviceProcesses$),
				tap(([, aps]) => {
					switch (apCode) {
						case AdviceProcessCode.LRClaim: {
							const lrPolicyName = `${data?.policyNumber}${
								data?.policyNumberSuffix ? '-' + data?.policyNumberSuffix : ''
							}`;
							const listLr = aps?.lRClaimsAdviceProcesses?.map((x) => updatePolicyNumber(x, lrPolicyName));
							this.store.setAdviceProcesses({
								...aps,
								lRClaimsAdviceProcesses: listLr,
							});
							break;
						}
						case AdviceProcessCode.FGClaim: {
							const fgPolicyName = `${data?.fGPolicyNumber}${
								data?.fGPolicyNumberSuffix ? '-' + data?.fGPolicyNumberSuffix : ''
							}`;
							const listFg = aps?.fGClaimsAdviceProcesses?.map((x) => updatePolicyNumber(x, fgPolicyName));
							this.store.setAdviceProcesses({
								...aps,
								fGClaimsAdviceProcesses: listFg,
							});
							break;
						}
					}
				}),
				take(1),
			)
			.subscribe();
	}

	getPeople = (apId: number) =>
		this.api.get(`crt/fact-find/${apId}/${AdviceProcessSectionCodes.People}`).pipe(
			catchError(() => {
				return of([]);
			}),
			// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
			concatMap((x: any) =>
				iif(
					() => !!x && x.length > 0,
					of(x),
					this.api.post3(`crt/fact-find/${apId}/${AdviceProcessSectionCodes.People}`),
				),
			),
		);

	getAssetsAndLiabilities = (apId: number) =>
		this.api.get(`crt/fact-find/${apId}/${AdviceProcessSectionCodes.Property}`).pipe(
			catchError(() => {
				return of([]);
			}),
			// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
			concatMap((x: any) =>
				iif(
					() => !!x && x.length > 0,
					of(x),
					this.api.post3(`crt/fact-find/${apId}/${AdviceProcessSectionCodes.Property}`),
				),
			),
		);

	prePopulatePeople(apId: number) {
		return concat(this.getPeople(apId), this.getAssetsAndLiabilities(apId)).pipe(map(() => true));
	}

	getSoa$ = (apId: number) => {
		return this.api.get<[]>(`crt/${apId}/${AdviceProcessSectionCodes.StatementOfAdvice}`);
	};

	getAdviceProcessCRT$ = (adviceProcessId: number, sectionCode: string): Observable<FinalStructure> =>
		this.api
			.get<FinalStructure[]>(`crt/${adviceProcessId}/${sectionCode}`)
			.pipe(map((x) => (!!x && x.length > 0 ? x[0] : null)));

	private mapDocumentForUpsert(ap: ServiceAdviceProcessState): ServiceAdviceProcessState {
		if (!ap?.documents) {
			return ap;
		}
		ap.documents = ap.documents?.map((d) => {
			if (d?.value?.documentID) {
				// @ts-ignore-next
				d.value = d?.value?.documentID;
			}
			return d;
		});
		return ap;
	}

	closeClaim(ap: ServiceAdviceProcessState, isEndProcess?: boolean, isReopen?: boolean) {
		ap.endProcessDate = MomentUtil.formatDateToServerDate(MomentUtil.createMomentNz());
		ap = this.mapDocumentForUpsert(ap);
		const endpoint = `adviceprocesses/${ap.adviceProcessID}/change-status/${ap.status}`;
		return this.api.put(endpoint, objectUtil.mapCamelCaseToPascalCase(ap)).pipe(
			switchMap(() => {
				return this.updateAdviceProcess({ ...ap }, isEndProcess, isReopen, false);
			}),
		);
	}

	cancelAdviceProcess(
		cap: { referenceId: number; cancellationReason: string; notes: string },
		ap?: ServiceAdviceProcessState,
	) {
		const req = { ...cap, type: 'AP' };
		const endpoint = `adviceprocesses/${req.referenceId}/change-status/6`;
		ap = this.mapDocumentForUpsert(ap);
		return this.api
			.put(endpoint, objectUtil.mapCamelCaseToPascalCase(req))
			.pipe(switchMap(() => this.updateAdviceProcess({ ...ap, status: 6 }, false, false, true)));
	}

	/**
	 * Get All Linked Contacts
	 * @param primaryClientID Primary Client ID : number
	 */
	getLinkedContacts(primaryClientID: number): Observable<LinkedContact[]> {
		this.store.setIsLoading(true, CustomerTypes.LinkedContact?.toLowerCase());
		return of(primaryClientID).pipe(
			mergeMap((x) => this.customerService.GetLinkedContactsByPrimaryClient(x)),
			tap((x) =>
				applyTransaction(() => {
					const state = x ? (x.map(objectUtil.mapPascalCaseToCamelCase) as LinkedContactState[]) : [];
					this.store.setLinkedContacts(linkedContactSortUtil(state));
					this.store.setIsLoading(false, CustomerTypes.LinkedContact?.toLowerCase());
				}),
			),
			catchError(() => of([])),
		);
	}

	updateLinkedContact(req: LinkedContactState) {
		const clientId = +this.query.getValue().primaryClient?.customerID;
		return of(req).pipe(
			mergeMap((x) => this.customerService.UpdateLinkedContacts(x)),
			tap(() => this.getClientHistories(+clientId).pipe(take(1)).subscribe()),
			tap(() => {
				this.getLinkedContacts(clientId).pipe(take(1)).subscribe();
			}),
			catchError((e) => {
				return throwError(e);
			}),
		);
	}

	updatePeople(people: PeopleState) {
		const endpoint = `crt/${people.cRTId}`;
		const body = objectUtil.mapCamelCaseToPascalCase(people);
		return this.api.put<PeopleDetails>(endpoint, body).pipe(
			tap(() => {
				const customerID = this.query.getValue()?.primaryClient?.customerID;
				this.getPrimaryClient(customerID, '', true).pipe(take(1)).subscribe();
			}),
			catchError(() => {
				return EMPTY;
			}),
		);
	}
}
