import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { uniq } from 'ramda';
import { util } from 'src/app/core/util/util.service';
import MomentUtil from 'src/app/util/moment.util';
import {
	AiFields,
	AiQuoteDataPolicyQuotedProductsState,
	AiQuoteDataResponseState,
	AiQuoteFormLifeAssuredState,
	AiQuoteFormState,
	AiQuoteResultState,
} from '../ai-quote/ai-quote.model';

// biome-ignore lint/complexity/noStaticOnlyClass: Mapper
export class AiQuoteMapper {
	public static mapProcessAiToView(
		res: AiQuoteResultState,
		lifeAssuredDropdownList: ViewDisplayValue[],
		productList: ViewDisplayValue[],
		// biome-ignore lint/suspicious/noExplicitAny: dynamic form value
		formValue: any,
	) {
		const hasValue = (key: string, fromData: AiQuoteDataPolicyQuotedProductsState | AiQuoteDataResponseState) => {
			return !!fromData?.[key] && fromData?.[key] !== 'N/A';
		};
		const isProductExist = (val: string) => {
			return !!productList?.find((x) => x?.display === val);
		};
		const getLifeAssuredId = (val: string) => {
			return lifeAssuredDropdownList?.find((x) => x?.display === val)?.value;
		};
		const getFormattedDate = (val: string) => {
			const sDate = val?.split('/');
			const formatted = sDate?.length === 3 ? `${sDate?.[2]}-${sDate?.[1]}-${sDate?.[0]}` : null;
			return formatted && util.isValidMoment(formatted) ? formatted : MomentUtil.formatDateToMoment(formatted);
		};
		const data = res?.data as AiQuoteDataResponseState;
		const owners = uniq(
			(data?.[AiFields.Policies] || [])
				?.map((x) => {
					const val = x?.[AiFields.QuotedFor];
					const o = lifeAssuredDropdownList?.find((p) => p?.display === val);
					return o ? o?.value : null;
				})
				?.filter(Boolean),
		);
		let indexStart = formValue?.lifeAssuredList?.length - 1;
		const products = data?.[AiFields.Policies]?.[0]?.[AiFields.QuotedProducts] || [];
		const lifeAssuredList = products?.map((x: AiQuoteDataPolicyQuotedProductsState) => {
			indexStart++;
			const startDate = hasValue(AiFields.QuoteDate, x) ? x?.[AiFields.QuoteDate] : '';

			// TAP1-3856 Show notes only if Product is 'Other' & 'Other' exists on its Dropdown settings
			const showNotes =
				hasValue(AiFields.Product, x) && isProductExist(x?.[AiFields.Product]) && x?.[AiFields.Product] === 'Other';

			return {
				id: +indexStart,
				benefit: x?.[AiFields.Benefit]?.value || '',
				notes: hasValue(AiFields.Notes, x) && showNotes ? x?.[AiFields.Notes] : '',
				premium: hasValue(AiFields.Premium, x) ? x?.[AiFields.Premium] : '',
				product: hasValue(AiFields.Product, x) && isProductExist(x?.[AiFields.Product]) ? x?.[AiFields.Product] : '',
				startDate: getFormattedDate(startDate),
				lifeAssuredDisplay: hasValue(AiFields.QuotedFor, x) ? x?.[AiFields.QuotedFor] : '',
				lifeAssured: getLifeAssuredId(x?.[AiFields.QuotedFor]),
				showDropdown: false,
				btnAdd: false,
				name: null,
				newExisting: null,
				priority: null,
			} as AiQuoteFormLifeAssuredState;
		});

		return {
			lifeAssuredList,
			policyOwner: owners,
			policyNumber: hasValue(AiFields.QuoteNumber, data) ? data?.[AiFields.QuoteNumber] : '',
			provider: hasValue(AiFields.Provider, data) ? data?.[AiFields.Provider] : '',
			policyFee: data?.[AiFields.Policies]?.[0]?.PolicyFee || '',
		} as AiQuoteFormState;
	}
}
