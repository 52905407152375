import { find, forEach, keys, pluck } from 'ramda';
import { ValueRef } from 'src/app/modules/advice-process/advice-process.component';
import { ViewDisplayValue } from '../_general/display-value.viewmodel';
import { ServicesCodes } from '../services/services.model';

export interface DynamicField {
	Field: string;
	// biome-ignore lint/suspicious/noExplicitAny: Type any
	Value: any;
	Type: string;
}

export interface DynamicFieldState {
	field: string;
	// biome-ignore lint/suspicious/noExplicitAny: Type any
	value: any;
	type: string;
}

export interface AdviceProcessStage {
	Field: string;
	Value: string;
	Type: string;
}

export interface AdviceProcessStageState {
	field: string;
	value: string;
	type: string;
}

export interface AdviceProcessDocument {
	Field: string;
	Value: {
		DocumentID: number;
		DocumentName: string;
		FileName: string;
	};
	Type: string;
}

export interface AdviceProcessDocumentState {
	showCreateButton?: boolean;
	field: string;
	value: AdviceDocumentStateValue;
	type: string;
}

export interface AdviceDocumentStateValueArray {
	showCreateButton?: boolean;
	field: string;
	value: AdviceDocumentStateValue | AdviceDocumentStateValue[];
	type: string;
}

export interface AdviceDocumentStateValue {
	documentID: number;
	documentName: string;
	fileName: string;
}

export enum AdviceProcessNames {
	LRAdviceNew = 'L&R Advice - New',
	LRAdviceReview = 'L&R Advice - Review',
	LRCustomerAlterationRequest = 'L&R - Client Alteration Request',
	MortgageAdvice = 'Mortgage Advice',
	MortgageRefix = 'Mortgage Refix',
	KiwiSaverAdvice = 'KiwiSaver Advice',
	BlanketAdvice = 'Blanket Advice',
	Complaint = 'Complaint',
	Investment = 'Investment Advice',

	FGDomesticNewBusinessAdvice = 'F&G Domestic - New Business Advice',
	FGCommercialNewBusinessAdvice = 'F&G Commercial - New Business Advice',
	FGDomesticMTAAdvice = 'F&G Domestic - MTA Advice',
	FGCommercialMTAAdvice = 'F&G Commercial - MTA Advice',
	FGDomesticRenewalAdvice = 'F&G Domestic - Renewal Advice',
	FGCommercialRenewalAdvice = 'F&G Commercial - Renewal Advice',

	// Claims
	FGClaim = 'F&G Claim',
	LRClaim = 'L&R Claim',
}

export enum AdviceProcessCode {
	LRAdviceNew = 'APLN',
	LRAdviceReview = 'APLR',
	ClientAlterationRequest = 'CARLR',
	MortgageAdvice = 'APM',
	MortgageRefix = 'APMR',
	KiwiSaverAdvice = 'APK',
	BlanketAdvice = 'APB',
	Complaint = 'APC',
	LRAdviceAlteration = 'APLRA',
	Investment = 'API',

	FGDomesticNewBusinessAdvice = 'APFGDNBA',
	FGCommercialNewBusinessAdvice = 'APFGCNBA',
	FGDomesticMTAAdvice = 'APFGDMA',
	FGCommercialMTAAdvice = 'APFGCMA',
	FGDomesticRenewalAdvice = 'APFGDRA',
	FGCommercialRenewalAdvice = 'APFGCRA',

	// Claims
	FGClaim = 'APFGC',
	LRClaim = 'APLRC',
}

export enum AdviceProcessStages {
	// L&R
	MeetingBooked = 'Meeting Booked',
}

interface AdviceCodes {
	id: string;
	codes: string[];
}

export const AdviserReworkAdviserCode: AdviceCodes[] = [
	{
		id: 'lRAdviser',
		codes: [
			AdviceProcessCode.LRAdviceNew,
			AdviceProcessCode.LRAdviceReview,
			AdviceProcessCode.LRAdviceAlteration,
			AdviceProcessCode.ClientAlterationRequest,
			AdviceProcessCode.LRClaim,
		],
	},
	{
		id: 'mortgageAdviser',
		codes: [AdviceProcessCode.MortgageAdvice, AdviceProcessCode.MortgageRefix],
	},
	{
		id: 'fGAdviser',
		codes: [
			AdviceProcessCode.FGDomesticNewBusinessAdvice,
			AdviceProcessCode.FGCommercialNewBusinessAdvice,
			AdviceProcessCode.FGDomesticMTAAdvice,
			AdviceProcessCode.FGCommercialMTAAdvice,
			AdviceProcessCode.FGDomesticRenewalAdvice,
			AdviceProcessCode.FGCommercialRenewalAdvice,
			AdviceProcessCode.FGClaim,
		],
	},
	{
		id: 'kSAdviser',
		codes: [AdviceProcessCode.KiwiSaverAdvice],
	},
	{
		id: 'investmentAdviser',
		codes: [AdviceProcessCode.Investment],
	},
	{
		id: 'gIAdviser',
		codes: [],
	},
];
export const AdviserReworkAdviserCodePascal = AdviserReworkAdviserCode?.map((x: AdviceCodes) => ({
	...x,
	id: x?.id?.charAt(0)?.toUpperCase() + x?.id?.slice(1),
}));

export const GetAllAdviserReworkCodes = pluck('id', AdviserReworkAdviserCode);
export const GetAllAdviserReworkCodesPascal = pluck('id', AdviserReworkAdviserCodePascal);

export const GetAdviserReworkAdviserCode = (apCode: string) => {
	const id = AdviserReworkAdviserCode?.find((x) => x?.codes?.includes(apCode))?.id;
	return id || '';
};

export const GetAdviceProcessValueRef = () => {
	const keys: string[] = Object.keys(AdviceProcessNames);
	const apValueRefs: ValueRef[] = [];
	const makeValueRef = (key: string) => {
		apValueRefs.push({
			name: AdviceProcessNames[key as keyof typeof AdviceProcessNames],
			processCode: AdviceProcessCode[key as keyof typeof AdviceProcessCode],
		});
	};
	forEach(makeValueRef, keys);
	return apValueRefs;
};

export const GetServiceByAdviceProcessCode = (code: string) => {
	const processCodeKey = find(
		(key: string) => AdviceProcessCode[key as keyof typeof AdviceProcessCode] === code,
		Object.keys(AdviceProcessCode),
	);
	const serviceKeys = Object.keys(ServicesCodes);
	const serviceKeyForCode = find(
		(key: string) => (processCodeKey ? key?.includes(processCodeKey) || processCodeKey?.includes(key) : false),
		serviceKeys,
	);
	if (serviceKeyForCode) {
		return ServicesCodes[serviceKeyForCode as keyof typeof ServicesCodes]?.toString();
	}
	return null;
};

export enum AdviceProcessOnlineRoutes {
	Mortgage = 'mortgage',
	KiwiSaver = 'kiwisaver',
	ClientAlterationRequest = 'car',
	MortgageV2 = 'moat-v2',
}

export enum AdviceProcessStatus {
	Deleted = 0,
	Active = 1,
	Archived = 2,
	Complete = 3,
	Incomplete = 4,
	Reopened = 5,
}

export enum AdviceProcessStatusState {
	deleted = 0,
	active = 1,
	archived = 2,
	complete = 3,
	incomplete = 4,
	reopened = 5,
	cancelled = 6,
}

export enum AdviceProcessPageCodes {
	Introduction = 'API',
	Disclosure = 'APDD',
	SOS = 'APSOS',
	People = 'APP',
	MoatPeople = 'MAPP',
	AssetsLiabilities = 'APAL',
	IncomeExpenses = 'APIB',
	Income = 'MI',
	Expenses = 'ME',
	MedicalHistory = 'APMH',
	CurrentInsurance = 'APCI',
	FG = 'APFG',
	Goals = 'APG',
	Life = 'APL',
	Disability = 'APD',
	TPD = 'APTPD',
	CriticalIllnness = 'APCRI',
	Medical = 'APM',
	RiskProfile = 'APRP',
	Declaration = 'APDC',
	KiwiSaverDeclaration = 'APKD',
	SOA = 'APSOA',
	AuthorityToProceed = 'APCA',
	FinalStructure = 'APFS',
	Application = 'MA',
	StructureSOA = 'MS', // LATER
	ROA = 'MROA',
	FactFind = 'APFF',
	YourKiwiSaver = 'APYK',
	WhyKiwiSaver = 'APWK',
	HowKiwiSaverWorks = 'APHKW',
	PassiveVsActive = 'APPVA',
	RiskVsVolatility = 'APRVV',
	KiwiSaverProjection = 'APKSP',
	// RiskProfile = 'APRP',
	RiskProfileReview = 'APRPR',
	Providers = 'APKP',
	AdviceSummary = 'APAS',
	InvestmentStrategy = 'APIS',
	ClientFactFind = 'APLFFD',
	LOATSOA = 'APLSOA',

	LOATV2RiskAnalysisLife = 'LOATRAL',
	LOATV2RiskAnalysisDisability = 'LOATRAD',
	LOATV2RiskAnalysisTPD = 'LOATRAT',
	LOATV2RiskAnalysisMedical = 'LOATRAM',
	LOATV2RiskAnalysisCriticalIllness = 'LOATRACI',

	// CAR DISCLOSURE
	CARDisclosure = 'APCARDD',

	//KLOAT
	KiwiSaverRiskProfile = 'APKSRP',
	KiwiSaverSummary = 'APKSS',
}

export enum AdviceProcessPageNamesByCode {
	API = 'Introduction',
	APDD = 'Disclosure Document',
	APSOS = 'Scope of Service',
	APP = 'People & Entities',
	APAL = 'Assets and Liabilities',
	APIB = 'Income and Expenses',
	APMH = 'Medical History',
	APCI = 'Current Insurance',
	APFG = 'F&G',
	APG = 'Goals',
	APL = 'Life',
	APD = 'Disability',
	APTPD = 'TPD',
	APCRI = 'Critical Illness',
	APM = 'Medical',
	APRP = 'Risk Profile',
	APDC = 'Declaration',
	APSOA = 'Statement of Advice',
	APCA = 'Authority to Proceed',
	APFS = 'Final Structure',
	APLFFD = 'ClientFactFind',
}

export enum LoatAdviceProcessPageNotesNamesByCode {
	LOATRAL = 'LOAT Life',
	LOATRAD = 'LOAT Disability',
	LOATRAT = 'LOAT TPD',
	LOATRAM = 'LOAT Medical',
	LOATRACI = 'LOAT Critical Illness',
}

export enum AdviceProcessMortgagePageNamesByCode {
	API = 'Introduction',
	APDD = 'Disclosure Document',
	APSOS = 'Scope of Service',
	MAPP = 'People',
	APAL = 'Assets & Liabilities',
	MI = 'Income',
	ME = 'Expenses',
	APDC = 'PIA & Declaration',
	MA = 'Application',
	ROA = 'Record of Advice',
}

export const GetAdviceProcessPageViewDisplay = () => {
	const codes: string[] = keys(AdviceProcessPageNamesByCode);
	const codeValueAndDisplay: ViewDisplayValue[] = [];
	const createViewDisplay = (code: string) => {
		const codeDisplay: ViewDisplayValue = {
			value: code,
			display: AdviceProcessPageNamesByCode[code as keyof typeof AdviceProcessPageNamesByCode],
		};
		codeValueAndDisplay.push(codeDisplay);
	};
	forEach(createViewDisplay, codes);
	return codeValueAndDisplay;
};

export const GetLoatAdviceProcessPageNoteViewDisplay = () => {
	const codes: string[] = keys(LoatAdviceProcessPageNotesNamesByCode);
	const codeValueAndDisplay: ViewDisplayValue[] = [];
	const createViewDisplay = (code: string) => {
		const codeDisplay: ViewDisplayValue = {
			value: code,
			display: LoatAdviceProcessPageNotesNamesByCode[code as keyof typeof LoatAdviceProcessPageNotesNamesByCode],
		};
		codeValueAndDisplay.push(codeDisplay);
	};
	forEach(createViewDisplay, codes);
	return codeValueAndDisplay;
};

export const GetAdviceProcessMortgagePageViewDisplay = () => {
	const codes: string[] = keys(AdviceProcessMortgagePageNamesByCode);
	const codeValueAndDisplay: ViewDisplayValue[] = [];
	const createViewDisplay = (code: string) => {
		const codeDisplay: ViewDisplayValue = {
			value: code,
			display: AdviceProcessMortgagePageNamesByCode[code as keyof typeof AdviceProcessMortgagePageNamesByCode],
		};
		codeValueAndDisplay.push(codeDisplay);
	};
	forEach(createViewDisplay, codes);
	return codeValueAndDisplay;
};

export const FactFindCodes = [
	AdviceProcessPageCodes.People,
	AdviceProcessPageCodes.AssetsLiabilities,
	AdviceProcessPageCodes.IncomeExpenses,
	AdviceProcessPageCodes.MedicalHistory,
	AdviceProcessPageCodes.CurrentInsurance,
	AdviceProcessPageCodes.FG,
];

export const RiskAnalysisCodes = [
	AdviceProcessPageCodes.Goals,
	AdviceProcessPageCodes.Life,
	AdviceProcessPageCodes.Disability,
	AdviceProcessPageCodes.TPD,
	AdviceProcessPageCodes.CriticalIllnness,
	AdviceProcessPageCodes.Medical,
	AdviceProcessPageCodes.RiskProfile,
];

export enum AdviceProcessRoutes {
	Introduction = 'intro',
	Disclosure = 'disclosure',
	SOS = 'scope-of-service',
	FactFind = 'fact-find',
	People = 'fact-find/people',
	AssetsLiabilities = 'fact-find/assests-and-liabilities',
	IncomeExpenses = 'fact-find/income-and-budget',
	MedicalHistory = 'fact-find/medical-history',
	CurrentInsurance = 'fact-find/current-insurance',
	FG = 'fact-find/fg-insurance',
	RiskAnalysis = 'risk-analysis',
	Goals = 'risk-analysis/goals',
	Life = 'risk-analysis/life',
	Disability = 'risk-analysis/disability',
	TPD = 'risk-analysis/tpd',
	CriticalIllnness = 'risk-analysis/critical-illness',
	Medical = 'risk-analysis/medical',
	RiskProfile = 'risk-analysis/risk-profile',
	Declaration = 'declaration',
	SOA = 'statement-of-advice',
	AuthorityToProceed = 'client-acceptance',
	FinalStructure = 'final-structure',

	AdviceSummary = 'advice-summary',

	//Kiwisaver
	YourKiwisaver = 'kiwisaver/your-kiwisaver',
	HowKiwiSaverWorks = 'kiwisaver/how-kiwisaver-works',
	InvestmentStrategy = 'kiwisaver/investment-strategy',
	KiwiSaverProjection = 'kiwisaver/kiwisaver-projection',
	KiwiSaverRiskProfile = 'kiwisaver/risk-profile',
	KiwiSaverSummary = 'kiwisaver/summary',
}

export enum MOATProcessRoutes {
	Introduction = 'intro',
	Disclosure = 'disclosure',
	ScopeOfService = 'scope-of-service',
	ClientSOP = 'client-sop',
	People = 'client-sop/people',
	AssetsLiabilities = 'client-sop/assets-and-liabilities',
	Expenses = 'client-sop/expenses',
	Income = 'client-sop/income',
	Declaration = 'declaration',
	Application = 'application',
	StructureSOA = 'structure-soa', // LATER
	ROA = 'record-of-advice',
}

export enum AdviceProcessSubRoutes {
	People = 'people',
	AssetsLiabilities = 'assests-and-liabilities',
	IncomeExpenses = 'income-and-budget',
	MedicalHistory = 'medical-history',
	CurrentInsurance = 'current-insurance',
	FG = 'fg-insurance',
	Goals = 'goals',
	Life = 'life',
	Disability = 'disability',
	TPD = 'tpd',
	CriticalIllnness = 'critical-illness',
	Medical = 'medical',
	RiskProfile = 'risk-profile',
	YourKiwisaver = 'your-kiwisaver',
	HowKiwiSaverWorks = 'how-kiwisaver-works',
	InvestmentStrategy = 'investment-strategy',
	KiwiSaverProjection = 'kiwisaver-projection',
	Summary = 'summary',
}

export enum AdviceProcessDocumentField {
	Declaration = 'PIA & Declaration',
	SOS = 'Scope of Service',
	FactFind = 'Client Fact Find',
	SOA = 'Statement of Advice',
	AuthorityToProceed = 'Authority to Proceed',
	FinalStructure = 'Final Structure',
	Disclosure = 'Disclosure Document',
	Research = 'Research',
	ConfirmationCall = 'Confirmation Call',
	CARFinalisedStructure = 'Finalised Structure',
}

export enum MOATDocumentField {
	Disclosure = 'Disclosure',
	SOS = 'Scope of Service',
	FactFindDeclaration = 'Fact Find & Declaration',
	LetterOfOffer = 'Letter of Offer',
	ROA = 'Record of Advice',
	SignedLoanStructure = 'Signed Loan Structure',
	SettlementStatement = 'Settlement Statement',
}

export enum KOATDocumentField {
	// online
	Disclosure = 'Disclosure Document',
	SOS = 'Scope of Service',
	FactFind = 'Client Fact Find',
	RiskProfileReview = 'Risk Profile Review',
	KiwiSaverInformation = 'KiwiSaver Information',
	// offline
	Declaration = 'Declaration',
	InvestorConfirmation = 'Investor Confirmation',
	// common
	AdviceSummary = 'Advice Summary',
}

export enum ClaimsDocumentField {
	Others = 'Other',
}

export const KOATDocumentsDisplayOffline: string[] = [
	KOATDocumentField.Declaration,
	KOATDocumentField.InvestorConfirmation,
	KOATDocumentField.AdviceSummary,
];

const LRDocumentFieldKeys = Object.keys(AdviceProcessDocumentField);
const MOATDocumentFieldKeys = Object.keys(MOATDocumentField);
const KOATDocumentFieldKeys = Object.keys(KOATDocumentField);

export enum KOATProcessRoutes {
	Introduction = 'intro',
	Disclosure = 'disclosure',
	ScopeOfService = 'scope-of-service',
	Declaration = 'declaration',
	FactFind = 'fact-find',
	AdviceSummary = 'advice-summary',
}

export const GetPageCodeForDocumentField = (field: string, processCode: string) => {
	let fields: unknown;
	let fieldKeys = [];
	if (processCode === AdviceProcessCode.LRAdviceNew || processCode === AdviceProcessCode.LRAdviceReview) {
		fields = AdviceProcessDocumentField;
		fieldKeys = LRDocumentFieldKeys;
	} else if (processCode === AdviceProcessCode.MortgageAdvice || processCode === AdviceProcessCode.MortgageRefix) {
		fields = MOATDocumentField;
		fieldKeys = MOATDocumentFieldKeys;
	} else if (processCode === AdviceProcessCode.KiwiSaverAdvice) {
		fields = KOATDocumentField;
		fieldKeys = KOATDocumentFieldKeys;
	}
	const findKey = (key: string) => {
		return fields[key]?.toString() === field;
	};
	const keyFound = find(findKey, fieldKeys);
	if (keyFound) {
		return AdviceProcessPageCodes[keyFound as keyof typeof AdviceProcessPageCodes];
	} else {
		return null;
	}
};

export const MOATDocumentsDisplayOffline: string[] = [
	MOATDocumentField.LetterOfOffer,
	MOATDocumentField.SettlementStatement,
	MOATDocumentField.SignedLoanStructure,
];

export enum AdviceProcessSectionCodes {
	Introduction = 'IP',
	People = 'FPP',
	Dependants = 'FPD',
	Trust = 'FPT',
	Company = 'FPC',
	Property = 'FAP',
	KiwiSaver = 'FAK',
	AssetsInvestments = 'FAA',
	FFComputation = 'FFC',
	Mortgages = 'FAM',
	Liabilities = 'FAL',
	AssetsLiabilities = 'FFAL',
	Others = 'FAO',
	IncomeSource = 'FII',
	RentalIncome = 'FIR',
	OtherIncome = 'FIO',
	MonthlyExpense = 'FIM',
	Statistics = 'FMS',
	MedicalHistory = 'FMM',
	FamilyHistory = 'FMF',
	GPDetails = 'FMG',
	LR = 'FCL',
	FG = 'FFF',
	Goals = 'RAG',
	Life = 'RAL',
	Disability = 'RAD',
	TPD = 'RAT',
	Medical = 'RAM',
	RiskProfile = 'RARP',
	CriticalIllness = 'RACI',
	SOS = 'SOS',
	HouseInsurance = 'FFGHI',
	CarInsurance = 'FFGCI',
	ContentInsurance = 'FFGCOI',
	FinalStructure = 'FS',
	AuthorityToProceed = 'CA',
	Declaration = 'D',
	Disclosure = 'DD',
	StatementOfAdvice = 'SOA',
	MOATRecordOfAdvice = 'MOATROA',
	Application = 'MA',
	ApplicationDocument = 'MAD',
	Purpose = 'MAP',
	PropertyPurchase = 'MAFRP',
	PropertySold = 'MAFRPS',
	Notes = 'MAN',
	FundingRequired = 'MAFR',
	FundingRequiredLoan = 'MAFRL',
	Security = 'MAS',
	ReviewApplication = 'MARA',
	ROA = 'MROA',
	LoanSplit = 'MROALSLS',
	LoanStructure = 'MROALS',
	OtherDetails = 'MROAOD',
	PreviousIncome = 'FPI',
	EndProcess = 'APEP', // L&R End Process
	MortgageEndProcess = 'MOATAPEP', // Mortgage End Process
	TopupRequirements = 'MAFRTR',
	LoanRefinance = 'MAFRR',
	RoaSecurity = 'MROAS',
	ApplicationLoanStructure = 'MALS',
	KiwiSaverEndProcess = 'KOATAPEP',
	KiwiSaverEndProcessPrefills = 'KOATFFIG',
	KiwiSaverAdviseSummaryPeople = 'KOATASPP',
	KiwiSaverAdviseSummaryDependent = 'KOATASPD',
	AdviceSummary = 'KOATAS',
	ExistingPolicyStructure = 'FSEPS',
	SOAEmailSettings = 'LOATSOAES',
}

export const FactFindSubSectionCodes = [
	AdviceProcessSectionCodes.People,
	AdviceProcessSectionCodes.Dependants,
	AdviceProcessSectionCodes.Trust,
	AdviceProcessSectionCodes.Company,
	AdviceProcessSectionCodes.Property,
	AdviceProcessSectionCodes.KiwiSaver,
	AdviceProcessSectionCodes.AssetsInvestments,
	AdviceProcessSectionCodes.Mortgages,
	AdviceProcessSectionCodes.Liabilities,
	AdviceProcessSectionCodes.AssetsLiabilities,
];

export const FactFindSubSectionCodesMOAT = [
	AdviceProcessSectionCodes.IncomeSource,
	AdviceProcessSectionCodes.PreviousIncome,
	AdviceProcessSectionCodes.MonthlyExpense,
	AdviceProcessSectionCodes.RentalIncome,
	AdviceProcessSectionCodes.OtherIncome,
];

export const RiskAnalysisSubSectionCodes = [
	AdviceProcessSectionCodes.Goals,
	AdviceProcessSectionCodes.RiskProfile,
	AdviceProcessSectionCodes.Life,
	AdviceProcessSectionCodes.CriticalIllness,
	AdviceProcessSectionCodes.Disability,
	AdviceProcessSectionCodes.Medical,
	AdviceProcessSectionCodes.TPD,
];

export const AssetsSubSectionCodes = [
	AdviceProcessSectionCodes.Property,
	AdviceProcessSectionCodes.KiwiSaver,
	AdviceProcessSectionCodes.AssetsInvestments,
	AdviceProcessSectionCodes.Mortgages,
	AdviceProcessSectionCodes.Liabilities,
	AdviceProcessSectionCodes.Others,
];

export const LsSoaSubSectionCodes = [
	AdviceProcessSectionCodes.LoanStructure,
	AdviceProcessSectionCodes.LoanSplit,
	AdviceProcessSectionCodes.OtherDetails,
	AdviceProcessSectionCodes.RoaSecurity,
];

// MOATV1
export const ReviewAppSubSectionCodes = [
	AdviceProcessSectionCodes.Purpose,
	AdviceProcessSectionCodes.FundingRequired,
	AdviceProcessSectionCodes.Security,
	AdviceProcessSectionCodes.LoanStructure,
	AdviceProcessSectionCodes.Notes,
	AdviceProcessSectionCodes.ApplicationDocument,
	AdviceProcessSectionCodes.ReviewApplication,
];

// MOATV2 - Separated because of different order from moatv1
export const MoatV2ReviewAppSubSectionCodes = [
	AdviceProcessSectionCodes.Purpose,
	AdviceProcessSectionCodes.FundingRequired,
	AdviceProcessSectionCodes.Security,
	AdviceProcessSectionCodes.LoanStructure,
	AdviceProcessSectionCodes.Notes,
	AdviceProcessSectionCodes.ReviewApplication,
	AdviceProcessSectionCodes.ApplicationDocument,
];

// LOAT Declaration > Fact Find
export enum LoatFactFindMergeTagCodes {
	People = 'LOATFFP',
	Company = 'LOATFFC',
	IncomeExpense = 'LOATFFIE',
	MedicalHistory = 'LOATFFMH',
}
export const LoatFactFindMergeTagsDataTypes = [
	LoatFactFindMergeTagCodes.People,
	LoatFactFindMergeTagCodes.Company,
	LoatFactFindMergeTagCodes.IncomeExpense,
	LoatFactFindMergeTagCodes.MedicalHistory,
];

export enum LoatPdfFooterCodes {
	Default = 'GDF',
	// Declaration = 'LOATCFFF'
}

export enum LoatPdfHeaderCodes {
	Default = 'GDH',
	// Declaration = 'LOATCFFH'
}

export enum MoatPdfFooterCodes {
	Default = 'GDF',
}

export enum MoatPdfHeaderCodes {
	Default = 'GDH',
}

export enum KoatPdfFooterCodes {
	Default = 'GDF',
}

export enum KoatPdfHeaderCodes {
	Default = 'GDH',
}

export enum LoatMergeTagCodes {
	ConCall = 'LOATCONCALL',
	FinalStructure = 'LOATFS',
}

export enum LoatMergeTagTableCodes {
	General = 'general',
	Business = 'business',
	ConCall = 'con-call',
	FinalStructure = 'final-structure',
}

export enum SOASubSectionCodes {
	SOS = 'SOASOS',
	CurrentInsurance = 'SOACI',
	ProposedInsurance = 'SOAPI',
	Documents = 'SOAD',
	Review = 'SOAR',
	Completed = 'Completed',
	ResettingMT = 'RESETSOAMT',
}

export enum ATPSubSectionCodes {
	ProposedInsurance = 'CAPI',
}

export enum FSSubSectionCodes {
	ProposedInsurance = 'FSANS',
}

export enum MortgageAdviceProcessPageNames {
	Introduction = 'Introduction',
	Disclosure = 'Disclosure Document',
	People = 'People',
	AssetsLiabilities = 'Assets & Liabilities',
	Income = 'Income',
	Expenses = 'Expenses',
	Declaration = 'PIA & Declaration',
	Application = 'Application',
	ScopeOfService = 'Scope of Service',
	ROA = 'Record of Advice',
	FactFind = 'Fact Find',
}

export enum MortgageAdviceProcessPageIds {
	Introduction = 'introduction',
	Disclosure = 'disclosure',
	ScopeOfService = 'scope-of-service',
	ClientSOP = 'clientSOP',
	People = 'people',
	AssetsLiabilities = 'assetsAndLiabilities',
	Income = 'income',
	Expenses = 'expenses',
	Declaration = 'declaration',
	Application = 'application',
	StructureSOA = 'structureSoa', // LATER
	ROA = 'recordOfAdvice',
}

export enum MoatStructurePages {
	loanStructure = 'Loan Structure',
	security = 'Security',
	otherDetails = 'Other Details',
	SOA = 'Record of Advice',
}

export enum MoatSidebarWarning {
	default = 'Please review required fields.',
	people = 'At least one person is required',
}

export enum MortgageAdviceProcessSectionCodes {
	Declaration = 'MD',
}

export enum KiwiSaverAdviceProcessSectionCodes {
	Declaration = 'KD',
}

export enum MoatApplicationStepHeader {
	Purpose = 'Purpose',
	FundingRequired = 'Funding Required',
	Security = 'Security',
	LoanStructure = 'Loan Structure',
	Notes = 'Notes',
	Documents = 'Documents',
	ReviewApplication = 'Review Application Summary',
}

export const MoatApplicationStepOrder = Object.values(MoatApplicationStepHeader);

export enum KiwiSaverAdviceProcessPageNames {
	Introduction = 'Introduction',
	Disclosure = 'Disclosure Document',
	ScopeOfService = 'Scope of Service',
	Declaration = 'Declaration',
	FactFind = 'Fact Find',
	YourKiwiSaver = 'Your KiwiSaver',
	WhyKiwiSaver = 'Why KiwiSaver?',
	HowKiwiSaverWorks = 'How KiwiSaver Works',
	PassiveVsActive = 'Passive vs. Active',
	RiskVsVolatility = 'Risk vs. Volatility',
	KiwiSaverProjection = 'KiwiSaver Projection',
	RiskProfile = 'Risk Profile',
	Providers = 'Providers',
	InvestmentStrategy = 'Investment Strategy',
	AdviceSummary = 'Advice Summary',
}

export enum KiwiSaverAdviceProcessPageIds {
	Introduction = 'introduction',
	Disclosure = 'disclosure',
	ScopeOfService = 'scope-of-service',
	FactFind = 'fact-find',
	YourKiwiSaver = 'your-kiwisaver',
	WhyKiwiSaver = 'why-kiwisaver',
	HowKiwiSaverWorks = 'how-kiwisaver-works',
	InvestmentStrategy = 'investment-strategy',
	PassiveVsActive = 'passive-vs-active',
	RiskVsVolatility = 'risk-vs-volatility',
	KiwiSaverProjection = 'kiwisaver-projection',
	RiskProfile = 'risk-profile-ig',
	RiskProfileInformationGathering = 'risk-profile-ig',
	RiskProfileSummary = 'risk-profile-summary',
	Providers = 'providers',
	Declaration = 'declaration',
	AdviceSummary = 'advice-summary',
}

export enum KiwiSaverAdviceRoutes {
	Declaration = 'declaration',
	YourKiwiSaver = 'fact-find/your-kiwisaver',
	RiskProfileInformationGathering = 'fact-find/risk-profile-ig',
	AdviceSummary = 'advice-summary/report-builder',
}

export const KsFactFindPageIds = [
	KiwiSaverAdviceProcessPageIds.FactFind,
	KiwiSaverAdviceProcessPageIds.YourKiwiSaver,
	KiwiSaverAdviceProcessPageIds.WhyKiwiSaver,
	KiwiSaverAdviceProcessPageIds.HowKiwiSaverWorks,
	KiwiSaverAdviceProcessPageIds.PassiveVsActive,
	KiwiSaverAdviceProcessPageIds.RiskVsVolatility,
	KiwiSaverAdviceProcessPageIds.KiwiSaverProjection,
	KiwiSaverAdviceProcessPageIds.RiskProfile,
	KiwiSaverAdviceProcessPageIds.Providers,
].map((x) => x?.toString());

export enum ProviderCommissionCodes {
	LR = 'PCLR',
	Mortgage = 'PCM',
	KiwiSaver = 'PCK',
	FG = 'PCFG',
}

export enum IncomeType {
	Rental = 'Rental',
	Boarder = 'Boarder',
}

export enum PropertyUse {
	OwnerOccupied = 'Owner Occupied',
	Investment = 'Investment',
	HolidayHome = 'Holiday Home',
}

export interface AdviceProcessAddModel {
	ClientsInvolved: string;
	CustomerID: number;
	ProcessCode: string;
	Adviser: number;
}

export interface AdviceProcessAddModelState {
	clientsInvolved: string | string[];
	customerID: number | string;
	processCode: string;
	adviser: number;
	customerServiceId?: string[];
	claimManager?: string;
	isOnline?: boolean;
}

export interface ComplaintProcess {
	AdviceService?: string;
	ComplaintType?: string;
	ComplaintOutcome?: string;
	ComplaintDetails?: string;
	ComplaintOutcomeDetails?: string;
}

export interface ComplaintProcessState {
	adviceService?: string;
	provider?: string;
	complaintType?: string;
	complaintOutcome?: string;
	complaintDetails?: string;
	complaintOutcomeDetails?: string;
}

export interface InvestmentProcess {
	RiskLevel?: string;
	SourceOfFunds?: string;
	SourceOfWealth?: string;
	RiskProfile?: string;
	InvestmentGoals?: string;
}

export interface InvestmentProcessState {
	riskLevel?: string;
	sourceOfFunds?: string;
	sourceOfWealth?: string;
	riskProfile?: string;
	investmentGoals?: string;
}

export interface ClaimsProcess {
	PolicyNumber?: string;
	PolicyNumberSuffix?: string;
	ClaimManager?: string;
	ReferenceNumber?: string;
	ClaimType?: string;
	TotalPaid?: string;
	PolicyPaymentMethod?: string;
	ClaimOutcome?: string;
	ClaimDetails?: string;
	OutcomeDetails?: string;
	CustomerServiceID?: string;
}

export interface ClaimsProcessState {
	policyNumber?: string;
	policyNumberSuffix?: string;
	claimManager?: string;
	referenceNumber?: string;
	claimType?: string;
	totalPaid?: string;
	policyPaymentMethod?: string;
	claimOutcome?: string;
	claimDetails?: string;
	outcomeDetails?: string;
	customerServiceID?: string;
}

export interface ClaimsTable {
	adviceProcessID: number;
	createdDate: string;
	totalPaid?: string;
	referenceNumber?: string;
	claimOutcome?: string;
	claimsDetail?: string;
	status: AdviceProcessStatusState;
	claimType?: string[];
}

export interface KLOATProcess {
	IsKLOATSynced?: boolean;
	SyncedKOATId?: number;
}

export interface KLOATProcessState {
	isKLOATSynced?: boolean;
	syncedKOATId?: number;
	kLOATAdviceProcessID?: number;
}

export interface ServiceAdviceProcess extends ComplaintProcess, InvestmentProcess, ClaimsProcess, KLOATProcess {
	ProcessCode: string;
	AdviceProcessID: number;
	CustomerID: number;
	// biome-ignore lint/suspicious/noExplicitAny: Can't trace type
	ClientsInvolved: any;
	Adviser: number;
	ReviewedBy: number;
	ReviewGoals: string;
	ImportantFactors: string;
	StartDate: string;
	EndProcessDate: string;
	ReopenDate: string;
	Status: AdviceProcessStatus;

	CreatedDateTime?: string;
	CreatedByStaffId?: number;
	ModifiedDateTime?: string;
	ModifiedByStaffId?: number;
	CreatedByStaffLevel?: number;

	Stages: AdviceProcessStage[];
	Documents: AdviceProcessDocument[];
	CompleteQuestions: DynamicField[];
	InCompleteQuestions: DynamicField[];

	AdviceType?: string;

	IsOnline?: boolean;
	IsStarted?: boolean;
}

export enum LOATKiwiSaverSync {
	No = 0,
	Yes = 1,
	NewRequest = 2,
	CreateAndSync = 3,
}

export interface ServiceAdviceProcessState
	extends ComplaintProcessState,
		InvestmentProcessState,
		ClaimsProcessState,
		KLOATProcessState {
	cPStatus: number;
	SharedToClient: string;
	sharedToClient: string;
	processCode: string;
	adviceProcessID: number;
	customerID: number;
	// biome-ignore lint/suspicious/noExplicitAny: Can't trace type
	clientsInvolved: any;
	adviser: number;
	reviewedBy: number;
	reviewGoals: string;
	importantFactors: string;
	startDate: string;
	endProcessDate: string;
	reopenDate: string;
	status: AdviceProcessStatusState;

	createdDateTime?: string;
	createdByStaffId?: number;
	modifiedDateTime?: string;
	modifiedByStaffId?: number;
	createdByStaffLevel?: number;

	stages: AdviceProcessStageState[];
	documents: AdviceProcessDocumentState[];
	completeQuestions: DynamicFieldState[];
	inCompleteQuestions: DynamicFieldState[];
	// biome-ignore lint/suspicious/noExplicitAny: Can't trace type
	adviceType?: any;

	isOnline?: boolean;
	isStarted?: boolean;
	// biome-ignore lint/suspicious/noExplicitAny: Can't trace type
	pageCompleted?: any[];
	// biome-ignore lint/suspicious/noExplicitAny: Can't trace type
	pageStarted?: any[];

	// Flag for showing Original Insurance Policy on the End Process > Missing Documents (LR Complete)
	showOriginalInsurancePolicy?: boolean;

	syncedKOATId?: number;
}

export interface AdviceProcess {
	ComplaintAdviceProcesses: ServiceAdviceProcess[];
	BlanketAdviceProcesses: ServiceAdviceProcess[];
	KiwiSaverAdviceProcesses: ServiceAdviceProcess[];
	LRAdviceProcesses: ServiceAdviceProcess[];
	MortgageAdviceProcesses: ServiceAdviceProcess[];
	FGAdviceProcesses: ServiceAdviceProcess[];
	InvestmentAdviceProcesses: ServiceAdviceProcess[];
	clientAlterationRequests: ServiceAdviceProcess[];
	FGClaimsAdviceProcesses: ServiceAdviceProcess[];
	LRClaimsAdviceProcesses: ServiceAdviceProcess[];
}

export interface AdviceProcessState {
	complaintAdviceProcesses: ServiceAdviceProcessState[];
	blanketAdviceProcesses: ServiceAdviceProcessState[];
	kiwiSaverAdviceProcesses: ServiceAdviceProcessState[];
	lRAdviceProcesses: ServiceAdviceProcessState[];
	mortgageAdviceProcesses: ServiceAdviceProcessState[];
	fGAdviceProcesses: ServiceAdviceProcessState[];
	investmentAdviceProcesses: ServiceAdviceProcessState[];
	clientAlterationRequests: ServiceAdviceProcessState[];
	fGClaimsAdviceProcesses: ServiceAdviceProcessState[];
	lRClaimsAdviceProcesses: ServiceAdviceProcessState[];
}

export const AdviceProcessTypesList = [
	{
		value: AdviceProcessCode.LRAdviceNew,
		display: AdviceProcessNames.LRAdviceNew,
		code: ServicesCodes.LR,
	},
	{
		value: AdviceProcessCode.LRAdviceReview,
		display: AdviceProcessNames.LRAdviceReview,
		code: ServicesCodes.LR,
	},
	{
		value: AdviceProcessCode.ClientAlterationRequest,
		display: AdviceProcessNames.LRCustomerAlterationRequest,
		code: ServicesCodes.ClientAlterationRequest,
	},
	{
		value: AdviceProcessCode.LRClaim,
		display: AdviceProcessNames.LRClaim,
		code: ServicesCodes.LR,
	},
	{
		value: AdviceProcessCode.MortgageAdvice,
		display: AdviceProcessNames.MortgageAdvice,
		code: ServicesCodes.Mortgage,
	},
	{
		value: AdviceProcessCode.MortgageRefix,
		display: AdviceProcessNames.MortgageRefix,
		code: ServicesCodes.Mortgage,
	},
	{
		value: AdviceProcessCode.KiwiSaverAdvice,
		display: AdviceProcessNames.KiwiSaverAdvice,
		code: ServicesCodes.KiwiSaver,
	},
	// { value: AdviceProcessCode.BlanketAdvice, display: 'Blanket Advice', code: 'FG' },
	{
		value: AdviceProcessCode.FGDomesticNewBusinessAdvice,
		display: AdviceProcessNames.FGDomesticNewBusinessAdvice,
		code: ServicesCodes.FG,
	},
	{
		value: AdviceProcessCode.FGCommercialNewBusinessAdvice,
		display: AdviceProcessNames.FGCommercialNewBusinessAdvice,
		code: ServicesCodes.FG,
	},
	{
		value: AdviceProcessCode.FGDomesticMTAAdvice,
		display: AdviceProcessNames.FGDomesticMTAAdvice,
		code: ServicesCodes.FG,
	},
	{
		value: AdviceProcessCode.FGCommercialMTAAdvice,
		display: AdviceProcessNames.FGCommercialMTAAdvice,
		code: ServicesCodes.FG,
	},
	{
		value: AdviceProcessCode.FGDomesticRenewalAdvice,
		display: AdviceProcessNames.FGDomesticRenewalAdvice,
		code: ServicesCodes.FG,
	},
	{
		value: AdviceProcessCode.FGCommercialRenewalAdvice,
		display: AdviceProcessNames.FGCommercialRenewalAdvice,
		code: ServicesCodes.FG,
	},
	{
		value: AdviceProcessCode.FGClaim,
		display: AdviceProcessNames.FGClaim,
		code: ServicesCodes.FG,
	},
	{
		value: AdviceProcessCode.Complaint,
		display: AdviceProcessNames.Complaint,
	},
	{
		value: AdviceProcessCode.Investment,
		display: AdviceProcessNames.Investment,
		code: ServicesCodes.Investment,
	},
];

export const OriginalInsurancePolicyDocument = {
	// Placeholder
	field: 'Original Insurance Policy',
	value: {
		documentID: 1,
		documentName: 'Original Insurance Policy',
		fileName: 'Original Insurance Policy',
	},
	type: 'DocumentID',
};

export interface KLOATSyncResponse {
	KLOATAdviceProcessID: string;
	SyncKLOAT: string;
}

export enum KLOATSyncStatus {
	No = 0,
	Yes = 1,
	NewRequest = 2,
}
