import { SettingsTypes } from 'src/app/modules/crt-settings/state/crt-settings.model';
import { util } from '../../../../util/util';
import { AdviceProcessSectionCodes } from '../../advice-process/advice-process.model';
import { ScopeOfServiceState } from './scope-of-service.model';

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
export class ScopeOfServiceMapper {
	public static mapToView(data: ScopeOfServiceState) {
		return {
			referenceId: data?.referenceId || 0,
			lifeInsurance: data?.lifeInsurance || false,
			criticalIllness: data?.criticalIllness || false,
			tPDInsurance: data?.tPDInsurance || false,
			disabilityInsurance: data?.disabilityInsurance || false,
			medicalInsurance: data?.medicalInsurance || false,
			businessRiskInsurance: data?.businessRiskInsurance || false,
			kiwiSaver: data?.kiwiSaver || false,
			homeCarAndContentsInsurance: data?.homeCarAndContentsInsurance || false,
			residentialMortgageOrLending: data?.residentialMortgageOrLending || false,
			other: data?.other || false,
			intro: this.parseData(data?.intro) || '',
			instructionOrLimitation: this.parseData(data?.instructionOrLimitation) || '',
			residentialMortgageOrLendingNote: this.parseData(data?.residentialMortgageOrLendingNote) || '',
			homeCarAndContentsInsuranceNote: this.parseData(data?.homeCarAndContentsInsuranceNote) || '',
			businessRiskInsuranceNote: this.parseData(data?.businessRiskInsuranceNote) || '',
			kiwiSaverNote: this.parseData(data?.kiwiSaverNote) || '',
			otherInformation: this.parseData(data?.otherInformation) || '',
			lifeInsuranceDetails: this.parseData(data?.lifeInsuranceDetails) || '',
			criticalIllnessDetails: this.parseData(data?.criticalIllnessDetails) || '',
			tPDInsuranceDetails: this.parseData(data?.tPDInsuranceDetails) || '',
			disabilityInsuranceDetails: this.parseData(data?.disabilityInsuranceDetails) || '',
			medicalInsuranceDetails: this.parseData(data?.medicalInsuranceDetails) || '',
			type: SettingsTypes.SOS,
			kiwiSaverAdvice: data?.kiwiSaverAdvice || false,
			kiwiSaverUntickReason: data?.kiwiSaverUntickReason || '',
		};
	}

	public static mapCheckboxes(data: ScopeOfServiceState, settings: ScopeOfServiceState) {
		if (!data?.cRTId) {
			// CRT Page > SOS checkbox status:
			// 0 - (Ticked on CRT Settings) Unticked on CRT Page
			// 1 - (Ticked on CRT Settings) Ticked on CRT Page
			// 2 - (Unticked on CRT Settings) Don't show on CRT Page
			return {
				// services
				lifeInsurance: settings?.lifeInsurance ? 1 : 2,
				criticalIllness: settings?.criticalIllness ? 1 : 2,
				tPDInsurance: settings?.tPDInsurance ? 1 : 2,
				disabilityInsurance: settings?.disabilityInsurance ? 1 : 2,
				medicalInsurance: settings?.medicalInsurance ? 1 : 2,

				// other advice
				kiwiSaver: settings?.kiwiSaver || settings?.kiwiSaverAdvice ? 1 : 2,
				homeCarAndContentsInsurance: settings?.homeCarAndContentsInsurance ? 1 : 2,
				// TAPNZ-2247 - overide defaults
				businessRiskInsurance: settings?.businessRiskInsurance ? 0 : 2,
				residentialMortgageOrLending: settings?.residentialMortgageOrLending ? 0 : 2,
				other: settings?.other ? 0 : 2,
				kiwiSaverAdvice: settings?.kiwiSaverAdvice ? 1 : 2,
			};
		}
		return data;
	}

	public static mapToViewCrt(data: ScopeOfServiceState, settings: ScopeOfServiceState) {
		return {
			cRTId: data?.cRTId,
			adviceProcessId: data?.adviceProcessId,
			sectionCode: AdviceProcessSectionCodes.SOS,
			...this.mapCheckboxes(data, settings),
			lifeInsuranceDetails:
				this.parseData(data?.lifeInsuranceDetails) ?? this.parseData(settings?.lifeInsuranceDetails),
			criticalIllnessDetails:
				this.parseData(data?.criticalIllnessDetails) ?? this.parseData(settings?.criticalIllnessDetails),
			tPDInsuranceDetails: this.parseData(data?.tPDInsuranceDetails) ?? this.parseData(settings?.tPDInsuranceDetails),
			disabilityInsuranceDetails:
				this.parseData(data?.disabilityInsuranceDetails) ?? this.parseData(settings?.disabilityInsuranceDetails),
			medicalInsuranceDetails:
				this.parseData(data?.medicalInsuranceDetails) ?? this.parseData(settings?.medicalInsuranceDetails),
			otherNotes: data?.cRTId ? this.parseData(data?.otherNotes) : '',
			instructionOrLimitation: data?.cRTId
				? this.parseData(data?.instructionOrLimitation)
				: this.parseData(settings?.instructionOrLimitation),
			intro: this.parseData(settings?.intro) ?? '',
			residentialMortgageOrLendingNote: this.parseData(settings?.residentialMortgageOrLendingNote) ?? '',
			homeCarAndContentsInsuranceNote: this.parseData(settings?.homeCarAndContentsInsuranceNote) ?? '',
			businessRiskInsuranceNote: this.parseData(settings?.businessRiskInsuranceNote) ?? '',
			kiwiSaverNote: this.parseData(settings?.kiwiSaverNote) ?? '',
			otherInformation: this.parseData(settings?.otherInformation) ?? '',
		};
	}

	public static parseData(data) {
		return data && util.tryCatchParse(data) ? JSON.parse(data) : data;
	}

	public static mapToUpsertSosSettings(data: ScopeOfServiceState) {
		return {
			...data,
			intro: data.intro ? JSON.stringify(data.intro) : '',
			instructionOrLimitation: data.instructionOrLimitation ? JSON.stringify(data.instructionOrLimitation) : '',
			residentialMortgageOrLendingNote: data.residentialMortgageOrLendingNote
				? JSON.stringify(data.residentialMortgageOrLendingNote)
				: '',
			homeCarAndContentsInsuranceNote: data.homeCarAndContentsInsuranceNote
				? JSON.stringify(data.homeCarAndContentsInsuranceNote)
				: '',
			businessRiskInsuranceNote: data.businessRiskInsuranceNote ? JSON.stringify(data.businessRiskInsuranceNote) : '',
			kiwiSaverNote: data.kiwiSaverNote ? JSON.stringify(data.kiwiSaverNote) : '',
			otherInformation: data.otherInformation ? JSON.stringify(data.otherInformation) : '',
			type: SettingsTypes.SOS,
		};
	}
}
