import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessConfig } from '@domain/business-config/business-config.model';
import { WidgetSettingType } from '@modules/admin/widgets/state/widget-settings.model';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { StructureSoaService } from '@modules/crm/crt-page/crt-mortgage/structure-soa/state/structure-soa.service';
import { CustomerAlterrationRequestService } from '@modules/customer-alteration-request-settings/states/customer-alterration-request.service';
import { MortgageEndProcessSettingsService } from '@modules/mortgage-settings/mortgage-end-process/state/mortgage-end-process.service';
import { SpecialFeaturesSettingsCode } from '@modules/special-features/state/special-features-settings.model';
import { AdviceProcessCancelModalComponent } from '@shared/modal/advice-process-cancel-modal/advice-process-cancel-modal.component';
import { SyncKloatModalComponent } from '@shared/modal/sync-kloat-modal/sync-kloat-modal.component';
import { PrimaryCustomerCompanyState } from '@shared/models/business-profile/business/business.model';
import { PrimaryClientState } from '@shared/models/client-profile/primary-client/primary-client.model';
import { EmailBodyTypes } from '@shared/models/emails/crt/email.model';
import { FgInsuranceState } from '@shared/models/services/fg-insurance/fg-provider-group.model';
import { LrInsuranceState } from '@shared/models/services/lr-insurance/lr-provider-group.model';
import sort from 'fast-sort';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import * as R from 'ramda';
import { clone } from 'ramda';
import { Observable, Observer, Subject, forkJoin, iif, of } from 'rxjs';
import { concatMap, filter, finalize, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CommandRoute, RouteService } from 'src/app/core/config/route.service';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import { UserQuery } from 'src/app/domain/user/user.query';
import { ClientReviewTemplateService } from 'src/app/modules/crm/client-review-template/states/client-review-template.service';
import { CrtNoteService } from 'src/app/modules/crm/client-review-template/states/note/crt-note.service';
import { DisabilityService } from 'src/app/modules/crm/client-review-template/states/risk-analysis/disability/disability.service';
import { LifeService } from 'src/app/modules/crm/client-review-template/states/risk-analysis/life/life.service';
import { RiskAnalysisMedicalService } from 'src/app/modules/crm/client-review-template/states/risk-analysis/medical/medical.service';
import { RiskAnalysisService } from 'src/app/modules/crm/client-review-template/states/risk-analysis/risk-analysis.service';
import { TpdService } from 'src/app/modules/crm/client-review-template/states/risk-analysis/tpd/tpd.service';
import { StatementOfAdviceService } from 'src/app/modules/crm/client-review-template/states/statement-of-advice/statement-of-advice.service';
import { EndProcessEmailSettingsService } from 'src/app/modules/crt-settings/end-process/state/end-process.service';
import { EndProcessEmailSettingsService as KsEndProcessEmailSettingsService } from 'src/app/modules/kiwisaver-settings/end-process/state/end-process.service';
import { CustomerService } from '../../core/customer/customer.service';
import { NoteService } from '../../core/note/note.service';
import MomentUtil from '../../util/moment.util';
import { objectUtil } from '../../util/util';
import { AdviceProcessAddModalComponent } from '../modal/advice-process-add-modal/advice-process-add-modal.component';
import { AdviceProcessEndModalComponent } from '../modal/advice-process-end-modal/advice-process-end-modal.component';
import { DeleteModalComponent } from '../modal/delete-modal/delete-modal.component';
import { ActivityViewModel } from '../models/_general/activity.viewmodel';
import { ViewDisplayValue } from '../models/_general/display-value.viewmodel';
import {
	AdviceProcessAddModelState,
	AdviceProcessCode,
	AdviceProcessNames,
	AdviceProcessOnlineRoutes,
	AdviceProcessPageCodes,
	AdviceProcessSectionCodes,
	AdviceProcessStageState,
	AdviceProcessStatusState,
	ClaimsDocumentField,
	GetServiceByAdviceProcessCode,
	KLOATSyncResponse,
	KLOATSyncStatus,
	KOATDocumentField,
	OriginalInsurancePolicyDocument,
	ServiceAdviceProcessState,
} from '../models/advice-process/advice-process.model';
import { FinalStructure } from '../models/client-review-template/final-structure/final-structure.model';
import { DocumentGroupState } from '../models/documents/document-group.model';
import { ServicesCodes } from '../models/services/services.model';
import { AdviceProcessFormComponent } from './advice-process-form/advice-process-form.component';

@Component({
	selector: 'app-advice-process',
	templateUrl: './advice-process.component.html',
	styleUrls: ['./advice-process.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdviceProcessComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
	private onDestroy$ = new Subject<void>();

	@Input() customerID: number;
	@Input() isLead: boolean;

	@Input() adviceProcesses: ServiceAdviceProcessState[];
	@Input() advisers: ViewDisplayValue[];
	@Input() reviewedBy: ViewDisplayValue[];
	@Input() allAdviserChoices: ViewDisplayValue[];
	@Input() clientsInvolved: ViewDisplayValue[];
	// biome-ignore lint/suspicious/noExplicitAny: Can't change; Too many affected
	@Input() clientsInvolvedRaw: any[];
	@Input() isLoading: boolean;
	@Input() allStaffChoices: ViewDisplayValue[];
	@Input() businessConfig: BusinessConfig;
	@Input() primaryClient: PrimaryClientState;
	@Input() primaryCompany: PrimaryCustomerCompanyState;

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() addNewAdviceProcess$: (data: any) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() updateAdviceProcess$: (data: any, isEndProcess?: boolean, isReopen?: boolean) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() deleteAdviceProcess$: (id, processCode) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() getQuestionnaires$: (code: string) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() getBusinessQuestionnaires$: (code: string, customerID?: number) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() getDocument$: (id: number) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() uploadDocument$: (file: any) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() downloadDocument$: (id: number) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() deleteDocument$: (id: number) => Observable<any>;
	@Input() kloatNewRequest$: (data: ServiceAdviceProcessState) => Observable<KLOATSyncResponse>;
	@Input() fetchAdviceProcessById$: (id: number, data: ServiceAdviceProcessState) => Observable<KLOATSyncResponse>;

	@Input() customerName: string;
	@Input() location: string;
	@Input() adviser: number;
	@Input() activityType$: Observable<ViewDisplayValue[]>;
	@Input() activityMeeting$: Observable<ViewDisplayValue[]>;
	@Input() adviserChoices$: Observable<ViewDisplayValue[]>;
	@Input() adviserCalendarChoices$: Observable<ViewDisplayValue[]>;
	@Input() qclr7Choices$: Observable<ViewDisplayValue[]>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() addActivityFn$: (req: ActivityViewModel) => Observable<any>;

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() getAdviceProcessNotes$: (customerID: number, adviceProcessID: number) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() addAdviceProcessNotes$: (req: any) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() deleteAdviceProcessNotes$: (noteID: number) => Observable<any>;

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() notes: any[];
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() deleteNote$: (id: number) => Observable<any>;

	@Input() document: DocumentGroupState;

	@Input() leadOriginChoice: ViewDisplayValue[];
	@Input() leadTypeChoice: ViewDisplayValue[];
	@Input() trustTypes: ViewDisplayValue[];
	@Input() lrProducts: ViewDisplayValue[];
	@Input() fgPolicyLines: ViewDisplayValue[];
	@Input() claimOutcome: ViewDisplayValue[];
	@Input() fGpaymentMethods: ViewDisplayValue[];
	@Input() hasQuickAdd: boolean;

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() getSCI$: (customerId: number) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() getSCT$: (customerId: number) => Observable<any>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() getTimeline$: (customerId: number) => Observable<any>;
	@Input() hasCRT: boolean;
	@Input() hasOfflineCRT: boolean;
	@Input() hasMOAT: boolean;
	@Input() hasKOAT: boolean;
	@Input() hasLOATV2: boolean;
	@Input() isCompany: boolean;
	@Input() claimsFeature: boolean;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() prePopulateFn$: (apId: number) => Observable<any>;
	@ViewChildren(AdviceProcessFormComponent)
	apComps!: QueryList<AdviceProcessFormComponent>;
	@Input() getAdviceProcessCRT$: (adviceProcessId: number, sectionCode: string) => Observable<FinalStructure>;
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() getSoa$: (apId: number) => Observable<any>;

	@Input() activeTabId: string;

	bsModalRef: BsModalRef;

	@Input() types = [
		{
			value: AdviceProcessCode.LRAdviceNew,
			display: AdviceProcessNames.LRAdviceNew,
			code: ServicesCodes.LR,
		},
		{
			value: AdviceProcessCode.LRAdviceReview,
			display: AdviceProcessNames.LRAdviceReview,
			code: ServicesCodes.LR,
		},
		{
			value: AdviceProcessCode.ClientAlterationRequest,
			display: AdviceProcessNames.LRCustomerAlterationRequest,
			code: ServicesCodes.LR,
		},
		{
			value: AdviceProcessCode.LRClaim,
			display: AdviceProcessNames.LRClaim,
			code: ServicesCodes.LR,
		},
		{
			value: AdviceProcessCode.MortgageAdvice,
			display: AdviceProcessNames.MortgageAdvice,
			code: ServicesCodes.Mortgage,
		},
		{
			value: AdviceProcessCode.MortgageRefix,
			display: AdviceProcessNames.MortgageRefix,
			code: ServicesCodes.Mortgage,
		},
		{
			value: AdviceProcessCode.KiwiSaverAdvice,
			display: AdviceProcessNames.KiwiSaverAdvice,
			code: ServicesCodes.KiwiSaver,
		},
		// { value: AdviceProcessCode.BlanketAdvice, display: 'Blanket Advice', code: 'FG' },
		{
			value: AdviceProcessCode.FGDomesticNewBusinessAdvice,
			display: AdviceProcessNames.FGDomesticNewBusinessAdvice,
			code: ServicesCodes.FG,
		},
		{
			value: AdviceProcessCode.FGCommercialNewBusinessAdvice,
			display: AdviceProcessNames.FGCommercialNewBusinessAdvice,
			code: ServicesCodes.FG,
		},
		{
			value: AdviceProcessCode.FGDomesticMTAAdvice,
			display: AdviceProcessNames.FGDomesticMTAAdvice,
			code: ServicesCodes.FG,
		},
		{
			value: AdviceProcessCode.FGCommercialMTAAdvice,
			display: AdviceProcessNames.FGCommercialMTAAdvice,
			code: ServicesCodes.FG,
		},
		{
			value: AdviceProcessCode.FGDomesticRenewalAdvice,
			display: AdviceProcessNames.FGDomesticRenewalAdvice,
			code: ServicesCodes.FG,
		},
		{
			value: AdviceProcessCode.FGCommercialRenewalAdvice,
			display: AdviceProcessNames.FGCommercialRenewalAdvice,
			code: ServicesCodes.FG,
		},
		{
			value: AdviceProcessCode.FGClaim,
			display: AdviceProcessNames.FGClaim,
			code: ServicesCodes.FG,
		},
		{
			value: AdviceProcessCode.Complaint,
			display: AdviceProcessNames.Complaint,
		},
		{
			value: AdviceProcessCode.Investment,
			display: AdviceProcessNames.Investment,
			code: ServicesCodes.Investment,
		},
	];

	@Input() apChoices$: ViewDisplayValue[];
	@Input() apcrtasChoices$: ViewDisplayValue[];
	@Input() apcrtatChoices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtctChoices$: ViewDisplayValue[];
	@Input() apcrtcoChoices$: ViewDisplayValue[];
	@Input() apcrtqi1Choices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtqi6Choices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtqclr7Choices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtrlChoices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtfccChoices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtynnaChoices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtqik6Choices$: Observable<ViewDisplayValue[]>;
	@Input() carqicar1$: Observable<ViewDisplayValue[]>;
	@Input() apcrtqik15Choices$: Observable<ViewDisplayValue[]>;

	@Input() lrProvider: ViewDisplayValue[]; // LRP
	@Input() mProvider: ViewDisplayValue[]; // MP
	@Input() kProvider: ViewDisplayValue[]; // KP
	@Input() fgProvider: ViewDisplayValue[]; // FGI

	@Input() cancelAdviceProcess$: (
		referenceId: number,
		cancellationReason: string,
		notes: string,
		ap?: ServiceAdviceProcessState,
		// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	) => Observable<any>;

	@Input() closeAdviceProcess$: (
		ap: ServiceAdviceProcessState,
		isEndProcess?: boolean,
		isReopen?: boolean,
		// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	) => Observable<any>;

	@Input() lrInsurance: LrInsuranceState;
	@Input() fgInsurance: FgInsuranceState;
	@Input() redirectToAP$: (data: {
		id?: string;
		serviceCode?: string;
		customerServiceId?: string;
		// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	}) => Observable<any>;

	@Output() goToPolicy: EventEmitter<{
		customerServiceId: number;
		serviceCode: string;
	}> = new EventEmitter<{ customerServiceId: number; serviceCode: string }>();

	isAddNew = false;
	isEndProcessLoading = false;
	isReopenLoading = false;
	isClaimClosing = false;
	canEndProcess: boolean;
	activeAdviceProcess: ServiceAdviceProcessState;
	editingList: ServiceAdviceProcessState[] = [];
	isEdit: boolean;
	EXCLUDED_IN_CHECKING_OF_DOCUMENT = ['Client Fact Find'];
	EXCLUDED_IN_CHECKING_OF_KS_DOCUMENT = ['KiwiSaver Information', 'Risk Profile Review'];
	origPolicyDocument = OriginalInsurancePolicyDocument;
	displayCancelProcess = false;
	processCodes = AdviceProcessCode;
	adviceProcessStatus = AdviceProcessStatusState;

	get isClaim(): boolean {
		if (!this.activeAdviceProcess) {
			return false;
		}
		switch (this.activeAdviceProcess.processCode) {
			case AdviceProcessCode.FGClaim:
			case AdviceProcessCode.LRClaim:
				return true;
		}
		return false;
	}

	@ViewChild('sidebarNav', { static: false }) sidebarNav: TabsetComponent;
	@ViewChild('apForm', { static: false }) apForm: AdviceProcessFormComponent;

	businessServices$ = this.businessConfigQuery.businessServices$;

	disableEndProcess = false;
	tooltipLabel = '';

	constructor(
		private modalService: BsModalService,
		private cd: ChangeDetectorRef,
		private customerService: CustomerService,
		private noteService: NoteService,
		private route: ActivatedRoute,
		private routeService: RouteService,
		private router: Router,
		private ngZone: NgZone,
		private endProcessService: EndProcessEmailSettingsService,
		private crtNoteService: CrtNoteService,
		private crtService: ClientReviewTemplateService,
		private lifeService: LifeService,
		private tpdService: TpdService,
		private disabilityService: DisabilityService,
		private medicalService: RiskAnalysisMedicalService,
		private soaService: StatementOfAdviceService,
		private riskAnalysisService: RiskAnalysisService,
		private businessConfigQuery: BusinessConfigQuery,
		private userQuery: UserQuery,
		private kiwiSaverEndprocess: KsEndProcessEmailSettingsService,
		private moatRoaService: StructureSoaService,
		private mortgageEmailSettingsService: MortgageEndProcessSettingsService,
		private carService: CustomerAlterrationRequestService,
		private clientProfileService: ClientProfileService,
	) {}

	ngOnInit(): void {
		this.clearDataFromState();
	}

	ngAfterViewInit() {
		if (this.route.snapshot.paramMap.has('adviceProcessId')) {
			setTimeout(() => {
				this.selectTab(+this.route.snapshot.paramMap.get('adviceProcessId'));
			}, 2);
		}
	}

	getAllowedServices() {
		const businessServices = this.businessConfigQuery.getValue().config.Services;
		const userServices =
			JSON.parse(this.userQuery.getValue().Services)?.filter((us) => businessServices.some((bs) => bs === us)) || [];
		const allowedServices = this.userQuery.isTapLevel()
			? businessServices
			: businessServices.filter((x) => userServices.includes(x));
		return allowedServices;
	}

	filterAdviceProcess(adviceProcesses: ServiceAdviceProcessState[]) {
		const list = adviceProcesses || [];
		const allowedServices = this.getAllowedServices() || [];
		const filteredTypes = this.types
			.filter((type) => {
				if (!type.code) {
					return true;
				}
				if (type.value === AdviceProcessCode.ClientAlterationRequest) {
					return allowedServices?.includes(ServicesCodes.ClientAlterationRequest);
				}
				if (
					!this.claimsFeature &&
					(type.value === AdviceProcessCode.FGClaim || type.value === AdviceProcessCode.LRClaim)
				) {
					return false;
				}
				return allowedServices?.includes(type.code);
			})
			.map((type) => type.value);

		const hideAP = (data) => {
			return filteredTypes.includes(data?.processCode);
		};

		return R.filter(hideAP, list);
	}

	filterOnlineAdviceProcess(adviceProcesses: ServiceAdviceProcessState[]) {
		const list = adviceProcesses || [];
		const hideOnlineAP = (data: ServiceAdviceProcessState) => {
			if (!this.hasCRT) {
				// If LOAT is Disabled on Business Settings, Show offline Advice Processes only.
				if (
					data?.processCode === AdviceProcessCode.LRAdviceNew ||
					data?.processCode === AdviceProcessCode.LRAdviceReview
				) {
					return !data?.isOnline;
				}
			}
			if (!this.hasMOAT) {
				// If MOAT is Disabled on Business Settings, Show offline Advice Processes only.
				if (data.processCode === AdviceProcessCode.MortgageAdvice) {
					return !data?.isOnline;
				}
			}
			if (!this.hasKOAT) {
				// If KOAT is Disabled on Business Settings, Show offline Advice Processes only.
				if (data.processCode === AdviceProcessCode.KiwiSaverAdvice) {
					return !data?.isOnline;
				}
			}
			return true;
		};
		return R.filter(hideOnlineAP, list);
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	getDocumentList(documents: any[]) {
		return documents?.map((doc) => {
			if (
				(doc.field === KOATDocumentField.KiwiSaverInformation &&
					this.activeAdviceProcess?.pageCompleted?.includes(AdviceProcessPageCodes.YourKiwiSaver)) ||
				(doc.field === KOATDocumentField.RiskProfileReview &&
					this.activeAdviceProcess?.pageCompleted?.includes(AdviceProcessPageCodes.RiskProfile))
			) {
				return {
					...doc,
					value: {
						documentID: 1,
						documentName: KOATDocumentField.RiskProfileReview,
						fileName: KOATDocumentField.RiskProfileReview,
					},
				};
			}

			return doc;
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!!changes.adviceProcesses && changes.adviceProcesses.currentValue !== undefined) {
			this.adviceProcesses = this.filterAdviceProcess(changes.adviceProcesses.currentValue || []);

			this.adviceProcesses = this.filterOnlineAdviceProcess(this.adviceProcesses || []);

			this.adviceProcesses = sort(this.adviceProcesses).desc((ap) => ap.modifiedDateTime);

			const activeServices = this.adviceProcesses?.filter((y) => y.status !== 6);
			const cancelledServices = this.adviceProcesses?.filter((y) => y.status === 6);

			this.adviceProcesses = [...activeServices, ...cancelledServices];

			const currentAdviceProcess = this.adviceProcesses?.find(
				(x) => this.activeAdviceProcess?.adviceProcessID === x.adviceProcessID,
			);

			this.activeAdviceProcess =
				!!this.activeAdviceProcess && !!currentAdviceProcess
					? currentAdviceProcess
					: !!this.adviceProcesses && this.adviceProcesses.length > 0
						? this.adviceProcesses[0]
						: null;

			setTimeout(() => {
				if (this.activeAdviceProcess) {
					this.selectTab(this.activeAdviceProcess.adviceProcessID);
				}
			}, 0);

			this.canEndProcess =
				(!!this.activeAdviceProcess && this.activeAdviceProcess.processCode !== AdviceProcessCode.Complaint) ||
				(!!this.activeAdviceProcess &&
					this.activeAdviceProcess.processCode === AdviceProcessCode.Complaint &&
					!this.activeAdviceProcess.stages?.some((x) => !x.value));

			this.isEdit = this.isEditing();
		}
		this.checkEndProcess();
		this.cd.detectChanges();
	}

	addEditingList(data: ServiceAdviceProcessState) {
		this.editingList = [...this.editingList, data];
		this.isEdit = this.isEditing();
		this.cd.detectChanges();
	}

	removeFromEditingList(data: ServiceAdviceProcessState) {
		this.editingList = this.editingList?.filter((x) => x.adviceProcessID !== data.adviceProcessID);
		this.isEdit = this.isEditing();
		this.cd.detectChanges();
	}

	isEditing() {
		return (
			!!this.activeAdviceProcess &&
			this.editingList.length > 0 &&
			!!this.editingList?.find((x) => x.adviceProcessID === this.activeAdviceProcess.adviceProcessID)
		);
	}

	trackbyFn(_i, adviceProcess) {
		return adviceProcess.advicerProcessID;
	}

	adviceProccessName(processCode) {
		const APType = this.types?.find((x) => x.value === processCode);
		if (APType.value === AdviceProcessCode.ClientAlterationRequest) {
			return 'L&R - Alteration';
		}
		return APType?.display;
	}

	adviceProcessStage(stages: AdviceProcessStageState[]) {
		return R.findLastIndex((x) => !!x.value, stages) >= 0
			? stages[R.findLastIndex((x) => !!x.value, stages)].field
			: '';
	}

	addAdviceProcessFn$ = (data) => {
		if (data?.processCode === AdviceProcessCode.KiwiSaverAdvice && data?.isOnline) {
			return this.syncKLOAT(data);
		}
		return this.addNewAp(data);
	};

	addNewAp = (data) => {
		return this.addNewAdviceProcess$(data).pipe(
			take(1),
			finalize(() => this.goToFirstAdviceProcess()),
		);
	};

	goToFirstAdviceProcess = () => {
		setTimeout(() => {
			this.goToPolicyEvent({
				customerServiceId: this.adviceProcesses[0].adviceProcessID,
				serviceCode: ServicesCodes.AdviceProcess,
			});
			// this.selectTab(this.adviceProcesses[0].adviceProcessID);
			this.clientProfileService.invokeApNoteFetchEvent?.next(this.adviceProcesses[0]?.adviceProcessID?.toString());
		}, 2);
	};

	syncKLOAT = (data) => {
		return this.kloatNewRequest$({
			...data,
			isKLOATSynced: KLOATSyncStatus.NewRequest,
			kLOATAdviceProcessID: 0, // 0 for New Request
		}).pipe(
			tap((res: KLOATSyncResponse) => {
				if (typeof res === 'number') {
					this.proceedKOATcreation(res, data);
					return;
				}
				if (res?.SyncKLOAT && res?.KLOATAdviceProcessID) {
					this.syncKLOATModal(data, res?.SyncKLOAT, +res?.KLOATAdviceProcessID);
					return;
				}
			}),
		);
	};

	syncKLOATModal = (data: ServiceAdviceProcessState, msg: string, kloatId: number) => {
		const confirm$ = new Observable((obs: Observer<void>) => {
			obs.next();
			obs.complete();
		}).pipe(
			mergeMap(() =>
				this.addNewAp({
					...data,
					isKLOATSynced: KLOATSyncStatus.Yes,
					kLOATAdviceProcessID: kloatId,
				}),
			),
		);
		const cancel$ = new Observable((obs: Observer<void>) => {
			obs.next();
			obs.complete();
		}).pipe(
			mergeMap(() =>
				this.addNewAp({
					...data,
					isKLOATSynced: KLOATSyncStatus.No,
					kLOATAdviceProcessID: kloatId,
				}),
			),
		);
		this.modalService.show(SyncKloatModalComponent, {
			class: 'modal-dialog-centered modal-dialog-v3 sync-kloat-modal',
			initialState: {
				confirm$,
				cancel$,
				message: msg?.replace(/\n|\t/g, '<br>') || '',
				header: 'KiwiSaver information for these<br>users has already been collected.',
			},
			ignoreBackdropClick: true,
		});
	};

	proceedKOATcreation(id: number, data: ServiceAdviceProcessState) {
		this.fetchAdviceProcessById$(id, data)
			.pipe(
				tap(() => this.goToFirstAdviceProcess()),
				take(1),
			)
			.subscribe();
	}

	addSCI = (data) =>
		this.customerService
			.AddSecondaryClient(data)
			.pipe(mergeMap((x) => this.getSCI$(this.customerID).pipe(map(() => x))));
	addSCT = (data) =>
		this.customerService
			.AddSecondaryTrustByPrimaryClient(data)
			.pipe(mergeMap((x) => this.getSCT$(this.customerID).pipe(map(() => x))));
	addNote = (data) =>
		this.noteService.SaveNote(data).pipe(mergeMap((x) => this.getTimeline$(this.customerID).pipe(map(() => x))));

	addNewAdviceProcess(selectedInfo?: AdviceProcessAddModelState) {
		const allowedServices = this.getAllowedServices() || [];
		const customerAlterationRequestEnabled =
			this.businessConfigQuery.getValue()?.config?.SpecialFeature?.includes(SpecialFeaturesSettingsCode.CAR) &&
			allowedServices.includes(ServicesCodes.ClientAlterationRequest);
		const filteredTypes = this.types.filter((type) => {
			// hide Client Alteration Request in business profile advice process creation
			if (type.value === AdviceProcessCode.ClientAlterationRequest) {
				if (this.isCompany || !customerAlterationRequestEnabled) {
					return false;
				}
				return true;
			}
			//Hide Claims if not enabled on business
			if (type.value === AdviceProcessCode.LRClaim || type.value === AdviceProcessCode.FGClaim) {
				if (!this.claimsFeature) {
					return false;
				}
				return true;
			}
			return !type.code ? true : allowedServices.includes(type.code);
		});

		const initialState = {
			header: 'New Advice Process',
			advisers: this.advisers,
			adviser: this.adviser,
			clientsInvolved: this.clientsInvolved,
			clientsInvolvedRaw: this.clientsInvolvedRaw,
			processCodes: filteredTypes,
			customerID: this.customerID,
			saveFn: this.addAdviceProcessFn$,
			leadOriginChoice: this.leadOriginChoice,
			leadTypeChoice: this.leadTypeChoice,
			trustTypes: this.trustTypes,
			lrInsurance: this.lrInsurance,
			fgInsurance: this.fgInsurance,
			hasQuickAdd: this.hasQuickAdd,
			addSCI: this.addSCI,
			addSCT: this.addSCT,
			addNote: this.addNote,
			hasCRT: this.hasCRT,
			hasMOAT: this.hasMOAT,
			hasKOAT: this.hasKOAT,
			isCompany: this.isCompany,
			businessConfig: this.businessConfig,
			primaryClient: this.primaryClient,
			primaryCompany: this.primaryCompany,
			claimManagers: this.reviewedBy,
			selectedInfo,
		};
		this.bsModalRef = this.modalService.show(AdviceProcessAddModalComponent, {
			class: 'modal-dialog-centered',
			ignoreBackdropClick: true,
			initialState,
			keyboard: false,
		});
	}

	updateAdviceProcessFn$ = (data, isEndProcess?: boolean, isReopen?: boolean) => {
		return of(data as ServiceAdviceProcessState).pipe(
			map((x) => {
				x.clientsInvolved = JSON.stringify(
					typeof x.clientsInvolved === 'string' ? JSON.parse(x.clientsInvolved) : x.clientsInvolved,
				);
				return x;
			}),
			switchMap((x) => this.updateAdviceProcess$(x, isEndProcess, isReopen)),
			finalize(() => setTimeout(() => this.selectTab(data.adviceProcessID), 2)),
		);
	};

	deleteAdviceProcessFn$ = (id, processCode) => {
		return this.deleteAdviceProcess$(id, processCode).pipe(
			finalize(() => setTimeout(() => this.selectTab(this.adviceProcesses[0]?.adviceProcessID), 2)),
		);
	};

	getServiceCode(code: string) {
		return GetServiceByAdviceProcessCode(code);
	}

	update = (answers) => {
		let data = {
			...this.activeAdviceProcess,
			documents: this.activeAdviceProcess.documents?.map((x) => ({
				...x,
				value: x.value ? x.value.documentID : null,
			})),
		};
		const isComplete = !data.stages?.some((x) => !x.value);
		data = {
			...data,
			[isComplete ? 'CompleteQuestions' : 'InCompleteQuestions']: answers,
			endProcessDate: MomentUtil.formatDateToServerDate(MomentUtil.createMomentNz()),
			status: isComplete ? 3 : 4,
		};
		return this.updateAdviceProcessFn$(data, true).pipe(takeUntil(this.onDestroy$));
	};

	getEndProcessSettings(adviceProcessID: number, settingsCode: string) {
		if (settingsCode) {
			return this.kiwiSaverEndprocess.getEndProcessSettings(adviceProcessID, settingsCode).pipe(
				// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
				map((x: any) => {
					return x?.map((people) => {
						return {
							customerId: people.CustomerId,
							customerName: this.clientsInvolved?.find((client) => +client.value === people.CustomerId)?.display || '',
							currentProvider: people.CurrentProvider,
							fundType: people.FundType,
							currentFundType: JSON.parse(people.FundType)?.join(', '),
						};
					});
				}),
			);
		}

		return of(null);
	}

	getEndProcessPrefills(adviceProcessID: number, settingsCode: string, staffSettings?: boolean) {
		if (settingsCode && staffSettings) {
			return this.kiwiSaverEndprocess.getStaffSettings(settingsCode);
		}

		if (settingsCode) {
			return this.kiwiSaverEndprocess.getEndProcessSettings(adviceProcessID, settingsCode);
		}

		return of(null);
	}

	getAdviceProcessPrefills(adviceProcessID: number, settingsCode: string) {
		if (settingsCode === ServicesCodes.Mortgage) {
			return this.moatRoaService.getRoaApprovedApplication(adviceProcessID).pipe(
				map(([_application, purpose]) => ({
					previousLender: purpose?.bank || '',
					currentLender: purpose?.currentPrimaryBank || '',
					applicationPurpose: purpose?.types?.join(', ') || '',
				})),
			);
		}

		if (settingsCode === AdviceProcessSectionCodes.KiwiSaverEndProcessPrefills) {
			return forkJoin([
				this.getEndProcessSettings(adviceProcessID, AdviceProcessSectionCodes.KiwiSaverEndProcessPrefills),
				this.getEndProcessPrefills(adviceProcessID, AdviceProcessSectionCodes.KiwiSaverAdviseSummaryPeople),
				this.getEndProcessPrefills(adviceProcessID, AdviceProcessSectionCodes.KiwiSaverAdviseSummaryDependent),
			]);
		}

		if (settingsCode === WidgetSettingType.NRDRO) {
			return this.getEndProcessPrefills(adviceProcessID, WidgetSettingType.NRDRO, true);
		}

		if (settingsCode === ServicesCodes.ClientAlterationRequest) {
			return this.getEndProcessPrefills(adviceProcessID, WidgetSettingType.NRDRO, true);
		}

		return of(null);
	}

	endProcess() {
		if (this.disableEndProcess) {
			return;
		}

		const current = this.activeAdviceProcess;

		const stages = current?.stages;
		const question = stages?.some((x) => !x.value) ? 'I' : 'C';
		const service = this.getServiceCode(current?.processCode);
		const isServiceCar = service === ServicesCodes.ClientAlterationRequest;

		const questionType = service === ServicesCodes.FG ? `Q${question}B` : `Q${question}${service}`;
		this.isEndProcessLoading = true;
		const clientsArray = JSON.parse(current?.clientsInvolved) as string[];
		const selectedClients = this.clientsInvolved?.filter((client) => clientsArray?.includes(client.value));

		let settingsType = of({});
		let settingsCode = '';

		if (service === ServicesCodes.KiwiSaver && !!current.isOnline) {
			settingsType = this.kiwiSaverEndprocess.getSettings(0, AdviceProcessSectionCodes.KiwiSaverEndProcess);

			settingsCode = AdviceProcessSectionCodes.KiwiSaverEndProcessPrefills;
		}
		if (service === ServicesCodes.LR && !!current.isOnline) {
			settingsType = this.endProcessService.getSettings(0, AdviceProcessSectionCodes.EndProcess);

			settingsCode = WidgetSettingType.NRDRO;
		}
		if (current?.processCode === AdviceProcessCode.MortgageAdvice && current?.isOnline) {
			settingsCode = ServicesCodes.Mortgage;
			settingsType = this.mortgageEmailSettingsService.getSettings(0, AdviceProcessSectionCodes.MortgageEndProcess);
		}
		if (service === ServicesCodes.ClientAlterationRequest) {
			settingsCode = ServicesCodes.ClientAlterationRequest;
			settingsType = this.carService.getEmailSettings(EmailBodyTypes.CAREndProcess);
		}

		settingsType
			.pipe(
				take(1),
				switchMap(() =>
					iif(
						() =>
							(service === ServicesCodes.LR ||
								service === ServicesCodes.ClientAlterationRequest ||
								service === ServicesCodes.Investment ||
								service === ServicesCodes.KiwiSaver ||
								current?.processCode === AdviceProcessCode.MortgageAdvice) &&
							this.isCompany, // TAPNZ-6987
						this.getBusinessQuestionnaires$(current?.processCode, this.customerID),
						this.getQuestionnaires$(current?.processCode),
					),
				),
				filter((x) => !!x),
				map((x) => x?.map(objectUtil.mapPascalCaseToCamelCase)),
				map((x) => x?.filter((y) => y.type === questionType)),
				// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
				map((x: any[]) => sort(x).asc((q) => q.settingOrder) as any[]),
				map((x) =>
					x?.map((y) => ({
						adviceProcessQuestionnaireID: y.dviceProcessSettingsId,
						question: y.value1,
						questionKey: y.value,
						questionType: y.type,
						dataType: y.dataType,
					})),
				),
				concatMap((questionnaires) => {
					return this.getAdviceProcessPrefills(current.adviceProcessID, settingsCode).pipe(
						map((data) => {
							return {
								questionnaires,
								prefills: data,
							};
						}),
					);
				}),
				tap(
					({ questionnaires, prefills }) => {
						const areStagesCompleted = !stages?.some((x) => !x.value);
						let documents = this.activeAdviceProcess?.documents || [];
						if (this.activeAdviceProcess?.isOnline) {
							documents = this.activeAdviceProcess.documents?.filter((document) => {
								return !this.EXCLUDED_IN_CHECKING_OF_DOCUMENT?.includes(document.field);
							});
							if (service === ServicesCodes.LR && areStagesCompleted) {
								// TAPNZ-6658: Show Original Insurance Policy document on End Process > Missing Documents
								// LR Complete
								const origPolicyDoc = this.origPolicyDocument;
								const docValue = !this.activeAdviceProcess?.showOriginalInsurancePolicy
									? this.origPolicyDocument?.value
									: null;

								documents = [...documents, { ...origPolicyDoc, value: docValue }];
							}
							if (service === ServicesCodes.KiwiSaver) {
								documents = this.getDocumentList(documents);
							}
						}

						let modalHeader = `${this.types?.find((x) => x.value === this.activeAdviceProcess.processCode).display}`;

						if (isServiceCar) {
							modalHeader = SpecialFeaturesSettingsCode.CAR;
						}

						// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
						const tryUpdateLnRNextReviewSchedule = (data: any) => {
							const scheduleLnRReviewQuestion = data.find((q) => q.field === 'QICAR3');
							if (scheduleLnRReviewQuestion?.value === 'Yes') {
								const nextReviewScheduleDate = data.find((q) => q.field === 'QICAR3 Date')?.value;
								return this.clientProfileService.updateNextReview(nextReviewScheduleDate, ServicesCodes.LR);
							}
							return of(false);
						};

						this.isEndProcessLoading = false;
						this.cd.detectChanges();
						const initialSate = {
							header: modalHeader,
							isComplete: areStagesCompleted,
							questions: questionnaires,
							answers: stages?.some((x) => !x.value)
								? this.activeAdviceProcess.inCompleteQuestions
								: this.activeAdviceProcess.completeQuestions,
							questionType,
							service,
							saveFn: (data) =>
								this.update(data).pipe(
									mergeMap((result) => {
										if (result.ProcessCode !== 'CARLR') {
											return of(result);
										}
										return tryUpdateLnRNextReviewSchedule(data);
									}),
								),
							// Activity
							isLead: this.isLead,
							customerId: this.customerID,
							customerName: this.customerName,
							location: this.location,
							adviser: this.adviser,
							activityType$: this.activityType$,
							adviserChoices$: this.adviserChoices$,
							adviserCalendarChoices$: this.adviserCalendarChoices$,
							addActivityFn$: this.addActivityFn$,
							isUploadedAllDocuments: !documents?.some((x) => !x.value),
							missingDocuments: documents?.filter((x) => !x.value),

							apcrtqi1Choices$: this.apcrtqi1Choices$,
							apcrtqi6Choices$: this.apcrtqi6Choices$,
							apcrtqclr7Choices$: this.apcrtqclr7Choices$,
							apcrtfccChoices$: this.apcrtfccChoices$,
							apcrtynnaChoices$: this.apcrtynnaChoices$,
							apcrtqik6Choices$: this.apcrtqik6Choices$,
							carqicar1$: this.carqicar1$,
							apcrtqik15Choices$: this.apcrtqik15Choices$,

							adviceProcess: current,
							clientsInvolved: selectedClients,
							adviceProcessPrefills: prefills,
							primaryClient: this.primaryClient,
							primaryCompany: this.primaryCompany,
							isCompany: this.isCompany,
						};

						const modalClass = `modal-dialog-centered modal-advice-process-summary ${
							isServiceCar ? 'car-modal-content' : ''
						}`;

						this.modalService.show(AdviceProcessEndModalComponent, {
							initialState: initialSate,
							class: modalClass,
							ignoreBackdropClick: true,
							keyboard: false,
						});
					},
					() => {},
					() => {
						this.isEndProcessLoading = false;
						this.cd.detectChanges();
					},
				),
			)
			.subscribe();
	}

	reopen() {
		this.isReopenLoading = true;
		this.cd.detectChanges();
		let data = {
			...this.activeAdviceProcess,
			documents: this.activeAdviceProcess.documents?.map((x) => ({
				...x,
				value:
					!!x.value && x.field !== ClaimsDocumentField.Others
						? x.value.documentID
						: x.field === ClaimsDocumentField.Others && x.value
							? x.value
							: null,
			})),
		};
		data = {
			...data,
			reopenDate: MomentUtil.formatDateToServerDate(MomentUtil.createMomentNz()),
			status: 5,
		};
		return this.updateAdviceProcessFn$(data, false, true)
			.pipe(
				tap(() => {
					const apComp = this.apComps?.find((x) => !!x?.adviceProcess?.adviceProcessID);
					if (apComp) {
						apComp.getNotes$(objectUtil.mapCamelCaseToPascalCase(data)).pipe(take(1)).subscribe();
					}
					// this.addForm.getNotes$(data);
					this.isReopenLoading = false;
				}),
			)
			.subscribe();
	}

	confirmReopen() {
		const confirm = new Observable((obs: Observer<unknown>) => {
			this.reopen();
			obs.complete();
		});

		const initState = {
			header: 'Reopen Advice Process',
			message: 'Are you sure you want to reopen this advice process?',
			delete$: confirm,
			canDelete: true,
			confirmButton: 'Reopen',
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	selectTab(id: number) {
		const index = R?.findIndex((x) => x?.adviceProcessID === +id, this.adviceProcesses);
		this.displayCancelProcess = !this.adviceProcesses[index]?.stages?.some((x) => !!x.value);
		if (index === -1) {
			return;
		}

		if (this.sidebarNav?.tabs?.[index]) {
			this.sidebarNav.tabs[index].active = true;
		}

		this.activeAdviceProcess = this.adviceProcesses?.find((x) => x.adviceProcessID === +id);
		this.canEndProcess =
			(!!this.activeAdviceProcess && this.activeAdviceProcess.processCode !== AdviceProcessCode.Complaint) ||
			(!!this.activeAdviceProcess &&
				this.activeAdviceProcess.processCode === AdviceProcessCode.Complaint &&
				!this.activeAdviceProcess.stages?.some((x) => !x.value));
		this.isEdit = this.isEditing();
		this.checkEndProcess();
		this.cd.detectChanges();
	}

	checkEndProcess() {
		if (this.activeAdviceProcess?.processCode === AdviceProcessCode.KiwiSaverAdvice) {
			const stages = this.activeAdviceProcess?.stages;
			const stagesCompleted = !stages?.some((x) => !x.value);
			const documents = this.getDocumentList(this.activeAdviceProcess?.documents);
			const missingDocuments = documents?.filter((x) => !x.value);
			const documentsCompleted = !documents?.some((x) => !x.value);

			if (this.activeAdviceProcess?.isOnline) {
				// TAPNZ-12705 ONLINE
				if (stagesCompleted && !documentsCompleted) {
					this.disableEndProcess = true;
					this.tooltipLabel = `
						Please save or upload the below outstanding documents to close this Advice Process: <br>
						${missingDocuments?.map((doc) => `&nbsp;&nbsp; - ${doc.field} <br>`)?.join('')}
					`;
					return;
				}
				if (
					!stagesCompleted &&
					stages?.find((x) => x?.field === 'Application Submitted')?.value &&
					!stages?.find((x) => x?.field === 'Advice Summary Presented')?.value
				) {
					this.disableEndProcess = true;
					this.tooltipLabel = 'Please save or upload the Advice Summary to close this Advice Process';
					return;
				}
			} else {
				// TAPNZ-13443 OFFLINE
				if (
					!this.offlineKSCanEnd(stages, documents)
					// !stages?.find((x) => x?.field === 'Transfer Complete')?.value &&
					// !documents?.find((x) => x?.field === 'Advice Summary')?.value
				) {
					this.disableEndProcess = true;
					this.tooltipLabel = 'Please save or upload the Advice Summary to close this Advice Process';
					return;
				}
			}

			this.disableEndProcess = false;
			this.tooltipLabel = '';
		} else {
			this.disableEndProcess = false;
		}
	}

	/*
	 * Rules for offline KiwiSaver end process to enable
	 * - No Stages or Stage 1 filled out: Can close the Advice Process with no documents completed
	 *	- Stage 2 - 3 filled out: All documents are required to be saved to close the Advice Process
	 * Please see https://bbtdigital.atlassian.net/browse/TAP1-167?focusedCommentId=115070 for more info
	 */
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	private offlineKSCanEnd(stages: AdviceProcessStageState[], docs: any[]): boolean {
		const filledStages = stages
			.map((stage) => {
				return stage.value ? stage.field : '';
			})
			.filter((s) => Boolean(s));

		// No Stages or Stage 1 filled out: Can close the Advice Process with no documents completed
		const firstOrNoStagesFilled =
			!filledStages.length || (filledStages.length === 1 && filledStages[0] === 'Class Advice');
		if (firstOrNoStagesFilled) {
			return true;
		}

		// Stage 2 - 3 filled out: All documents are required to be saved to close the Advice Process
		const multipleStagesFilled = filledStages.length > 1;
		const notSavedDocument = docs?.findIndex((x) => !x?.value) !== -1;
		if (multipleStagesFilled && notSavedDocument) {
			return false;
		}
		return true;
	}

	prePopulate(adviceProcess: ServiceAdviceProcessState) {
		const apId: number = adviceProcess.adviceProcessID;
		if (!apId) {
			return;
		}
		this.prePopulateFn$(apId)
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				() => {
					this.ngZone.run(() => {
						let route: CommandRoute = null;
						switch (adviceProcess.processCode) {
							case AdviceProcessCode.MortgageAdvice:
								route = this.routeService.crtPage(
									!this.hasQuickAdd,
									this.customerID,
									apId,
									AdviceProcessOnlineRoutes.Mortgage,
								);
								break;
							case AdviceProcessCode.KiwiSaverAdvice:
								route = this.routeService.crtPage(
									!this.hasQuickAdd,
									this.customerID,
									apId,
									AdviceProcessOnlineRoutes.KiwiSaver,
								);
								break;
							case AdviceProcessCode.ClientAlterationRequest:
								route = this.routeService.clientAlterationRequest(this.customerID, apId);
								break;
							default:
								route = this.routeService.clientReviewTemplate(!this.hasQuickAdd, this.customerID, apId);
								break;
						}
						// if (
						// 	adviceProcess.processCode === AdviceProcessCode.MortgageAdvice
						// ) {
						// 	route = this.routeService.crtPage(
						// 		!this.hasQuickAdd,
						// 		this.customerID,
						// 		apId,
						// 		AdviceProcessOnlineRoutes.Mortgage
						// 	);
						// } else if (
						// 	adviceProcess.processCode === AdviceProcessCode.KiwiSaverAdvice
						// ) {
						// 	route = this.routeService.crtPage(
						// 		!this.hasQuickAdd,
						// 		this.customerID,
						// 		apId,
						// 		AdviceProcessOnlineRoutes.KiwiSaver
						// 	);
						// } else {
						// 	route = this.routeService.clientReviewTemplate(
						// 		!this.hasQuickAdd,
						// 		this.customerID,
						// 		apId
						// 	);
						// }
						this.router.navigate(route);
					});
				},
				() => {
					this.apForm.setPrepopulate(false);
				},
				() => {
					this.apForm.setPrepopulate(false);
				},
			);
	}
	cancelAdviceProcess() {
		const saveFn$ = (cancellationReason: string, notes: string) =>
			this.cancelAdviceProcess$(
				this.activeAdviceProcess?.adviceProcessID,
				cancellationReason,
				notes,
				this.activeAdviceProcess,
			);

		const initState = {
			header: 'Cancel Advice Process',
			message: 'Why is the Advice Process being cancelled?',
			reasonOptions: ['The client could not be contacted', 'The client has opted out of the advice meeting'],
			saveFn$,
		};
		this.modalService.show(AdviceProcessCancelModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	reopenAdviceProcessClaim() {
		const adviceProcess = clone(this.activeAdviceProcess);
		adviceProcess.status = this.adviceProcessStatus.reopened;
		return this.updateAdviceProcessFn$(adviceProcess, false, true).pipe(take(1)).subscribe();
	}

	cancelAdviceProcessClaim() {
		if (this.isClaimClosing) {
			return;
		}
		const adviceProcess = clone(this.activeAdviceProcess);
		const allStagesCompleted = adviceProcess.stages.every((s) => Boolean(s.value));
		of(true)
			.pipe(
				mergeMap(() => {
					this.isClaimClosing = true;
					if (allStagesCompleted) {
						adviceProcess.status = this.adviceProcessStatus.complete;
						return this.closeAdviceProcess$(adviceProcess, true, false).pipe(take(1));
					}

					adviceProcess.status = this.adviceProcessStatus.incomplete;
					return this.closeAdviceProcess$(adviceProcess, true, false).pipe(take(1));
				}),
				finalize(() => (this.isClaimClosing = false)),
			)
			.pipe(take(1))
			.subscribe();
	}

	goToPolicyEvent(data: { customerServiceId: number; serviceCode: string }) {
		this.goToPolicy?.emit(data);
	}

	clearDataFromState() {
		// LOAT State
		this.crtService.clear();
		this.riskAnalysisService.clearData();
		this.lifeService.clearData();
		this.tpdService.clearData();
		this.disabilityService.clearData();
		this.medicalService.clearData();
		this.crtNoteService.clearData();
		this.soaService.clearData();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
