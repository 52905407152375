import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { IconService } from '@core/icons/icon.service';
import { BusinessConfig } from '@domain/business-config/business-config.model';
import { User, isTapLevel } from '@domain/user/user.model';
import { UserQuery } from '@domain/user/user.query';
import { environment as env } from '@environment';
import markerSDK from '@marker.io/browser';
import { ExportsService } from '@modules/exports/state/exports.service';
import { TransferQuery } from '@modules/transfer/state/transfer.query';
import { TransferService } from '@modules/transfer/state/transfer.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import * as moment from 'moment';
import { Intercom } from 'ng-intercom';
import { Observable, Subject, combineLatest, interval } from 'rxjs';
import { filter, map, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { AuthenticationService } from './core/authentication/authentication.service';
import { ScriptService } from './core/general/script.store';
import { LocalService } from './core/services/local.service';
import { BusinessConfigQuery } from './domain/business-config/business-config.query';
import { ExportsQuery } from './modules/exports/state/exports.query';

@Component({
	selector: 'app-root, demo-progressbar-dynamic',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	title: string; // Default title
	stateTitle: string;
	hasNewReleaseUpdate: boolean;
	appTimeInterval: Observable<number>;
	showExportPopup = false;

	exportsStatus$ = this.exportsQuery.status$;
	transferStatus$ = this.transferQuery.status$;

	constructor(
		script: ScriptService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		private businessConfigQuery: BusinessConfigQuery,
		private authService: AuthenticationService,
		private localService: LocalService,
		private idle: Idle,
		private keepalive: Keepalive,
		private swUpdate: SwUpdate,
		private appRef: ApplicationRef,
		private exportsService: ExportsService,
		private exportsQuery: ExportsQuery,
		private tranferService: TransferService,
		private transferQuery: TransferQuery,
		private userQuery: UserQuery,
		public intercom: Intercom,
		public iconService: IconService,
	) {
		script.load('googlemaps').catch((_error) => {});
		// sets an idle timeout of 60 seconds, for testing purposes.
		idle.setIdle(60);
		// sets a timeout period of 3 hours. after 3 hours and 60 seconds of inactivity, the user will be considered timed out.
		idle.setTimeout(env.idleTime);
		// sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
		idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

		idle.onIdleEnd.subscribe(() => {
			this.localService.setValue('idleTime', Date.now());
		});

		idle.onTimeout.subscribe(() => {
			if (this.stateTitle !== 'Login' && this.stateTitle !== 'Login Automation') {
				this.authService.logout().subscribe();
			}
		});

		idle.onIdleStart.subscribe(() => {
			this.localService.setValue('idleTime', Date.now());
		});

		idle.onTimeoutWarning.subscribe(() => {});

		keepalive.interval(15);
		keepalive.onPing.subscribe(() => {
			if (!this.authService.isLoggedIn && this.stateTitle !== 'Login' && this.stateTitle !== 'Login Automation') {
				this.authService.logout().subscribe();
			}
		});

		this.reset();

		/**
		 * Initialize Intercom if Required
		 */
		this.initializeIntercom();

		/**
		 *  Register TAP icons
		 */
		iconService.registerIcons();
	}

	reset() {
		this.idle.watch();
		this.localService.setValue('idleTime', Date.now());
	}

	ngOnInit() {
		this.businessConfigQuery.businessName$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this.title = x && x !== '' ? x : 'The Adviser Platform'));
		this.titleService.setTitle(this.title);

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this.getTitle()),
				tap(() => {
					if (env?.production) {
						this.swUpdate.checkForUpdate().then();
					}
				}),
				takeUntil(this.onDestroy$),
			)
			.subscribe((title: string | null) => {
				this.stateTitle = title;
				this.setTitle(this.stateTitle);
			});
		this.stateTitle = this.getTitle(); // Initialize value
		this.setTitle(this.stateTitle);

		/**
		 * Service Worker
		 * Update notification
		 */
		if (this.swUpdate.isEnabled) {
			this.swUpdate.versionUpdates.subscribe((res) => {
				// when a new version has been detected
				if (res?.type === 'VERSION_READY') {
					// check wether the user is not logged in and is in the login page
					if (!this.authService.isLoggedIn) {
						// if true, pull the updates immediately
						this.activateNewReleaseUpdate();
					} else {
						// otherwise, flag the prompt for manual updates
						this.hasNewReleaseUpdate = true;
					}
				}
			});
		}

		/**
		 * Service Worker
		 * Check for Updates
		 */
		this.checkUpdate();

		setTimeout(() => {
			this.showExportPopup = true;
		}, 5000);

		/** Initialize Marker.io in all environments except prod/live*/
		if (!env?.live) {
			markerSDK.loadWidget(env?.markerIo);
		}
	}

	setTitle(title?: string) {
		let t = `${this.title} | ${title}`;
		if (!title || title === '') {
			t = this.title;
		}
		this.titleService.setTitle(t);
	}

	/**
	 * Get breadcrumb from route data
	 * @return string | null
	 */
	getTitle(): string | null {
		let child = this.route.firstChild;
		while (child) {
			if (child.firstChild) {
				child = child.firstChild;
			} else if (child.snapshot.data && child.snapshot.data.breadcrumb) {
				if (
					child.parent &&
					child.parent.snapshot.data &&
					child.parent.snapshot.data.breadcrumb &&
					child.parent.snapshot.data.ignoreChildBreadcrumb
				) {
					return child.parent.snapshot.data.ignoreChildBreadcrumb
						? child.parent.snapshot.data.breadcrumb
						: child.snapshot.data.breadcrumb;
				}
				return child.snapshot.data.breadcrumb;
			} else {
				return null;
			}
		}
		return null;
	}

	activateNewReleaseUpdate() {
		this.swUpdate.activateUpdate().then(() => window.location.reload());
	}

	checkUpdate() {
		this.appRef.isStable.subscribe((isStable) => {
			if (isStable && !this.appTimeInterval) {
				const timeInterval = interval(120000);

				this.appTimeInterval = timeInterval;

				timeInterval.subscribe(() => {
					if (env?.production) {
						this.swUpdate.checkForUpdate().then();
					}
				});
			}
		});
	}

	closeNewReleaseUpdate() {
		this.hasNewReleaseUpdate = false;
	}

	closeExportPopup() {
		setTimeout(() => {
			this.showExportPopup = false;
		}, 500);
	}

	initializeIntercom() {
		// Utility function to check if user and business are valid
		const areValuesValid = (user: User, business: BusinessConfig): boolean =>
			!!user && !!user?.StaffID && !!business && !!business?.BusinessCode && !!business.BusinessName;

		// Function to create Intercom configuration
		const createIntercomConfig = (user: User, business: BusinessConfig) => {
			const blConfig = {
				app_id: env.intercom.appId,
				email: user?.EmailAddress,
				created_at: moment(user?.StartDate).unix(),
				name: `${user?.FirstName} ${user?.LastName}`,
				'TAP Active User': !!user?.IsActive,
				tap_company: business?.BusinessCode,
				company: {
					company_id: business?.BusinessCode,
					name: business?.BusinessName,
				},
			};

			const tlConfig = {
				app_id: env.intercom.appId,
				email: user?.EmailAddress,
				created_at: moment(user?.StartDate).unix(),
				name: `${user?.FirstName} ${user?.LastName}`,
				'TAP Active User': !!user?.IsActive,
			};

			return isTapLevel(user) ? tlConfig : blConfig;
		};

		// Combine observables and initialize Intercom
		combineLatest([this.userQuery.userInfo$, this.businessConfigQuery.businessConfig$])
			.pipe(
				// Complete observable if environment conditions are not met
				takeWhile(() => env.live && env.production),
				takeUntil(this.onDestroy$),
				filter(([user, business]) => areValuesValid(user, business)),
				tap(([user, business]) => {
					const config = createIntercomConfig(user, business);
					this.intercom.boot(config);
				}),
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.exportsService.stopPolling();
		this.tranferService.stopPolling();
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
