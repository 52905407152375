import { Moment } from 'moment';

export interface AiQuotePayloadState {
	payload: AiQuotePayloadDataState;
	file: string;
}
export interface AiQuoteResultState {
	success: boolean;
	status: string;
	message: string;
	data: AiQuoteDataResponseState;
}

export interface AiQuoteDataResponseState {
	InsuranceProvider: string;
	TotalFees: string;
	QuoteNumber: string;
	QuoteDate: string;
	Policies: AiQuoteDataPolicyState[];
}

export interface AiQuoteDataPolicyState {
	PolicyName: string;
	PolicyFee: string;
	PremiumFrequency: string;
	QuotedProducts: AiQuoteDataPolicyQuotedProductsState[];
}

export interface AiQuoteDataPolicyQuotedProductsState {
	QuotedFor: string;
	ProductName: string;
	Benefit: {
		value: string;
		frequency: string;
	};
	Premium: string;
	QuoteDate: string;
	ProductNotes: string;
	Excess?: string;
}
export interface AiQuotePayloadDataState {
	type: string; // AiQouteType
	companyData: AiQuoteCompanyState;
}

export interface AiQuoteCompanyState {
	name: string; // AiQouteCompany
	data: AiQuoteCompanyDataState;
}

export interface AiQuoteCompanyDataState {
	adviceProcessId: number;
	fileName: string;
	companyCode: string;
	clientId: number;
	lifeAssuredList: string;
	providersList: string;
	productsList: string;
}

export interface AiQuoteFormState {
	lifeAssuredList: AiQuoteFormLifeAssuredState[];
	policyOwner: string[];
	policyNumber: string;
	provider: string;
	policyFee: string;
}

export interface AiQuoteFormLifeAssuredState {
	id: number;
	benefit: string;
	notes: string;
	premium: string;
	product: string;
	startDate: Moment;
	lifeAssuredDisplay: string;
	lifeAssured: string;
	showDropdown?: boolean;
	btnAdd?: boolean;
	name?: string;
	newExisting?: string;
	priority?: string;
}

export const AiQouteCompany = 'tap';
export const AiQouteType = 'insurance';

export const AiQuoteStatus = {
	DEFAULT: 0,
	LOADING: 1,
	SUCCESS: 2,
	FAILED: 3,
	ERROR_PROVIDER: 4,
	ERROR_POLICY: 5,
};

export const AiQuoteMessages = {
	loading: {
		status: AiQuoteStatus.LOADING,
		buttonLabel: 'Processing',
	},
	processing: {
		status: AiQuoteStatus.LOADING,
		buttonLabel: 'Process with AI',
	},
	failed: {
		status: AiQuoteStatus.FAILED,
		buttonTooltip: 'Something went wrong, for now update your details manually',
		buttonLabel: 'AI Failed',
		notifHeader: 'AI Failed',
		notifMessage:
			'Opps! Something went wrong. Our AI is still new and in Beta. We are training it every day to help improve accuracy, for now <b>update your details manually</b> while we look at what has gone wrong.',
	},
	success: {
		status: AiQuoteStatus.SUCCESS,
		buttonTooltip: null,
		buttonLabel: 'Complete',
		notifHeader: 'Complete',
		notifMessage:
			'Your document %DOCNAME% has finished processing. Your quote has been reviewed and the products are detailed above. <b>Please review and ensure all the data is accurate. Our AI is in Beta and learning every day.</b>',
	},
	errorProvider: {
		status: AiQuoteStatus.ERROR_PROVIDER,
		buttonTooltip: 'Only one provider per insurance policy',
		buttonLabel: 'Second Provider Error',
		notifHeader: 'Second Provider Error',
		notifMessage:
			'We see you are trying to upload information for <b>2 different providers</b>. This cannot be done. To input the second provider please save and close this popup. Then click on "Add a new policy" again.',
	},
	errorPolicy: {
		status: AiQuoteStatus.ERROR_POLICY,
		buttonTooltip: 'Only one policy number per insurance policy',
		buttonLabel: 'Second Policy Error',
		notifHeader: 'Second Policy Error',
		notifMessage:
			'We see you are trying to upload information for <b>2 different policies</b>. This cannot be done. To input the second policy please save and close this popup. Then click on "Add a new policy" again.',
	},
};

// AI Quote Response Fields
export enum AiFields {
	Provider = 'InsuranceProvider',
	QuotedFor = 'QuotedFor',
	Benefit = 'Benefit',
	Notes = 'ProductNotes',
	Premium = 'Premium',
	Product = 'ProductName',
	Excess = 'Excess',
	QuoteDate = 'QuoteDate',
	QuotedProducts = 'QuotedProducts',
	Policies = 'Policies',
	PolicyFee = 'PolicyFee',
	QuoteNumber = 'QuoteNumber',
}
