import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, delay, take, tap } from 'rxjs';

@Component({
	selector: 'app-sync-kloat-modal',
	templateUrl: './sync-kloat-modal.component.html',
	styleUrls: ['./sync-kloat-modal.component.scss'],
})
export class SyncKloatModalComponent {
	confirm$: Observable<unknown>;
	cancel$: Observable<unknown>;
	header: string;
	message: string;
	confirmButton: string;
	cancelButton: string;
	isLoading: boolean;
	cancelLoading: boolean;

	constructor(public bsModalRef: BsModalRef) {
		this.setupProps();
	}

	setupProps() {
		this.header = 'Sync?';
		this.message = '';
	}

	confirm() {
		this.isLoading = true;
		const loadingDone = () => {
			this.bsModalRef.hide();
			setTimeout(() => (this.isLoading = false), 500);
		};
		if (this.confirm$) {
			this.confirm$
				.pipe(
					take(1),
					delay(10),
					tap(() => loadingDone()),
				)
				.subscribe();
			return;
		}
		loadingDone();
	}

	cancel() {
		this.cancelLoading = true;
		const loadingDone = () => {
			this.bsModalRef.hide();
			setTimeout(() => (this.cancelLoading = false), 500);
		};
		if (this.cancel$) {
			this.cancel$
				.pipe(
					take(1),
					delay(10),
					tap(() => loadingDone()),
				)
				.subscribe();
			return;
		}
		loadingDone();
	}

	close() {
		this.bsModalRef.hide();
	}
}
export interface DeleteModalModel {
	confirm$: Observable<unknown>;
	cancel$?: Observable<unknown>;
	header: string;
	message: string;
}
