import { Injectable } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import { environment as env } from '@environment';
import { Observable, map } from 'rxjs';
import { AiQuotePayloadState, AiQuoteResultState } from './ai-quote.model';

@Injectable({
	providedIn: 'root',
})
export class AiQuoteService {
	endpoint = `${env.aiQuoteApi}pdf-extraction`; // V2
	// endpoint = `${env.aiQuoteApi}pdf-ingestion`; // V1

	constructor(private api: ApiService) {}

	processAiQuotePdf(data: AiQuotePayloadState): Observable<AiQuoteResultState> {
		return this.api.externalPost(this.endpoint, data).pipe(map((x) => x as AiQuoteResultState));
	}
}
