import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent<T = unknown> {
	// we need to remove host attribute type
	// since bootstrap will add a cursor: pointer style
	// in element that has a [type]="button"
	@HostBinding('attr.type') get attrType() {
		return null;
	}

	@Input() text?: string;

	@HostBinding('style.width') get styleWidth() {
		return this.width ?? '';
	}

	@HostBinding('class') get pointerEvent() {
		return this.disabled ? 'btn disabled user-select-none p-0' : '';
	}

	/**
	 * angular material icons classes
	 * https://github.com/marella/material-icons/tree/main/css#readme
	 */
	@Input() icon?: string;

	@Input() clickFn$?: (data?: T, event?: PointerEvent) => Observable<unknown>;

	@Input() color: 'primary' | 'default' | 'base' = 'primary';

	@Input() type: 'button' | 'submit' | 'reset' = 'button';

	@Input() className?: string;

	@Input() disabled: boolean | null = false;

	@Input() isLoading = false;

	@Input() hideTextOrIconWhenLoading = false;

	@Input() width?: string;

	@Input() data?: T;

	// solid: button with a filled background. useful for buttons in a toolbar.
	// clear: button with a transparent background that resembles a flat button.
	@Input() fill: 'solid' | 'clear' = 'solid';

	@Input() isAnchorTag?: boolean = false;

	@Input() anchorTagHref?: (string | number)[] | string = ['/'];

	@Input() target: '_blank' | '_self' | '_parent' | '_top' = '_self';

	@Output() loading = new EventEmitter<boolean>();

	get hideText(): boolean {
		if (!this.text) {
			return false;
		}
		if (this.hideTextOrIconWhenLoading && this.isLoading) {
			return false;
		}
		return true;
	}

	get hideIcon(): boolean {
		if (!this.icon) {
			return false;
		}
		if (this.hideTextOrIconWhenLoading && this.isLoading) {
			return false;
		}
		return true;
	}

	get btnClass(): string {
		// tap-btn tap-btn--primary text-white
		return this.fill === 'solid'
			? `tap-btn tap-btn--${this.color} ${this.className ?? ''}`
			: `tap-btn text-${this.color} ${this.className ?? ''}`;
	}

	get spinnerClass(): string {
		return 'button-spinner spinner-border spinner-border-sm ml-2';
	}

	@HostListener('click', ['$event'])
	onClick(event: PointerEvent): void {
		if (this.clickFn$ && !this.isLoading) {
			this.isLoading = true;
			this.clickFn$(this.data, event)
				.pipe(
					finalize(() => (this.isLoading = false)),
					catchError((err) => {
						this.isLoading = false;
						this.cdr.detectChanges();
						return of(err);
					}),
				)
				.subscribe(() => {
					this.isLoading = false;
					this.cdr.detectChanges();
				});
		}
	}

	setLoading(isLoading: boolean): void {
		this.isLoading = isLoading;
		this.loading.emit(isLoading);
	}

	constructor(private cdr: ChangeDetectorRef) {}
}
