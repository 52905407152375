import {
	ChangeDetectorRef,
	Directive,
	EmbeddedViewRef,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
	selector: '[appInputChipsFor]',
	exportAs: 'appInputChipsInstance',
})
export class AppInputChipsFor implements OnChanges, OnDestroy {
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	renderedItems: EmbeddedViewRef<any>[] = [];

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	overFlowingItems: any[] = [];

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	private _items: any[];
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() set appInputChipsForOf(items: any[]) {
		this._items = items;
		this.render();
	}

	private _showAll = false;
	@Input() set appInputChipsForShowAll(showAll: boolean) {
		this._showAll = showAll;
		this.forceRender();
	}

	private subs = new Subscription();

	constructor(
		// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private cdr: ChangeDetectorRef,
	) {
		const sub = fromEvent(window, 'resize')
			.pipe(debounceTime(200))
			.subscribe(() => this.cdr.detectChanges());

		this.subs.add(sub);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.appInputChipsForOf?.firstChange) {
			this.cdr.detectChanges();
		}
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	// compare rendered view item and item weather
	// some items changes
	private itemChanged(): boolean {
		// if no view rendered yet
		if (!this.renderedItems?.length) {
			return true;
		}
		if (this.renderedItems?.length !== this._items?.length) {
			return true;
		}
		return (
			this.renderedItems.findIndex((renderedItem, index) => {
				return renderedItem.context.$implicit !== this._items[index];
			}) !== -1
		);
	}

	render() {
		if (!this.itemChanged()) {
			return;
		}
		if (this._showAll) {
			return this.renderAll();
		}
		this.renderVisibleItems();
	}

	forceRender() {
		if (this._showAll) {
			return this.renderAll();
		}
		this.renderVisibleItems();
	}

	renderAll(): void {
		this.overFlowingItems.length = 0;
		if (this.renderedItems.length) {
			this.renderedItems.forEach((item) => item.destroy());
			this.viewContainer.clear();
			this.renderedItems.length = 0;
		}
		this._items?.forEach((item, index) => {
			const renderedItem = this.viewContainer.createEmbeddedView(this.templateRef, {
				index,
				$implicit: item,
			});
			this.renderedItems.push(renderedItem);
		});
	}

	renderVisibleItems(): void {
		this.overFlowingItems.length = 0;
		if (this.renderedItems.length) {
			// destroy the rendered views before re-rendering the items
			this.renderedItems.forEach((item) => item.destroy());
			this.viewContainer.clear();
			this.renderedItems.length = 0;
		}

		let containerWidth = 0;
		let totalItemsWidth = 0;
		this._items?.forEach((item, index) => {
			// if items is already overflowed base on the container
			if (totalItemsWidth > containerWidth) {
				this.overFlowingItems.push(item);
				return;
			}

			const renderedItem = this.viewContainer.createEmbeddedView(this.templateRef, {
				index,
				$implicit: item,
			});
			// paint the newly created view to calculate the selected item
			// element width
			renderedItem.detectChanges();

			const el: HTMLElement = renderedItem.rootNodes[0];

			const showMoreBadgeWidth = 45;

			// add badge width if there is next item and it will exceed the container width
			// this will prevent badge from showing even though the selected items total
			// width don't exceed the container width
			const additionalContainerWIdth = this._items[index + 1] ? showMoreBadgeWidth : 0;

			// 50 = show more badge width
			containerWidth =
				// @ts-ignore-next
				(el.offsetParent?.offsetWidth ?? 0) - additionalContainerWIdth;

			// 5 = flex item gap
			totalItemsWidth += el.offsetWidth + 5;
			// if the current item exceed the container total width
			if (totalItemsWidth > containerWidth) {
				this.overFlowingItems.push(item);
				// remove the rendered item
				renderedItem.destroy();
			} else {
				this.renderedItems.push(renderedItem);
			}
		});
	}
}
