import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChange,
	SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import * as R from 'ramda';
import { Observable, Subject, of, zip } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { BusinessService } from 'src/app/core/business/business.service';
import { CustomerService } from 'src/app/core/customer/customer.service';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { UserQuery } from '../../../domain/user/user.query';
import { ObservableUtil } from '../../../util/observable.util';
import { Fields, getInvalidWarning, getRequiredWarning } from '../../error-message/error-message';
import { ActivityViewModel } from '../../models/_general/activity.viewmodel';
import { ViewDisplayValue } from '../../models/_general/display-value.viewmodel';
import { patchValue } from '../../services/service-utils/service.util';
import { NoWhitespaceValidator } from '../../validator/no-whitespace/no-whitespace.directive';

const PENBERTHY_CODE = 'penberthy';

@Component({
	selector: 'app-activity-form-dumb',
	templateUrl: './activity-form-dumb.component.html',
	styleUrls: ['./activity-form-dumb.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityFormDumbComponent implements OnInit, OnDestroy, OnChanges {
	onDestroy$ = new Subject<void>();
	@Input() AT: ViewDisplayValue[];
	@Input() AM: ViewDisplayValue[];
	@Input() advisers: ViewDisplayValue[];
	@Input() adviserCalendars: ViewDisplayValue[];
	@Input() isSaving: boolean;
	@Input() initialState: ActivityViewModel;
	@Input() hideClient = false;
	@Input() isBulkTransfer = false;
	@Input() showCancelButton = false;
	@Input() customSaveButton: string;
	@Input() i: number;

	@Output() saveEvent = new EventEmitter<ActivityViewModel>();
	@Output() cancelEvent = new EventEmitter<void>();
	@Output() primaryIdEvent = new EventEmitter<number>();
	@Input() isEditForm: boolean;
	@Input() isModal: boolean;
	@Input() isRequiredDate: boolean;
	@Input() permissionsToComplete: string[];
	@Input() isAS: boolean;
	@Input() isAR: boolean;

	showClass: string;

	form: UntypedFormGroup = this.fb.group({
		ActivityId: null,
		CreatedByStaffId: null,
		ActivityType: '',
		DueDate: null,
		DueTime: '',
		ActivityName: '',
		Details: '',
		IsCompleted: false,
		IsCancelled: false,
		Adviser: null,
		Appointment: false,
		AssignedToAdviserName: '',
		CreatedByStaffName: '',
		Customer: null,
		Location: '',
		Duration: 60,
		Meeting: null,
	} as { [key in keyof ActivityViewModel]: unknown });

	formValue$ = ObservableUtil.connectBehavior<CalendarViewModel>(
		this.form.valueChanges.pipe(takeUntil(this.onDestroy$)),
		this.form.value,
	);

	shouldShowGoogleCalendar$: Observable<boolean> = this.formValue$.pipe(
		map((calendarParam) => {
			const notEmpty = R.complement(R.isNil);
			return notEmpty(calendarParam.Appointment) && calendarParam.Appointment && notEmpty(calendarParam.Adviser);
		}),
	);

	googleCalendarUrl$: Observable<SafeResourceUrl> = this.formValue$.pipe(
		map(
			(form) =>
				({
					Appointment: form.Appointment,
					Adviser: form.Adviser,
				}) as CalendarViewModel,
		),
		distinctUntilChanged((x, y) => R.whereEq(x, y)),
		withLatestFrom(this.shouldShowGoogleCalendar$),
		filter(([, shouldShow]) => shouldShow),
		// Get urlList, filter, get url
		map(([form]) => {
			const urlList: ViewDisplayValue[] = this.adviserCalendars;

			const findCalendarPairWithId = R.find<ViewDisplayValue>(
				R.whereEq({ value: form.Adviser?.toString() } as Pick<ViewDisplayValue, 'value'>),
			);

			const getUrl = R.propOr<string>('', 'display' as keyof ViewDisplayValue);

			// biome-ignore lint/suspicious/noExplicitAny: TO DO
			const calendarUrl: any = R.pipe(findCalendarPairWithId, getUrl)(urlList);
			return this.sanitizer.bypassSecurityTrustResourceUrl(calendarUrl);
		}),
	);

	companyCode = '';
	referralActivityType = 'Referral';
	assignedToList: ViewDisplayValue[];

	constructor(
		private fb: UntypedFormBuilder,
		private sanitizer: DomSanitizer,
		private userQuery: UserQuery,
		private customerService: CustomerService,
		private businessService: BusinessService,
		private loggerService: LoggerService,
		private businessConfigQuery: BusinessConfigQuery,
	) {
		this.isEditForm = false;
	}

	private getInitialState = R.propOr(null, 'initialState' as keyof ActivityFormDumbComponent);
	private getCurrent = R.propOr(null, 'currentValue' as keyof SimpleChange);

	private getcurrentInitialState = R.pipe(this.getInitialState, this.getCurrent);

	get activityTypeFC(): AbstractControl {
		return this.form.controls['ActivityType'];
	}

	get adviserFC(): AbstractControl {
		return this.form.controls['Adviser'];
	}

	get meetingFC(): AbstractControl {
		return this.form.controls['Meeting'];
	}

	get activityNameFC(): AbstractControl {
		return this.form.controls['ActivityName'];
	}

	get assignedToAdviserName(): AbstractControl {
		return this.form.controls['AssignedToAdviserName'];
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.resetForm(this.getcurrentInitialState(changes));
		this.form.get('ActivityType').valueChanges.subscribe((x) => this.form.get('ActivityName').setValue(x));
	}

	ngOnInit() {
		this.companyCode = this.businessConfigQuery.getValue().config?.BusinessCode;

		this.setValidators();

		this.resetForm(this.initialState);

		this.advisers = this.advisers ? this.advisers?.sort((a, b) => a.display?.localeCompare(b.display)) : this.advisers;
		this.form.get('Adviser' as keyof ActivityViewModel).valueChanges.subscribe((x: string) => {
			const getNameOfChosenAdviser = R.pipe(
				R.find(R.whereEq<Pick<ViewDisplayValue, 'value'>>({ value: x })),
				R.propOr('', 'display' as keyof ViewDisplayValue),
			);

			if (!this.isBulkTransfer) {
				this.form.patchValue({
					AssignedToAdviserName: getNameOfChosenAdviser(this.advisers),
				} as Pick<ActivityViewModel, 'AssignedToAdviserName'>);
			}
		});

		this.form
			.get('Customer' as keyof ActivityViewModel)
			.valueChanges.pipe(
				filter((x) => x),
				mergeMap((f) => {
					if (!!f && !!f.CustomerId) {
						if (f.IsCompany) {
							return this.businessService.GetPrimaryCompany(f.CustomerId).pipe(
								map((c) => {
									return c.PhysicalAddress ? c.PhysicalAddress : this.form.get('Location').value;
								}),
							);
						} else {
							return this.customerService.GetPrimaryClient(f.CustomerId).pipe(
								map((c) => {
									return c.PhysicalAddress ? c.PhysicalAddress : this.form.get('Location').value;
								}),
							);
						}
					} else {
						return this.form.get('Location').value;
					}
				}),
			)
			.subscribe((x: string) => {
				this.form.get('Location' as keyof ActivityViewModel).setValue(x);
			});

		zip(of(this.AT))
			.pipe(
				take(1),
				map((ddListList) => {
					const defaultValueList: string[] = ddListList
						?.map((ddList) => ddList?.find((dd) => dd.isDefault))
						?.map((def) => def && def.value);
					return defaultValueList;
				}),
			)
			.subscribe(this.setDropdownDefaults);

		// Special handling for Penberthy Business, please refer to TAPNZ-13727
		const defaultMeeting =
			this.companyCode !== PENBERTHY_CODE ? this.AM?.find((option) => option.isDefault)?.value : '';
		const appointmentCheck =
			this.initialState && this.initialState.hasOwnProperty('Appointment') ? this.initialState.Appointment : true;

		this.form.patchValue({
			Meeting: this.initialState.Meeting ?? defaultMeeting,
			Appointment: this.isBulkTransfer ? false : appointmentCheck,
		});

		// Special handling for Penberthy Business, please refer to TAPNZ-13727
		if (this.companyCode === PENBERTHY_CODE) {
			this.form.patchValue({
				DueTime: this.initialState && this.initialState.DueTime ? this.initialState.DueTime : '12:00',
				Appointment: this.initialState && this.initialState.Appointment ? this.initialState.Appointment : false,
			});
		}

		if (appointmentCheck) {
			this.setDueDateRequirement();
		}

		if (!this.isEditForm) {
			const userIsTapLevel$ = this.userQuery.isTapLevel$.pipe(take(1));
			const userId$ = this.userQuery.userId$.pipe(take(1));
			userIsTapLevel$.pipe(withLatestFrom(userId$)).subscribe(([x, y]) => {
				if (!x && !this.isBulkTransfer) {
					this.form.get('Adviser').setValue(y.toString());
				}
			});

			this.form
				.get('Duration')
				.setValue(!!this.initialState && !!this.initialState.Duration ? this.initialState.Duration : 60);
		}

		if (this.isRequiredDate) {
			this.setDueDateRequirement();
		}

		this.form.get('Appointment').valueChanges.subscribe((x) => {
			if (x) {
				const date = this.form.get('DueDate').value;
				if (date !== null) {
					const isValidDate = date.isValid();
					if (!isValidDate) {
						this.form.controls['DueDate'].setValue(null);
					}
				}
				this.form.get('DueDate').clearValidators(); // Just resets validator for due date;
				this.setDueDateRequirement();
			} else {
				if (!this.isRequiredDate) {
					this.form.get('DueDate').clearValidators();
				}
			}

			this.form.get('DueDate').updateValueAndValidity();
		});

		this.showClass = this.isModal ? '3rem' : '4rem';

		// Set Activity Type and Activity Name when triggered by Activity Settings (AS) or Activity Referral (AR)
		if (this.isAS || this.isAR) {
			this.activityTypeFC.setValue(this.referralActivityType);
			this.activityNameFC.setValue(!R.isEmpty(this.initialState.ActivityName) ? this.initialState.ActivityName : '');
		}

		if (this.isAS) {
			this.adviserFC.setValue(this.initialState.Adviser);
			this.meetingFC.setValue(this.initialState.Meeting);
		}
		this.setAssignedToList();
	}

	setDropdownDefaults: (defaultValues: string[]) => void = ([at]) => {
		patchValue<unknown>(this.form, {
			ActivityType: this.initialState && this.initialState.ActivityType ? this.initialState.ActivityType : at,
			ActivityName:
				this.initialState && this.initialState.ActivityId
					? this.initialState.ActivityName
					: (this.initialState.ActivityType ?? at),
		});
	};

	setAssignedToList() {
		const formValue = this.form.getRawValue();
		const adviserAssigned = this.advisers?.find((x) => +x?.value === +formValue?.Adviser);
		if (!adviserAssigned) {
			// If Adviser is not on the adviser list
			// Manually add option to the list
			const adviser = {
				display: formValue?.AssignedToAdviserName || '',
				value: formValue?.Adviser,
			};
			this.assignedToList = [...(this.advisers || []), adviser]
				?.filter(Boolean)
				?.sort((a, b) => a.display?.localeCompare(b.display));
		} else {
			this.assignedToList = this.advisers || [];
		}
	}

	setDueDateRequirement() {
		this.form.get('DueDate').setValidators(Validators.required);
		this.form.get('DueDate').updateValueAndValidity();
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	// biome-ignore lint/suspicious/noExplicitAny: unknown
	private resetForm(initialState: any) {
		if (R.isNil(initialState)) {
			return;
		} else {
			if (initialState.Appointment) {
				this.setDueDateRequirement();
			}
		}
		this.form.reset(initialState, { emitEvent: true });
	}

	setValidators(): void {
		const requiredList = ['ActivityType', 'ActivityName', 'Duration'];

		if (!this.isBulkTransfer) {
			requiredList.push('Adviser');
		}

		for (const c of requiredList) {
			this.form.get(c).setValidators([Validators.required, NoWhitespaceValidator]);
		}
		this.form.updateValueAndValidity();
	}

	connectControlIsInvalid = (keyName: keyof ActivityViewModel) => {
		const control = this.form.get(keyName);
		const statusChanges$ = control.statusChanges.pipe(takeUntil(this.onDestroy$));
		return ObservableUtil.connectBehavior(statusChanges$, control.status).pipe(map((x) => x === 'INVALID'));
	};

	ActivityTypeInvalid$ = this.connectControlIsInvalid('ActivityType');
	ActivityNameInvalid$ = this.connectControlIsInvalid('ActivityName');
	DueDateInvalid$ = this.connectControlIsInvalid('DueDate');
	DueTimeInvalid$ = this.connectControlIsInvalid('DueTime');
	DurationInvalid$ = this.connectControlIsInvalid('Duration');
	AdviserInvalid$ = this.connectControlIsInvalid('Adviser');

	save() {
		if (this.isAS) {
			this.form.controls['DueDate'].clearValidators();
			this.form.controls['DueDate'].updateValueAndValidity();
			this.form.controls['DueTime'].clearValidators();
			this.form.controls['DueTime'].updateValueAndValidity();
		}

		if (!this.form.valid) {
			if (!this.form.value.ActivityType) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.ActivityType));
				return;
			}
			if (!this.form.value.ActivityName) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.ActivityName));
				return;
			}

			if (this.form.value.Appointment || this.isBulkTransfer) {
				if (!this.form.value.DueDate && !this.isAS) {
					this.loggerService.Warning({}, getRequiredWarning(Fields.Date));
					return;
				}
			}
			if (!this.form.value.DueTime && !this.isAS && !this.isBulkTransfer) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Time));
				return;
			}
			if (!this.form.value.Duration) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Duration));
				return;
			}
			if (!this.form.value.Adviser) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Adviser));
				return;
			}
			if (!!this.form.value.Appointment && !this.form.value.DueDate && !this.isAS) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Date));
				return;
			}
		} else {
			// tslint:disable-next-line: max-line-length
			const time = this.form.value.DueTime
				? this.form.value.DueTime?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
						this.form.value.DueTime,
					]
				: null;
			if (time && time.length === 1) {
				this.loggerService.Warning({}, getInvalidWarning(`${Fields.DueDate} or ${Fields.DueTime}`));
				return;
			}
			const data = {
				...this.initialState,
				...this.form.value,
			};
			this.saveEvent.emit(data);
		}
	}
	cancel() {
		this.cancelEvent.emit();
	}

	/**
	 * prevent user from entering "e" key and enable tab, delete and backspace keys
	 */
	durationKeydown(e: KeyboardEvent): void {
		const TAB = 9;
		const DELETE = 46;
		const BACKSPACE = 8;
		const NOT_VALID_CHARACTER =
			e.keyCode !== TAB && e.keyCode !== DELETE && e.keyCode !== BACKSPACE && Number.isNaN(Number(e.key));
		if (NOT_VALID_CHARACTER) {
			e.preventDefault();
		}
	}
}
type CalendarViewModel = Pick<ActivityViewModel, 'Adviser' | 'Appointment'>;
