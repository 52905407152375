import { Injectable, inject } from '@angular/core';
import { ApiService } from '@core/base/api.service';
import { applyTransaction } from '@datorama/akita';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { CrtDocumentService } from '@modules/crm/crt-page/_shared/service/crt-document.service';
import { HtmlPdfConfigState } from '@modules/crm/crt-page/_shared/service/html-pdf/defaults-config';
import { ScopeOfServiceMapper } from '@modules/crm/crt-page/crt-kiwisaver/scope-of-services/state/scope-of-service.mapper';
import { ScopeOfServiceState } from '@modules/crm/crt-page/crt-kiwisaver/scope-of-services/state/scope-of-service.model';
import { ScopeOfServiceService } from '@modules/crm/crt-page/crt-kiwisaver/scope-of-services/state/scope-of-service.service';
import { SosTemplateSettingsState } from '@modules/crm/crt-page/crt-kiwisaver/state/crt-kiwisaver.store';
import { MergeTagsService } from '@modules/crm/crt-page/crt-kiwisaver/state/merge-tags/crt-kiwisaver-mergetags.service';
import { SettingsTypes } from '@modules/kiwisaver-settings/state/kiwisaver-settings.model';
import { getContentWithMergeTags, removeMtWrappersOnTextarea } from '@shared/converter/content-merge-tags';
import {
	AdviceProcessCode,
	AdviceProcessSectionCodes,
	LOATKiwiSaverSync,
} from '@shared/models/advice-process/advice-process.model';
import { LinkedContactState } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import { SosMTMapper } from '@shared/models/client-review-template/merge-tags/crt-kiwisaver/scope-of-service/sos.mapper';
import { MergeTagState } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { PdfDesignV2Options } from '@shared/models/client-review-template/pdf-design-v2/pdf-design-v2.model';
import { ServicesCodes } from '@shared/models/services/services.model';
import { complement, either, flatten, isEmpty, isNil, prop, uniq, uniqBy } from 'ramda';
import { BehaviorSubject, EMPTY, Observable, iif, of, zip } from 'rxjs';
import { catchError, concatMap, finalize, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { SecondaryClientState } from 'src/app/shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryTrustState } from 'src/app/shared/models/client-profile/secondary-trust/secondary-trust.model';
import { SecondaryBusinessState } from 'src/app/shared/models/client-profile/seondary-business/secondary-business.model';
import { DocumentGroupState } from 'src/app/shared/models/documents/document-group.model';
import {
	AdviceProcessDocumentTypesMOAT,
	DocumentUpload,
	DocumentUploadState,
} from 'src/app/shared/models/documents/document.model';
import { util, convertUtil, objectUtil } from '../../../..//util/util';
import { BusinessService } from '../../../../core/business/business.service';
import { CustomerService } from '../../../../core/customer/customer.service';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { PrimaryCustomerCompanyState } from '../../../../shared/models/business-profile/business/business.model';
import { PrimaryClientState } from '../../../../shared/models/client-profile/primary-client/primary-client.model';
import { LoatQuery } from './loat.query';
import { ClientReviewTemplateContacts, LoatStore } from './loat.store';

@Injectable()
export class LoatService {
	snapshot = this.query.getValue();
	showInvalid$ = new BehaviorSubject<boolean>(null);
	dataIsResolved$ = new BehaviorSubject<boolean>(false);

	// Dropdown values
	SCR$ = this.dropdownValueQuery.orderedChoices$('SCR');
	PCE$ = this.dropdownValueQuery.orderedChoices$('PCE');
	SCTT$ = this.dropdownValueQuery.orderedChoices$('SCTT');
	LRP$ = this.dropdownValueQuery.orderedChoices$('LRP');
	LRPR$ = this.dropdownValueQuery.orderedChoices$('LRPR');
	APCRTVD$ = this.dropdownValueQuery.orderedChoices$('APCRTVD');
	APCRTG$ = this.dropdownValueQuery.orderedChoices$('APCRTG');
	APCRTBP$ = this.dropdownValueQuery.orderedChoices$('APCRTBP');
	APCRTYN$ = this.dropdownValueQuery.orderedChoices$('APCRTYN');
	APCRTYNNA$ = this.dropdownValueQuery.orderedChoices$('APCRTYNNA');
	APCRTF$ = this.dropdownValueQuery.orderedChoices$('APCRTF');
	APCRTME$ = this.dropdownValueQuery.orderedChoices$('APCRTME');
	APCRTB$ = this.dropdownValueQuery.orderedChoices$('APCRTB');
	APCRTFM$ = this.dropdownValueQuery.orderedChoices$('APCRTFM');
	APCRTKC$ = this.dropdownValueQuery.orderedChoices$('APCRTKC');
	APCRTTC$ = this.dropdownValueQuery.orderedChoices$('APCRTTC');
	APCRTI$ = this.dropdownValueQuery.orderedChoices$('APCRTI');
	APCRTIST$ = this.dropdownValueQuery.orderedChoices$('APCRTIST');
	APCRTIT$ = this.dropdownValueQuery.orderedChoices$('APCRTIT');
	APCRTTM$ = this.dropdownValueQuery.orderedChoices$('APCRTTM');
	SU$ = this.dropdownValueQuery.orderedChoices$('SU');
	SV1$ = this.dropdownValueQuery.orderedChoices$('SV1');
	STI$ = this.dropdownValueQuery.orderedChoices$('STI');
	ST$ = this.dropdownValueQuery.orderedChoices$('ST');
	PCLT$ = this.dropdownValueQuery.orderedChoices$('PCLT');
	KP$ = this.dropdownValueQuery.orderedChoices$('KP');
	MP$ = this.dropdownValueQuery.orderedChoices$('MP');
	MLT$ = this.dropdownValueQuery.orderedChoices$('MLT');
	MLP$ = this.dropdownValueQuery.orderedChoices$('MLP');
	APCRTRP$ = this.dropdownValueQuery.orderedChoices$('APCRTRP');
	APCRTL$ = this.dropdownValueQuery.orderedChoices$('APCRTL');
	APCRTA$ = this.dropdownValueQuery.orderedChoices$('APCRTA');
	KFT$ = this.dropdownValueQuery.orderedChoices$('KFT');

	APCRTCLC$ = this.dropdownValueQuery.orderedChoices$('APCRTCLC');
	APCRTVP$ = this.dropdownValueQuery.orderedChoices$('APCRTVP');
	APCRTKPY$ = this.dropdownValueQuery.orderedChoices$('APCRTKPY');
	APCRTSI$ = this.dropdownValueQuery.orderedChoices$('APCRTSI');
	FGI$ = this.dropdownValueQuery.orderedChoices$('FGI');

	APCRTGL$ = this.dropdownValueQuery.orderedChoices$('APCRTGL');
	APCRTDP$ = this.dropdownValueQuery.orderedChoices$('APCRTDP');

	APCRTE$ = this.dropdownValueQuery.orderedChoices$('APCRTE');
	APCRTIID$ = this.dropdownValueQuery.orderedChoices$('APCRTIID');
	APCRTESI$ = this.dropdownValueQuery.orderedChoices$('APCRTESI');
	APCRTDIP$ = this.dropdownValueQuery.orderedChoices$('APCRTDIP');
	APCRTR$ = this.dropdownValueQuery.orderedChoices$('APCRTR');
	APCRTPWP$ = this.dropdownValueQuery.orderedChoices$('APCRTPWP');

	APCRTTRC$ = this.dropdownValueQuery.orderedChoices$('APCRTTRC');
	APCRTICP$ = this.dropdownValueQuery.orderedChoices$('APCRTICP');
	APCRTEC$ = this.dropdownValueQuery.orderedChoices$('APCRTEC');
	APCRTAE$ = this.dropdownValueQuery.orderedChoices$('APCRTAE');

	APCRTIP$ = this.dropdownValueQuery.orderedChoices$('APCRTIP');
	APCRTP$ = this.dropdownValueQuery.orderedChoices$('APCRTP');
	APCRTLTCR$ = this.dropdownValueQuery.orderedChoices$('APCRTLTCR');
	APCRTHO$ = this.dropdownValueQuery.orderedChoices$('APCRTHO');
	APCRTWO$ = this.dropdownValueQuery.orderedChoices$('APCRTWO');

	AT$ = this.dropdownValueQuery.orderedChoices$('AT');
	AM$ = this.dropdownValueQuery.orderedChoices$('AM');

	APCRTCC$ = this.dropdownValueQuery.orderedChoices$('APCRTCC');
	APCRTNE$ = this.dropdownValueQuery.orderedChoices$('APCRTNE');
	APCRTFSCCO$ = this.dropdownValueQuery.orderedChoices$('APCRTFSCCO');

	primaryClient$ = this.query.primaryClient$;
	primaryClient = this.snapshot.primaryClient;
	adviceProcessId$ = this.query.adviceProcessId$;
	adviceProcessId = this.snapshot.adviceProcessId;

	isCompany$ = this.query.isCompany$;
	people$ = this.query.people$;
	dependents$ = this.query.dependents$;
	trusts$ = this.query.trusts$;
	company$ = this.query.company$;
	peopleEntities$ = this.query.peopleEntities$;

	currentInsurances$ = this.query.currentInsurances$;
	isLoadingCurrentInsurance$ = this.query.isLoadingCurrentInsurance$;
	lifeAssured$ = this.query.lifeAssured$;

	policyOwners$ = this.query.policyOwnersWithCRT$;
	showCurrentInsuranceModal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

	staffId$ = this.query.select().pipe(map((loat) => loat?.adviceProcess?.adviser));

	private apiService = inject(ApiService);
	private sosMTService = inject(MergeTagsService);
	private scopeOfServiceService = inject(ScopeOfServiceService);
	private businessConfig = inject(BusinessConfigQuery);
	private documentService = inject(CrtDocumentService);

	themeConfig$ = this.businessConfig.themeConfig$;

	constructor(
		protected dropdownValueQuery: DropdownValueQuery,
		protected store: LoatStore,
		protected query: LoatQuery,
		protected customerService: CustomerService,
		protected businessService: BusinessService,
	) {}

	clear() {
		applyTransaction(() => {
			this.store.reset();
		});
	}

	setAdviceProcessId(id: number) {
		applyTransaction(() => {
			this.store.setAdviceProcessId(id);
		});
	}

	setIsCompany(isCompany: boolean) {
		applyTransaction(() => {
			this.store.setIsCompany(isCompany);
		});
	}

	getContacts(customerId: number): Observable<ClientReviewTemplateContacts> {
		return this.customerService.GetContacts(customerId).pipe(
			tap((result) => {
				const sci = result?.SCI
					? // @ts-ignore-next
						(result?.SCI?.filter((sci) => +sci.IsActive === 1).map(
							objectUtil.mapPascalCaseToCamelCase,
						) as SecondaryClientState[])
					: [];

				this.store.setSecondaryClients(sci);

				const allSCI = result.SCI
					? (result.SCI.map(objectUtil.mapPascalCaseToCamelCase) as SecondaryClientState[])
					: [];
				this.store.setAllSecondaryClients(allSCI);

				const primaryClient = objectUtil.mapPascalCaseToCamelCase(result.PCI) as
					| PrimaryClientState
					| PrimaryCustomerCompanyState;
				this.store.setPrimaryClient(primaryClient);

				const linkCustomer = result?.LC
					? (result?.LC
							// @ts-ignore-next
							?.filter((lci) => +lci.IsActive === 1 || +lci.IsActive === 3)
							.map(objectUtil.mapPascalCaseToCamelCase) as LinkedContactState[])
					: [];
				this.store.setLinkedContacts(linkCustomer);

				const secondaryTrusts = result?.SCT
					? (result.SCT?.map(objectUtil.mapPascalCaseToCamelCase) as SecondaryTrustState[])
					: [];
				this.store.setSecondaryTrusts(secondaryTrusts);

				const secondaryCompanies = result.PCC
					? (result.PCC?.map(objectUtil.mapPascalCaseToCamelCase) as SecondaryBusinessState[])
					: [];
				this.store.setSecondaryCompanies(secondaryCompanies);
			}),
		);
	}

	getPrimaryClient(customerId) {
		return this.customerService.GetPrimaryClient(customerId).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = objectUtil.mapPascalCaseToCamelCase(x) as PrimaryClientState | PrimaryCustomerCompanyState;
					this.store.setPrimaryClient(state);
				}),
			),
			catchError(() => EMPTY),
		);
	}

	getSecondaryClients(customerId) {
		// this.store.setLoading(true);
		return this.customerService.GetSecondaryClientsByPrimaryClient(customerId).pipe(
			map((x) => x?.filter((y) => +y.IsActive === 1 || +y.IsActive === 3)),
			tap((x) =>
				applyTransaction(() => {
					const sci = x
						? (x
								?.filter((sci) => +sci.IsActive === 1)
								.map(objectUtil.mapPascalCaseToCamelCase) as SecondaryClientState[])
						: [];

					this.store.setSecondaryClients(sci);

					const allSCI = x ? (x.map(objectUtil.mapPascalCaseToCamelCase) as SecondaryClientState[]) : [];
					this.store.setAllSecondaryClients(allSCI);
				}),
			),
			finalize(() => this.store.setLoading(false)),
			catchError(() => EMPTY),
		);
	}

	getSecondaryTrusts(customerId) {
		return this.customerService.GetSecondaryTrustByPrimaryClient(customerId).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = x ? (x?.map(objectUtil.mapPascalCaseToCamelCase) as SecondaryTrustState[]) : [];
					this.store.setSecondaryTrusts(state);
				}),
			),
			catchError(() => EMPTY),
		);
	}

	getSecondaryCompanies(customerId) {
		return this.customerService.GetSecondaryBusinessesByPrimaryClient(customerId).pipe(
			tap((x) =>
				applyTransaction(() => {
					const state = x ? (x?.map(objectUtil.mapPascalCaseToCamelCase) as SecondaryBusinessState[]) : [];
					this.store.setSecondaryCompanies(state);
				}),
			),
			catchError(() => EMPTY),
		);
	}

	saveDocument(req: DocumentUpload): Observable<number> {
		return this.customerService.UploadDocument(req).pipe(catchError(() => EMPTY));
	}

	updateDocument(req: DocumentUpload) {
		return this.customerService.UploadDocument(req).pipe(catchError(() => EMPTY));
	}

	updateSignature(req: DocumentUploadState | DocumentUpload, id: number) {
		return this.customerService.UpdateDocument(req, id).pipe(catchError(() => EMPTY));
	}

	setHasFormChanges(hasFormChanges: boolean) {
		this.store.setHasFormChanges(hasFormChanges);
	}

	/**
	 * Get Client Documents
	 * @param primaryClientId primaryClientId
	 */
	getClientDocuments(primaryClientId: number): Observable<DocumentGroupState> {
		return of(primaryClientId).pipe(
			mergeMap((x) => this.customerService.GetDocumentsClientId(x)),
			map((x) => objectUtil.mapPascalCaseToCamelCase(x)),
			catchError(() => EMPTY),
		);
	}

	setShowInvalid(invalidState) {
		this.showInvalid$.next(invalidState);
	}

	/**
	 * Get the list of available choices for people both CRM and CRT
	 * @param ownersIds Value of specific input field selected
	 * @param policyOwners available choices
	 * @returns list of available choices with or without transferred & deceased client/s & linked contacts
	 */
	getOwnerChoices = (ownersIds: (string | number)[], policyOwners: ViewDisplayValue[]) => {
		const owners = (ownersIds || [])?.map((x) => +x);
		const allSci = (this.query.getValue().allSecondaryClients || [])?.map((x) => ({
			...x,
			id: +x?.customerID,
		}));
		const allLinked = (this.query.getValue().linkedContacts || [])?.map((x) => ({
			...x,
			isLinked: true,
			id: x?.linkedFromPrimaryCustomer ? +x?.relatedCustomerId : +x?.customerId,
		}));
		const desceasedSciIds = allSci?.filter((x) => x?.contactMethod === 'Deceased')?.map((x) => +x?.id);
		const deceasedLinkedIds = allLinked?.filter((x) => x?.contactMethod === 'Deceased')?.map((x) => +x?.id);
		const deceasedIds = uniq([...desceasedSciIds, ...deceasedLinkedIds]);
		const newPolicyOwnersNoDeceased = (policyOwners || [])
			?.filter((x) => !deceasedIds?.includes(+x?.value))
			?.sort((a, b) => a.display?.localeCompare(b.display));

		if (owners?.length > 0) {
			const transferredDeceased = owners?.filter((x) => {
				const isTransferred = policyOwners?.filter((y) => +y?.value === +x)?.length === 0;
				const isDeceased = deceasedIds?.includes(+x);
				return isTransferred || isDeceased;
			});

			if (transferredDeceased?.length > 0) {
				const policyOwnersTransferredDeceased = [...allSci, ...allLinked]?.filter(
					(x) => +transferredDeceased.some((t) => +t === +x.id),
				);
				const policyOwnersTransferredDeceasedIds = policyOwnersTransferredDeceased?.map((x) => +x?.id);
				const potChoices =
					policyOwnersTransferredDeceased?.map((x) =>
						// @ts-ignore-next
						ViewDisplayValue.Map(`${x.id}`, `${x?.isLinked ? x?.name : x.firstName.concat(' ', x.lastName)}`),
					) || [];
				const newPolicyOwners =
					policyOwners
						?.filter((x) => {
							const isDeceased = deceasedIds?.includes(+x?.value);
							const isSelected = policyOwnersTransferredDeceasedIds?.includes(+x?.value);
							return isSelected ? false : !isDeceased;
						})
						?.map((x) => ({ ...x, value: x?.value?.toString() })) || [];
				const newList = uniqBy(prop('value'), [...newPolicyOwners, ...potChoices]);

				return newList?.sort((a, b) => a.display?.localeCompare(b.display));
			} else {
				const newList = uniqBy(prop('value'), newPolicyOwnersNoDeceased);
				return newList?.sort((a, b) => a.display?.localeCompare(b.display));
			}
		} else {
			const newList = uniqBy(prop('value'), newPolicyOwnersNoDeceased);
			return newList?.sort((a, b) => a.display?.localeCompare(b.display));
		}
	};

	getLinkedContacts(customerId) {
		// this.store.setLoading(true);
		return this.customerService.GetLinkedContactsByPrimaryClient(customerId).pipe(
			map((x) => x?.filter((y) => +y.IsActive === 1 || +y.IsActive === 3)),
			tap((x) =>
				applyTransaction(() => {
					const lci = x
						? (x?.filter((lci) => +lci.IsActive === 1).map(objectUtil.mapPascalCaseToCamelCase) as LinkedContactState[])
						: [];

					this.store.setLinkedContacts(lci);
				}),
			),
			finalize(() => this.store.setLoading(false)),
			catchError(() => EMPTY),
		);
	}

	createKOATFromLOAT(clientsInvolved: number[]): Observable<boolean> {
		const loat = this.query.getValue()?.adviceProcess;
		const payload = {
			ProcessCode: AdviceProcessCode.KiwiSaverAdvice,
			Adviser: loat.adviser,
			ClientsInvolved: util.tryStringifyJson(clientsInvolved),
			IsOnline: true,
			CustomerServiceId: '[]',
			ClaimManager: null,
			CustomerID: loat.customerID,
			IsKLOATSynced: LOATKiwiSaverSync.CreateAndSync,
			KLOATAdviceProcessID: loat.adviceProcessID,
		};
		return this.apiService.post<number>('adviceprocesses', payload).pipe(
			mergeMap((koatAdviceProcessId) => {
				return this.saveKOATSOSDocument(koatAdviceProcessId).pipe(
					take(1),
					map(() => koatAdviceProcessId),
				);
			}),
			map((koatAdviceProcessId) => {
				this.store.setKoatAdviceProcessId(koatAdviceProcessId);
				return Boolean(koatAdviceProcessId);
			}),
		);
	}

	getScopeOfService(adviceProcessId: number, settingsCode: string) {
		const endpoint = `crt/${adviceProcessId}/${settingsCode}`;
		return this.apiService.get<ScopeOfServiceState>(endpoint).pipe(
			map((data) =>
				applyTransaction(() => {
					const state = complement(either(isNil, isEmpty))(data) ? objectUtil.mapPascalCaseToCamelCase(data)[0] : {};
					return state;
				}),
			),
			catchError(() => of({})),
		);
	}

	getSettingsTemplate() {
		return this.scopeOfServiceService.getSosTemplateSettings().pipe(
			concatMap((x) =>
				iif(
					() => !!x?.templateLink,
					this.scopeOfServiceService.getDocumentFromURL(x?.templateLink || ''),
					of('<p></p>'),
				),
			),
			take(1),
		);
	}

	getKOATSOS(): Observable<ScopeOfServiceState> {
		const endpoint = `crt/settings/0/${SettingsTypes.KOATSosTemplate}`;
		return this.apiService.get<SosTemplateSettingsState>(endpoint).pipe(
			map((x) => (complement(either(isNil, isEmpty))(x) ? objectUtil.mapPascalCaseToCamelCase(x) : null)),
			catchError(() => of(null)),
		);
	}

	renderKOATSosTemplate(koatAdviceProcessId: number) {
		// return this.sosMTService.getSosMt(koatAdviceProcessId).pipe(
		return of([]).pipe(
			mergeMap((MTs) => {
				return this.staffId$.pipe(
					take(1),
					map((adviser) => ({ adviser, MTs })),
				);
			}),
			mergeMap(({ adviser, MTs }) => {
				return zip([
					this.sosMTService.getMergeTags('general', 0),
					this.sosMTService.getMergeTags('business', 0),
					this.sosMTService.getAdviserProviderMt(adviser).pipe(map((result) => flatten(result))),
					of(MTs),
				]).pipe(
					map(([general, business, providerMT, sosMT]) => {
						return flatten([
							general?.map(objectUtil.mapPascalCaseToCamelCase) ?? [],
							business?.map(objectUtil.mapPascalCaseToCamelCase) ?? [],
							...(providerMT ?? []),
							sosMT,
						]);
					}),
				);
			}),
			mergeMap((mergeTags) => {
				return zip([
					this.getSettingsTemplate(),
					of(mergeTags),
					// this.getKOATSOS(),
					this.scopeOfServiceService.getSosDefault(),
				]);
			}),
			mergeMap(([settingsTemplate, mergeTags, settings]) => {
				const sosD = this.updateMergeTagsOnContent(
					ScopeOfServiceMapper.mapToViewCrt({} as ScopeOfServiceState, settings),
					mergeTags,
				);
				sosD.adviceProcessId = koatAdviceProcessId;
				return this.scopeOfServiceService.addScopeOfService(sosD).pipe(
					mergeMap(() => {
						return this.getScopeOfService(koatAdviceProcessId, AdviceProcessSectionCodes.SOS).pipe(
							map((sos) => [settingsTemplate, mergeTags, settings, sos]),
							mergeMap(([settingsTemplate, mergeTags, settings, sos]) => {
								return this.sosMTService.getSosMt(koatAdviceProcessId).pipe(
									map((MTs) => {
										return [settingsTemplate, [...mergeTags, ...MTs], settings, sos];
									}),
								);
							}),
						);
					}),
				);
			}),
			map(([settingsTemplate, mergeTags, settings, sosData]) => {
				const updatedMT = SosMTMapper.koatSosMergeTags(mergeTags, sosData, settings);
				return getContentWithMergeTags(settingsTemplate, updatedMT);
			}),
			map((x) => {
				return `<div class="sos-pdf-file pdf-design-v2 pdf-design-v2-body">${x}</div>`;
			}),
			take(1),
		);
	}

	convertMergeTags(content: string, mergeTags: MergeTagState[], convertToNonTextarea = false) {
		if (!convertToNonTextarea) {
			content = content?.trim()?.replace(/(?:\r\n|\r|\n)/gm, '<br />');
		}
		const data = getContentWithMergeTags(content, mergeTags, convertToNonTextarea);
		return removeMtWrappersOnTextarea(data, convertToNonTextarea);
	}

	updateMergeTagsOnContent(data, mergeTags) {
		const newData = {
			...data,
			whatWeWillTalkAboutToday: this.convertMergeTags(data?.whatWeWillTalkAboutToday, mergeTags, true) ?? '',
			whatWeWontTalkAboutToday: this.convertMergeTags(data?.whatWeWontTalkAboutToday, mergeTags, true) ?? '',
			lifeAndRiskReviewDetails: this.convertMergeTags(data?.lifeAndRiskReviewDetails, mergeTags, true) ?? '',
			houseCarAndContentsInsuranceDetails:
				this.convertMergeTags(data?.houseCarAndContentsInsuranceDetails, mergeTags, true) ?? '',
			residentialMortgageOrLendingDetails:
				this.convertMergeTags(data?.residentialMortgageOrLendingDetails, mergeTags, true) ?? '',
		};

		return newData;
	}

	private getKOATSOSPdfHeaderFooterOptions() {
		return this.themeConfig$.pipe(
			withLatestFrom(this.businessConfig.businessOATLogo$),
			map(([theme, oatLogo]) => {
				// Header config For PDF
				const HeaderHtmlUrlParam = new URLSearchParams({
					headerText: 'Scope of Service',
					headerLogo: oatLogo?.toString()?.trim() || '',
				})?.toString();
				// Footer config for PDF
				const FooterHtmlUrlParam = new URLSearchParams({
					footerColor: theme?.primarycolor || '#00263e',
				})?.toString();

				return {
					...PdfDesignV2Options,
					HeaderHtmlUrlParam,
					FooterHtmlUrlParam,
				} as HtmlPdfConfigState;
			}),
			map((options) => {
				const otherHeaderOptions = new URLSearchParams({
					startHeaderOnPage: '1',
				})?.toString();
				const otherFooterOptions = new URLSearchParams({
					startPageNumberOn: '1',
				})?.toString();
				return {
					...options,
					FooterHtmlUrlParam: `${options?.FooterHtmlUrlParam}&${otherFooterOptions}`,
					HeaderHtmlUrlParam: `${options?.HeaderHtmlUrlParam}&${otherHeaderOptions}`,
				};
			}),
			take(1),
		);
	}

	saveKOATSOSDocument(koatAdviceProcessId: number) {
		const groupName = this.query.getValue().primaryClient?.groupName?.toString()?.toUpperCase();
		const fileName = `${groupName} SCOPE OF SERVICE`;
		return this.renderKOATSosTemplate(koatAdviceProcessId).pipe(
			mergeMap((content) => {
				return this.getKOATSOSPdfHeaderFooterOptions().pipe(
					mergeMap((options) =>
						this.documentService.downloadDocumentPDF(content, fileName, {
							...options,
							FileName: fileName,
						}),
					),
				);
			}),
			mergeMap((content) => convertUtil.convertToBase64(content)),
			map((template) => ({
				ReferenceId: this.query.getValue().adviceProcessId,
				CustomerId: this.query.getValue().primaryClient?.customerID,
				Document: template,
				FileName: `${fileName}.pdf`,
				DocumentType: ServicesCodes.KiwiSaver,
				Type: AdviceProcessDocumentTypesMOAT.SOS,
			})),
			concatMap((x) => this.scopeOfServiceService.saveDocument(x)),
			mergeMap((documentId: number) => {
				const endpoint = `adviceprocesses/${koatAdviceProcessId}/document/${documentId}/${AdviceProcessDocumentTypesMOAT.SOS}`;
				return this.apiService.put(endpoint);
			}),
			take(1),
		);
	}
}
