import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '@environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { UserLoggedIn } from '../authentication/authentication.service';
import { LoggerService } from '../logger/logger.service';
import { LocalService } from '../services/local.service';
import { util } from '../util/util.service';

export const apiUrl = `${env.apiUrl}/api/v${env.apiVersion}`;

// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
const CheckForError = (response: any) => {
	const parsedResponse = util.fromJson(response);
	if (isJsonResultStatus(parsedResponse)) {
		// if (parsedResponse.status_code === 401) {
		//   const unauthorizedLink = 'http://' + env.apiUrl + '/login/Unauthorized';
		//   window.location.replace(unauthorizedLink);
		// }
		if (parsedResponse.status_code >= 300 || parsedResponse.status_code < 200) {
			throw parsedResponse.status_description ? parsedResponse.status_description : parsedResponse;
		}
	}
};

@Injectable({ providedIn: 'root' })
export class ApiService {
	/**
	 *
	 */
	constructor(
		private http: HttpClient,
		private loggerService: LoggerService,
		private localService: LocalService,
	) {}

	get user(): UserLoggedIn {
		return this.localService.getValue('token');
	}

	get isAuthorized(): boolean {
		const expiresIn = this.localService.getValue('expires_in');
		return !!this.user && expiresIn && Date.now() < expiresIn;
	}

	/**
	 * Sends a post request. Not the method to call normally as this is for some special old endpoints which needs special json parsing
	 *
	 * @param endpoint endpoint to call
	 * @param body body to send
	 *
	 */
	post2<T>(endpoint: string, body: object = {}): Observable<T & JsonResultStatus> {
		const url = util.toUrl(apiUrl, endpoint);
		return this.http.post<T & JsonResultStatus>(url, body).pipe(
			map((x) => {
				CheckForError(x);
				return x;
			}),
		);
	}
	/**
	 * Sends a post request.
	 * @param endpoint endpoint to call
	 * @param body body to send
	 */
	post<T>(endpoint: string, body: object = {}, opt = {}): Observable<T> {
		const url = util.toUrl(apiUrl, endpoint);
		// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
		return this.http.post<any>(url, body, opt).pipe(
			map<string | T | JsonResultStatus, T | JsonResultStatus>((x) => util.fromJson(x)),
			map<T | JsonResultStatus, T>((x) => {
				CheckForError(x); // tslint:disable-line: no-use-before-declare
				return x as T;
			}),
		);
	}

	/**
	 * Sends post request but does not retry on failure
	 * @param endpoint endpoint to call
	 * @param body body to send
	 */
	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	postNoRetry<T>(endpoint: string, body?: any, _opt?: any): Observable<T> {
		const url = util.toUrl(apiUrl, endpoint);

		// const optionalCompanyCode =
		//   opt && opt.headers && opt.headers.CompanyCode
		//     ? opt.headers.CompanyCode
		//     : '';
		// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
		return this.http.post<any>(url, body, {}).pipe(
			map<string | T | JsonResultStatus, T | JsonResultStatus>((x) => util.fromJson(x)),
			map<T | JsonResultStatus, T>((x) => {
				CheckForError(x); // tslint:disable-line: no-use-before-declare
				return x as T;
			}),
		);
	}

	/**
	 * Sends a post request with better types. Retries 1 time on failure.
	 * @param endpoint endpoint to call
	 * @param body body to send
	 */
	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	post3<T>(endpoint: string, body?: any, opt: any = {}): Observable<T> {
		let headers = opt.headers || new HttpHeaders();
		if (!headers.has('X-Retry')) {
			headers = headers.set('X-Retry', 'true');
		}
		const updatedOpt = { ...opt, headers };
		return this.post<T>(endpoint, body, updatedOpt).pipe(
			retry(1),
			catchError((error) => throwError(error)),
		);
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	postFileDownload: (endpoint: string, body?: any) => Observable<Blob> = (endpoint, body) =>
		this.http.post(util.toUrl(apiUrl, endpoint), body, {
			responseType: 'blob',
		});

	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	post4: (endpoint: string, body?: any) => Observable<any> = (endpoint, body) =>
		this.http.post(util.toUrl(apiUrl, endpoint), body, {
			responseType: 'text',
			// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
		} as any);

	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	postFileArrayBuffer: (endpoint: string, body?: any) => Observable<any> = (endpoint, body) =>
		this.http.post(util.toUrl(apiUrl, endpoint), body, {
			responseType: 'arraybuffer',
			// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
		} as any);

	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	get<T>(endpoint: string, params?: any, headers: any = {}, otherOptions = {}): Observable<T> {
		const url = util.toUrl(apiUrl, endpoint);
		return this.http.get<T>(url, { params: { ...params }, headers: { ...headers }, ...otherOptions }).pipe(
			map<string | T | JsonResultStatus, T | JsonResultStatus>((x) => util.fromJson(x)),
			map<T | JsonResultStatus, T>((x) => {
				CheckForError(x); // tslint:disable-line: no-use-before-declare
				return x as T;
			}),
		);
	}

	// Get method without error checking
	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	get2<T>(endpoint: string, params?: any, headers: any = {}, otherOptions = {}): Observable<T> {
		const url = util.toUrl(apiUrl, endpoint);
		return this.http.get<T>(url, { params: { ...params }, headers: { ...headers }, ...otherOptions }).pipe(
			map<string | T | JsonResultStatus, T | JsonResultStatus>((x) => util.fromJson(x)),
			map<T | JsonResultStatus, T>((x) => {
				return x as T;
			}),
		);
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	getResponseBlob(endpoint: string, params?: any): Observable<any> {
		return this.http.get(util.toUrl(apiUrl, endpoint), { ...params, responseType: 'blob' });
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	put<T>(endpoint: string, body: any = {}, headers: any = {}): Observable<T> {
		const url = util.toUrl(apiUrl, endpoint);
		return this.http.put<T>(url, body, { headers: { ...headers } }).pipe(
			map<string | T | JsonResultStatus, T | JsonResultStatus>((x) => util.fromJson(x)),
			map<T | JsonResultStatus, T>((x) => {
				CheckForError(x); // tslint:disable-line: no-use-before-declare
				return x as T;
			}),
		);
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	put2<T>(endpoint: string, body: any = {}, headers = {}): Observable<T> {
		const url = util.toUrl(apiUrl, endpoint);
		return this.http
			.put<T>(url, body, {
				headers: {
					...headers,
				},
			})
			.pipe(
				map<string | T | JsonResultStatus, T | JsonResultStatus>((x) => util.fromJson(x)),
				map<T | JsonResultStatus, T>((x) => {
					CheckForError(x); // tslint:disable-line: no-use-before-declare
					return x as T;
				}),
			);
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	patch<T>(endpoint: string, body: any = {}, headers: any = {}): Observable<T> {
		const url = util.toUrl(apiUrl, endpoint);
		return this.http.patch<T>(url, body, { headers: { ...headers } }).pipe(
			map<string | T | JsonResultStatus, T | JsonResultStatus>((x) => util.fromJson(x)),
			map<T | JsonResultStatus, T>((x) => {
				CheckForError(x); // tslint:disable-line: no-use-before-declare
				return x as T;
			}),
		);
	}

	/** Returns text */
	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	putText: (endpoint: string, body?: any) => Observable<any> = (endpoint, body) =>
		this.http.put(util.toUrl(apiUrl, endpoint), body, {
			responseType: 'text',
			// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
		} as any);

	delete<T>(endpoint: string): Observable<T> {
		const url = util.toUrl(apiUrl, endpoint);
		return this.http.delete<T>(url).pipe(
			map<string | T | JsonResultStatus, T | JsonResultStatus>((x) => util.fromJson(x)),
			map<T | JsonResultStatus, T>((x) => {
				CheckForError(x); // tslint:disable-line: no-use-before-declare
				return x as T;
			}),
		);
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	request<T>(method: string, endpoint: string, body: any = {}): Observable<T> {
		return this.http
			.request(method, endpoint, {
				body: { ...body },
			})
			.pipe(
				map<string | T | JsonResultStatus, T | JsonResultStatus>((x) => util.fromJson(x)),
				map<T | JsonResultStatus, T>((x) => {
					CheckForError(x); // tslint:disable-line: no-use-before-declare
					return x as T;
				}),
			);
	}

	/** For External APIs - Outside CRM */
	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	getExternalResource(url: string, params?: any): Observable<any> {
		return this.http.get(url, { ...params });
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	getExternalResourceAsBlob(url: string, params?: any): Observable<any> {
		return this.http.get(url, { ...params, responseType: 'blob' });
	}

	/**
	 * Sends post request but does not retry on failure
	 * @param endpoint endpoint to call
	 * @param body body to send
	 */
	// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
	externalPost<T>(url: string, body?: any, options?: any): Observable<T> {
		// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
		return this.http.post<any>(url, body, options).pipe(
			map((x) => util.fromJson(x)),
			map<T | JsonResultStatus, T>((x) => {
				CheckForError(x); // tslint:disable-line: no-use-before-declare
				return x as T;
			}),
		);
	}
}

export interface JsonResultStatus {
	status_code?: number;
	status_description?: string;
	id?: string;
}

// biome-ignore lint/suspicious/noExplicitAny: Can't remove any; Too many affected
export function isJsonResultStatus(o: any): o is JsonResultStatus {
	return o && (o.status_code || o.status_description);
}
