import { DOCUMENT } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Inject,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, fromEvent } from 'rxjs';
import { delay, finalize, map, shareReplay } from 'rxjs/operators';
import { ComponentBase } from '../../../core/base/component-base';
import { util } from '../../../util/util';
import { AppInputChipsFor } from './input-chips-for';
import { DefaultSelectionModel } from './input-chips-selection-model';

// biome-ignore lint/complexity/noBannedTypes: allow Object
interface InputChipsItem extends Object {
	text?: string;
	id: string;
}

@Component({
	selector: 'app-input-chips',
	templateUrl: './input-chips.component.html',
	styleUrls: ['./input-chips.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'appInputChips',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputChipsComponent),
			multi: true,
		},
	],
})
export class InputChipsComponent<T extends InputChipsItem>
	extends ComponentBase
	implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy
{
	@ViewChild(AppInputChipsFor) inputChipsFor: AppInputChipsFor;

	@ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

	/**
	 * remove all attribute from host element. so we dont have a
	 * duplicate attribute when we supplied tabindex, type, size, and id
	 */
	@HostBinding('attr.tabindex') attrTabindex = null;
	@HostBinding('attr.type') attrType = null;
	@HostBinding('attr.size') attrSize = null;
	@HostBinding('attr.id') attrId = null;
	@HostBinding('attr.appearance') attrAppearance = null;

	/**
	 * if maxSelectedItems is defined and selectedItems count are equivalent or greater than maxSelectedItems
	 * we will hide dropdown icon and dropdown using css class since ng-select doesn't have a
	 * API for this behavior
	 */
	@HostBinding('class') get hostClassName(): string {
		if (!this.maxSelectedItems) {
			return `d-relative ${this.appearance === 'bootstrap' ? '' : 'appearance-crt'} ${this.customClass}`;
		}

		return this.maxSelectedItems <= this.ngSelect.selectedItems.length
			? `cp-input-chips-max-selected-item d-relative ${this.appearance === 'bootstrap' ? '' : 'appearance-crt'}`
			: `d-relative ${this.appearance === 'bootstrap' ? '' : 'appearance-crt'} ${this.customClass}`;
	}

	@HostBinding('class.has-value') get hostClassHasValue() {
		return this.ngSelect?.hasValue ?? false;
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Output() onAdded = new EventEmitter<any>();

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Output() onRemoved = new EventEmitter<any>();

	/**
	 * regular expression to determine if a string contains number without
	 * alphabet or special characters
	 */
	numberRexepx = /^\d+$/;

	@Input() items$?: Observable<T[]>;

	/**
	 * property that will be displayed in select box
	 */
	@Input() displayProperty: keyof T = 'text';

	/**
	 * Object property to use for selected model. By default binds to whole object.
	 */
	@Input() valueProperty: keyof T = 'id';

	/**
	 * Placeholder text.
	 */
	@Input() placeholder = '';

	/**
	 * Allows to select multiple items.
	 */
	@Input() multiple = true;

	/**
	 * Set tabindex on ng-select
	 */
	@Input() tabindex = 0;

	@Input() value?: unknown;

	@Input() disabled = false;

	@Input() size: 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'link' = 'md';

	@Input() readonly = false;

	@Input() maxSelectedItems = 0;

	@Input() required = false;

	@Input() id = '';

	@Input() appearance: 'bootstrap' | 'crt' = 'bootstrap';

	/**
	 * convert array value before emitting to specified valueDataType
	 * there is/are instance that we need string of numbers '["1", "2", "3"]'
	 */
	@Input() valueDataType: 'string' | 'number' = 'string';

	/*
	 * show ng-select clear all button
	 */
	@Input() clearable = true;

	/**
	 * Set the dropdown position on open
	 */
	@Input() dropdownPosition: 'bottom' | 'top' | 'auto' = 'top';

	/**
	 * A function to compare the option values with the selected values.
	 * The first argument is a value from an option.
	 * The second is a value from the selection(model).
	 * A boolean should be returned.
	 */
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	@Input() compareWith: (value: any, item: any) => boolean;

	@Input() customClass = '';

	/**
	 * by default when user selected a value
	 * dropdown will close.
	 * this will also add us a functionality which is
	 * when user press ctrl key when selecting in dropdown
	 * dropdown will not close
	 */
	@Input() closeOnSelect = true;

	readonlyItems$?: Observable<T[]>;

	private change?: (value: unknown) => void;
	private resizeObserver: ResizeObserver;
	@ViewChild('parentDiv') parentDiv: ElementRef;
	private currentWidth = 0;

	get className(): string {
		return `form-control form-control-${this.size} w-100 p-0`;
	}

	get selectionModel(): DefaultSelectionModel {
		// @ts-ignore-next
		return this.ngSelect?.itemsList?._selectionModel;
	}

	get overflowingTags(): string {
		return this.inputChipsFor.overFlowingItems.map((item) => item[this.displayProperty]).join(', ');
	}

	constructor(
		@Inject(DOCUMENT) private _doc: HTMLDocument,
		private cdr: ChangeDetectorRef,
		private el: ElementRef<HTMLElement>,
		private ngZone: NgZone,
	) {
		super();
	}

	ngOnInit(): void {
		if (this.closeOnSelect) {
			const keyeventCallback = (e: KeyboardEvent) => {
				this.closeOnSelect = !e.ctrlKey;
				this.cdr.detectChanges();
			};
			super.subscribe(fromEvent<KeyboardEvent>(this._doc, 'keydown'), keyeventCallback);
			super.subscribe(fromEvent<KeyboardEvent>(this._doc, 'keyup'), keyeventCallback);
		}
	}

	ngAfterViewInit(): void {
		this.readonlyItems$ = this.items$?.pipe(
			map((items) => {
				if (!this.value) {
					return [];
				}

				// if id and text is same property we dont need to map
				// the display value
				if (this.displayProperty === this.valueProperty) {
					return this.value;
				}

				const hashMap = items.reduce((prev, cur) => {
					// @ts-ignore-next
					prev.set(cur[this.valueProperty], cur[this.displayProperty]);
					return prev;
				}, new Map());
				return (
					this.value
						// @ts-ignore-next
						?.map((id: number) => hashMap.get(id))
						.filter((a: string) => Boolean(a))
				);
			}),
			finalize(() => this.cdr.detectChanges()),
		);

		if (this.items$) {
			super.subscribe(this.items$.pipe(shareReplay(), delay(100)), (items) => {
				if (!items.length) {
					return;
				}
				this.cleanSelectedItem();
			});
		}
		// Trigger inputChips render when resizing for responsiveness
		this.resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				const width = entry.contentRect.width;
				if (width !== this.currentWidth) {
					this.currentWidth = width;
					this.inputChipsFor.forceRender();
				}
			}
		});
		if (this.parentDiv) {
			this.resizeObserver.observe(this.parentDiv.nativeElement);
		}
		this.cdr.detectChanges();
	}

	ngOnDestroy(): void {
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
		super.dispose();
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	trackByFn = (item: any) => {
		return item[this.valueProperty];
	};

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	onChange(value: any[]): void {
		if (this.change) {
			// @ts-ignore-next
			this.change(this.parseValue(value));
			this.value = value?.filter((a) => !a.isDeleted)?.map((val) => val[this.valueProperty]);
		}
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	onFocus(_e: any): void {
		// this will prevent parent element from adjust its height
		// when input chips is focused
		this.ngSelect.element.style.height = `${this.el.nativeElement.offsetHeight}px`;
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	onBlur(_e: any): void {
		this.ngSelect.element.style.height = '';
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	onRemove(e: any): void {
		this.onRemoved.emit(e);
	}

	writeValue(value: unknown): void {
		this.value = Array.isArray(value) ? value : this.parseJson(value as string);
		this.cdr.detectChanges();
	}

	registerOnChange(change: (value: unknown) => void): void {
		this.change = change;
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
		this.cdr.detectChanges();
	}

	selectAll(): void {
		this.ngAfterViewInit;
		this.items$.pipe(map((values) => values.map((v) => v[this.valueProperty]))).subscribe((value) => {
			this.writeValue(value);
			this.change(util.tryStringifyJson(value));
			this.cdr.detectChanges();
		});
	}

	deselectAll(): void {
		this.writeValue([]);
		this.change(null);
		this.cdr.detectChanges();
	}

	registerOnTouched(): void {
		// throw new Error('Method not implemented.');
	}

	modeItemBadgeClick(): void {
		this.ngSelect?.focus();
		this.ngSelect?.open();
	}

	isAllSelected(): boolean {
		if (!this.ngSelect) {
			return false;
		}
		return this.ngSelect.selectedItems.length === this.ngSelect.items.length;
	}

	forRenderItems(): void {
		this.inputChipsFor?.forceRender?.();
	}

	// remove selected item that dont exists in items
	private cleanSelectedItem(): void {
		this.ngSelect?.selectedItems?.forEach((item) => {
			// if item doesn't exists in items
			if (item.index === null) {
				this.ngSelect.unselect(item);
			}
		});
	}

	private parseJson(value: string): (number | string)[] | null {
		// Parse value(string[]) to array of number since input-chips value is number
		const parsedValue = util.tryParseJson(value)?.map?.((val) => {
			return this.numberRexepx.test(val as string) ? +val : val;
		});
		// Since CRM input chips adding a duplicate value.
		// this will remove duplicate values
		return Array.from(new Set(parsedValue));
	}

	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	private parseValue(value: any[]): string | null {
		return value?.length
			? util.tryStringifyJson(
					value
						?.filter((val) => !val.isDeleted)
						?.map((val) => {
							return this.valueDataType === 'string'
								? (val[this.valueProperty] as string | number).toString()
								: +(val[this.valueProperty] as string | number);
						}),
				)
			: null;
	}
	// biome-ignore lint/suspicious/noExplicitAny: Can't extract type
	onAdd(e: any): void {
		this.onAdded.emit(e);
	}
}
